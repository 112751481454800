import axios from "axios";
import env from "react-dotenv";
import React, { useEffect, useRef } from "react";
import "../Shared/Styles/login.css";
import { Header } from "../Shared/Components/header.component";
import AutoPlusLogo from "../Shared/Images/imports/logo-primary-auto-plus-hide.svg";
import { Footer } from "../Shared/Components/footer.component";
import carImage from "../Shared/Images/pngwing11.png";
import vectorImage from "../Shared/Images/vectorConnexion.svg";
import eyeSlashIcon from "../Shared/Images/eye-slash.svg";
import userIcon from "../Shared/Images/user.svg";
import smsIcon from "../Shared/Images/sms.svg";
import callIcon from "../Shared/Images/call.svg";
import lockIcon from "../Shared/Images/lock.svg";
import checkArrow from "../Shared/Images/imports/checkbox-fill-icon.svg";

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FooterComposant } from "../Shared/Components/footer.composant";
import { get, post, patch } from "../Shared/Services/api.service";
import { encryptData } from "../Shared/Services/encryption.service";
import {
  redirectToHome,
  redirectToURL,
} from "../Shared/Services/redirect.service";
import NotificationService from "../Shared/Services/notification.service";
import { checkUserData } from "../Shared/Services/user.check.service";

export const Inscription = () => {
  async function checkUserStatus() {
    const valueUserData = await checkUserData();
    if (valueUserData !== null) {
      redirectToHome();
    }
  }
  useEffect(() => {
    checkUserStatus();
    return () => {};
  }, []);

  const [showMdp, setShowMdp] = useState(false);
  const [showSecondMdp, setShowSecondMdp] = useState(false);
  const [politique, setPolitique] = useState(false);

  const handleChangePolitique = () => {
    setPolitique(!politique);
  };

  const handleChangeShowMdp = () => {
    setShowMdp(!showMdp);
  };

  const handleChangeShowSecondMdp = () => {
    setShowSecondMdp(!showSecondMdp);
  };

  const [nameInput, setNameInput] = useState("");
  const [prenomInput, setPrenomInput] = useState("");
  const [mailInput, setMailInput] = useState("");
  const [telInput, setTelInput] = useState("");
  const [mdpInput, setMdpInput] = useState("");
  const [secondMdpInput, setSecondMdpInput] = useState("");

  useEffect(() => {}, [telInput]);
  const [canLogin, setCanLogin] = useState(false);
  const [loadingSignin, setLoadingSignin] = useState(false);

  useEffect(() => {
    setLoadingSignin(false);
  }, []);
  const encryptAndSave = (response) => {
    const encryptedUserDataSave = encryptData({
      token: response.data.token,
      email: response.data.email,
    });
    window.localStorage.setItem("userDataSave", encryptedUserDataSave);
  };
  const signinFunction = () => {
    let data = {
      password: mdpInput,
      firstname: nameInput,
      lastname: prenomInput,
      profile: "Buyer",
      email: mailInput.toLowerCase(),
      phone: telInput,
    };
    setLoadingSignin(true);
    post("/users/signin", data)
      .then((response) => {
        encryptAndSave(response);
        NotificationService.addToQueue("Inscription Reussie");
        setTimeout(() => {
          redirectToURL("/account-confirm");
        }, 2000);
      })
      .catch((error) => {
        console.error(error.response);
        NotificationService.addToQueue(
          "Erreur lors de l'inscription, Ce email ou numero est déjà pris"
        );
        setLoadingSignin(false);
      });
  };

  useEffect(() => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (
      nameInput.trim().length >= 1 &&
      prenomInput.trim().length >= 2 &&
      mailInput.match(mailformat) &&
      telInput.trim().length >= 8 &&
      mdpInput.length >= 8 &&
      secondMdpInput.length >= 8 &&
      mdpInput === secondMdpInput &&
      politique === true
    ) {
      setCanLogin(true);
    } else {
      setCanLogin(false);
    }
  }, [
    nameInput,
    prenomInput,
    mailInput,
    telInput,
    mdpInput,
    secondMdpInput,
    politique,
  ]);

  return (
    <div className="login-connect-page">
      <Header NoBackground={false} />
      <div className="login-page flex center-margin max-width">
        <img className="back-car" src={carImage} alt="cars-image" />
        <img className="back-vector" src={vectorImage} alt="vector-image" />
        <div className="contains flex colm">
          <div className="top-img-label flex colm">
            <img src={AutoPlusLogo} alt="autoPlusLogo" />
            <h4>Inscription</h4>
          </div>
          <form className="input-contains flex colm">
            <div className="input-me-login">
              <img src={userIcon} alt="user-icon" />
              <input
                onChange={(e) => setNameInput(e.target.value)}
                type="text"
                placeholder="Nom"
                maxLength={50}
              />
            </div>
            <div className="input-me-login">
              <img src={userIcon} alt="user-icon" />
              <input
                onChange={(e) => setPrenomInput(e.target.value)}
                type="text"
                placeholder="Prénoms"
                maxLength={50}
              />
            </div>
            <div className="input-me-login">
              <img src={smsIcon} alt="sms-icon" />
              <input
                onChange={(e) => setMailInput(e.target.value)}
                type="email"
                placeholder="Adresse mail"
              />
            </div>
            <div className="input-me-login">
              <img src={callIcon} alt="call-icon" />
              <input
                onChange={(e) => setTelInput(e.target.value)}
                type="tel"
                maxLength={10}
                placeholder="Numéro de téléphone"
              />
            </div>
            <div className="input-me-login">
              <img src={lockIcon} alt="lock-icon" />
              <input
                onChange={(e) => setMdpInput(e.target.value)}
                type={showMdp === true ? "text" : "password"}
                placeholder="Mot de passe"
              />
              <button
                type="button"
                onClick={handleChangeShowMdp}
                className={showMdp === true ? "eye-slage o-back" : "hover-btn"}
              >
                <img
                  className={
                    showMdp === true ? "eye-slage o-back" : "eye-slage"
                  }
                  src={eyeSlashIcon}
                  alt="eye-slage-icon"
                />
              </button>
            </div>
            <div className="input-me-login">
              <img src={lockIcon} alt="lock-icon" />
              <input
                onChange={(e) => setSecondMdpInput(e.target.value)}
                type={showSecondMdp === true ? "text" : "password"}
                placeholder="Confirmer le mot de passe"
              />
              <button
                type="button"
                onClick={handleChangeShowSecondMdp}
                className={
                  showSecondMdp === true ? "eye-slage o-back" : "hover-btn"
                }
              >
                <img
                  className="eye-slage"
                  src={eyeSlashIcon}
                  alt="eye-slage-icon"
                />
              </button>
            </div>
            <div className="input-politique flex row">
              <button
                onClick={handleChangePolitique}
                className="flex hover-btn"
                type="button"
              >
                {politique === true ? (
                  <img src={checkArrow} alt="check-arrow" />
                ) : null}
              </button>
              <p>
                Je confirme avoir lu et j’accepte les{" "}
                <Link className="hover-btn" to={"/condition"}>
                  conditions d’utilisation
                </Link>{" "}
                et la{" "}
                <Link className="hover-btn" to={"/politique"}>
                  politique de confidentialité
                </Link>
              </p>
            </div>
          </form>
          <button
            type="button"
            disabled={!(canLogin && !loadingSignin)}
            onClick={signinFunction}
            className="hover-btn action-btn"
          >
            S'inscrire
          </button>
          <p className="switch-over">
            Vous avez déjà un compte ?{" "}
            <Link className="hover-btn" to={"/connexion"}>
              Se connecter
            </Link>
          </p>
        </div>
      </div>
      <FooterComposant />
    </div>
  );
};
