import React from "react";
import "../Styles/section-header3-card.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import FinancementIcon from "../Images/imports/wallet-minus-icon.svg";
import BuyCarIcon from "../Images/imports/driving-icon.svg";
import SellCarIcon from "../Images/imports/money-add-icon.svg";
import { SectionHeader3CardContainer } from "./section-header3-card-container";

export const SectionHeader3Card = () => {
  return (
    <div className="Funding_card">
      <div className="WhyUs_Cards">
        <div>
          <Card variant="outlined">
            <SectionHeader3CardContainer
              img={FinancementIcon}
              title="Financement"
              text="Nous comprenons que l'achat d'une voiture peut représenter un investissement important, et C'est pourquoi nous proposons une offre de financement flexible."
            />
          </Card>
        </div>

        <div>
          <Card variant="outlined">
            <SectionHeader3CardContainer
              img={BuyCarIcon}
              title="Achat de véhicules"
              text="Nous vous assurons : Qualité et fiabilité, Transparence et confiance, Options de financement flexibles et Service client exceptionnel pour votre processus d’achat."
            />
          </Card>
        </div>

        <div>
          <Card variant="outlined">
            <SectionHeader3CardContainer
              img={SellCarIcon}
              title="Vente de véhicules"
              text="Nous offrons un service pratique et rapide pour vous aider à vendre votre véhicule en toute simplicité."
            />
          </Card>
        </div>
      </div>
    </div>
  );
};
