export const SearchNormalIcon = ({ color }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon/Search/search-normal">
        <g id="vuesax/linear/search-normal">
          <g id="search-normal">
            <path
              id="Vector"
              d="M10.0837 17.5C14.4559 17.5 18.0003 13.9555 18.0003 9.58329C18.0003 5.21104 14.4559 1.66663 10.0837 1.66663C5.7114 1.66663 2.16699 5.21104 2.16699 9.58329C2.16699 13.9555 5.7114 17.5 10.0837 17.5Z"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M18.8337 18.3333L17.167 16.6666"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export const HeartIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon/Support Like Question/heart">
        <g id="vuesax/bold/heart">
          <g id="heart">
            <path
              id="Vector"
              d="M11.5996 5.62962L12 6.16475L12.4004 5.62962C13.3204 4.39983 14.7949 3.6001 16.44 3.6001C19.2315 3.6001 21.5 5.8739 21.5 8.6901C21.5 9.82572 21.3189 10.8738 21.0043 11.8462L21.0032 11.8494C20.249 14.2361 18.7035 16.1597 17.0354 17.5934C15.365 19.029 13.6004 19.9484 12.4589 20.3367L12.4589 20.3367L12.4536 20.3386C12.3537 20.3738 12.1893 20.4001 12 20.4001C11.8107 20.4001 11.6463 20.3738 11.5464 20.3386L11.5464 20.3386L11.5411 20.3367C10.3996 19.9484 8.635 19.029 6.96465 17.5934C5.29649 16.1597 3.75096 14.2361 2.99676 11.8494L2.99677 11.8494L2.99572 11.8462C2.68114 10.8738 2.5 9.82572 2.5 8.6901C2.5 5.8739 4.76848 3.6001 7.56 3.6001C9.2051 3.6001 10.6796 4.39983 11.5996 5.62962Z"
              stroke="white"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
