import React, { useEffect, useState } from "react";
import "../Styles/car-card.css";
import EssenceIcon from "../Images/EssenceIcon.svg";
import TransmissionIcon from "../Images/TransmissionIcon.svg";
import KilometerIcon from "../Images/kilometerCarac.svg";
import goodIcon from "../Images/good.svg";
import greatIcon from "../Images/great.svg";
import excellentIcon from "../Images/excellent.svg";
import env from "react-dotenv";
import { redirectToURL } from "../Services/redirect.service";
import { HeartIcon } from "../Images/jsxsvg/icons";
import heartIcon from "../Images/heart.svg";
import logoAutoPlus from "../Images/imports/logo-primary-auto-plus.svg";
import {
  capitalizeFirstLetters,
  truncateString,
} from "../Services/letters.manage";
import { useInView } from "react-intersection-observer";
import { getMyName } from "../Services/get-goodId-static";
import { useSelector } from "react-redux";
import NotificationService from "../Services/notification.service";

export const CarCard = ({
  car,
  setMyUserEditPage = null,
  dataFav,
  updateFavoris,
}) => {
  const [amActive, setAmActive] = useState(false);
  const [isFav, setIsFav] = useState(false);

  useEffect(() => {
    if (car.idswip === undefined) {
      setAmActive(true);
      return;
    }
    if (car.idswip <= car.current + 2 && car.idswip >= car.current) {
      setAmActive(true);
    } else {
      setAmActive(false);
    } // eslint-disable-next-line
  }, [car]);

  const [imageSrc, setImageSrc] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [favInLoad, setFavInLoad] = useState(false);
  const [imageRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px 50px 0px",
  });

  const [stateDiv, setStateDiv] = useState(<></>);

  const getGoodStateIcon = (val) => {
    const state = val;
    let imgState = goodIcon;
    let colorId = "#3083A3";
    let nameing = "Bon prix";

    if (state === 2) {
      imgState = greatIcon;
      colorId = "#22C55E";
      nameing = "Super prix";
    } else if (state === 3) {
      imgState = excellentIcon;
      colorId = "#16833E";
      nameing = "Excellent prix";
    }

    return { icon: imgState, color: colorId, value: nameing };
  };

  useEffect(() => {
    // if (car.isRecommanded === undefined) return;
    // if (car.isRecommanded === false) return;
    const goodState = getGoodStateIcon(car.isGoodPrice);
    setStateDiv(
      <div className="state-price addgap8 liner flex row">
        <div
          style={{ backgroundColor: goodState.color }}
          className="flex liner"
        >
          <img src={goodState.icon} alt="state-icon" />
        </div>
        <span style={{ color: goodState.color }}>{goodState.value}</span>
      </div>
    ); // eslint-disable-next-line
  }, [car]);

  const [imgLink, setImgLink] = useState("");
  const [nameCar, setNameCar] = useState("");
  const [priceCar, setPriceCar] = useState("");
  const [kilometerCar, setKilometerCar] = useState("");

  const [motorTypeName, setMotorTypeName] = useState("");
  const [transmissionName, setTransmissionName] = useState("");
  const dataTransmissions = useSelector(
    (state) => state.dataConf.transmissions
  );
  const dataMotorTypes = useSelector((state) => state.dataConf.motorTypes);

  async function getMotorAndTransmission() {
    if (car.motorType) {
      setMotorTypeName(car.motorType.name);
    } else {
      setMotorTypeName(getMyName(car.motorTypeId, dataMotorTypes));
    }

    if (car.transmission) {
      setTransmissionName(car.transmission.name);
    } else {
      setTransmissionName(getMyName(car.transmissionId, dataTransmissions));
    }
  }
  useEffect(() => {
    if (motorTypeName === "" || transmissionName === "")
      getMotorAndTransmission();
  }, [dataTransmissions, dataMotorTypes]);
  useEffect(() => {
    const envLocal = env.API_PRIVATE_URL + "/assets/uploads/cars/";
    setImgLink(envLocal + car.thumb);
    // setImgLink(envLocal + "/assets/images_test/vehiculeTestImage.jpg");
    // setImgLink(envLocal + "/assets/images_test/" + car.thumb);

    // const randomIndex = Math.floor(Math.random() * 10000);
    // setImgLink("https://picsum.photos/1920/1080?random=" + randomIndex);

    let carN = capitalizeFirstLetters(car.brandName + " " + car.model);
    setNameCar(carN);

    let price = 0;
    price = car.price;
    price = price.toLocaleString();
    price = price.toString().replace(" ", ".");
    price = price.toString().replace(" ", ".");
    setPriceCar("₣ " + price);

    let kilometers = 0;
    kilometers = car.kilometers;
    getMotorAndTransmission();

    setKilometerCar(kilometers.toLocaleString() + " km"); // eslint-disable-next-line
  }, []);
  const goToProductPage = () => {
    if (setMyUserEditPage === null) redirectToURL(`/productpage/${car.id}`);
    if (setMyUserEditPage !== null) setMyUserEditPage(car);
  };

  function updateFavorisState(val) {
    setIsFav(val);
    setFavInLoad(false);
  }
  const changeFavState = async (event) => {
    event.stopPropagation();
    setFavInLoad(true);
    const result = await updateFavoris(car);
    if (result === true)
      NotificationService.addToQueue(
        `${car.brandName} ${car.model} àjoutée à vos favoris`
      );
    updateFavorisState(result);
  };

  useEffect(() => {
    if (dataFav) {
      let amIn = false;
      dataFav.map((fav) => {
        if (fav.carId === car.id) amIn = true;
      });
      setIsFav(amIn);
    }
  }, [dataFav]);

  useEffect(() => {
    if (imgLink === "") return;
    if (inView) {
      setImageSrc(imgLink);
    }
  }, [inView, imgLink]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  return (
    <>
      <button
        key={setMyUserEditPage !== null && `garage-${car.id}-${car.ownerId}`}
        onClick={goToProductPage}
        className={
          amActive ? "card is-active-card flex colm" : "card flex colm"
        }
      >
        <div className="top">
          {imageSrc ? (
            <img
              src={imageSrc}
              alt={car.thumb}
              ref={imageRef}
              onLoad={handleImageLoad}
              style={{ display: imageLoaded ? "inline" : "none" }}
            />
          ) : null}
          {!imageLoaded && (
            <div ref={imageRef} className="placeholder flex colm">
              <span class="loader"></span>
              <img src={logoAutoPlus} alt="logo-autoplus" />
            </div>
          )}
        </div>
        <div className="bot flex colm btw">
          <div className="name-price flex colm">
            <span>{truncateString(nameCar, 23)}</span>
            <div className="flex liner btw row">
              <span>{priceCar}</span>
              <div
                style={{
                  pointerEvents: favInLoad && "none",
                  opacity: favInLoad && "0.7",
                }}
                onClick={(e) => changeFavState(e)}
                className="flex liner hover-btn favorite-btn"
              >
                {isFav === true ? (
                  <HeartIcon color={"#3083a3"} />
                ) : (
                  <img src={heartIcon} alt="heart-icon" />
                )}
              </div>
            </div>
            {stateDiv}
          </div>
          <div className="description btw flex row">
            <div className="desc flex row">
              <img src={EssenceIcon} alt="essence-icon" />
              <span>{truncateString(motorTypeName, 12)}</span>
            </div>
            <div className="desc flex row">
              <img src={TransmissionIcon} alt="transmission-icon" />
              <span>{truncateString(transmissionName, 10)}</span>
            </div>
            <div className="desc flex row">
              <img src={KilometerIcon} alt="kilometer-icon" />
              <p>{kilometerCar}</p>
            </div>
          </div>
        </div>
      </button>
    </>
  );
};
