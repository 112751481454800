import React, { useEffect } from "react";
import "../Styles/header.css";
import logoPrimaryAutoPlus from "../Images/imports/logo-primary-auto-plus.svg";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import eyeIcon from "../Images/eye-slash-white.svg";
import trashIcon from "../Images/imports/trash.svg";
import userIconSl from "../Images/imports/userPr.svg";
import carIconS1 from "../Images/imports/carPr.svg";
import logoutIconS1 from "../Images/imports/logoutPr.svg";
import helpIcon from "../Images/imports/help.svg";
import helpIconMobile from "../Images/imports/helpmobile.svg";

import profilIcon from "../Images/profile-circle.svg";
import profilIcon2 from "../Images/profile-circle2.svg";
import profilIconWW from "../Images/profile-circleWW.svg";
import moneyIcon from "../Images/card-pos.svg";
import dollarIcon from "../Images/dollar-circle.svg";
import walletIcon from "../Images/wallet-money.svg";
import heartIcon from "../Images/heart.svg";
import msgIcon from "../Images/messages-2.svg";
import settingIcon from "../Images/setting-2.svg";
import clockIcon from "../Images/clock.svg";
import carIcon from "../Images/car.svg";
import smsIcon from "../Images/imports/sms-icon.svg";
import logoutIcon from "../Images/logout.svg";
import closeIcon from "../Images/close-icon.svg";
import { decryptData } from "../Services/encryption.service";
import { redirectToHome, redirectToURL } from "../Services/redirect.service";
import { capitalizeFirstLetters } from "../Services/letters.manage";
import { useDispatch, useSelector } from "react-redux";
import { get, patch, remove } from "../Services/api.service";
import moment from "moment";
import { setCarBrand, setCarFindBySave } from "../../feature/filter.data";
import { HelpOutlineOutlined } from "@mui/icons-material";
export const Header = ({ NoBackground = false, Mobile = false }) => {
  const navLinkStyles = ({ isActive }) => {
    return {
      fontWeight: isActive ? "bold" : "normal",
      textDecoration: isActive ? "underline" : "none",
      textDecorationColor: isActive ? "#FFBF00" : "",
      textDecorationThickness: isActive ? "5px" : "",
    };
  };

  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [menuDesktopOpen, setMenuDesktopOpen] = useState(false);
  const [menuDesktopNotifOpen, setMenuDesktopNotifOpen] = useState(false);

  const handleOpenMenu = () => {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
    setMenuDesktopOpen(false);
    setMenuDesktopNotifOpen(false);
  };
  const closeOpenMenuMobile = () => {
    setMobileMenuIsOpen(false);
    setMenuDesktopOpen(false);
    setMenuDesktopNotifOpen(false);
  };

  const handleDesktopMenu = () => {
    setMenuDesktopOpen(!menuDesktopOpen);
    setMenuDesktopNotifOpen(false);
  };

  const handleDesktopNotifMenu = () => {
    const updatedMessagesNotif = currNotif.filter((item) => {
      return item.open === false;
    });
    setCurrNotif(updatedMessagesNotif);
    setMenuDesktopNotifOpen(!menuDesktopNotifOpen);
    setMenuDesktopOpen(false);
  };

  const navigate = useNavigate();

  const goToInternalLink = (link) => {
    closeOpenMenuMobile();
    if (link === "/#") {
      return;
    }
    navigate(link);
  };
  const disconnectedMe = () => {
    window.localStorage.removeItem("userDataSave");
    window.sessionStorage.removeItem("userDataSave");
    redirectToHome();
  };

  const [dataUser, setDataUser] = useState(null);
  const [currNotif, setCurrNotif] = useState([]);

  const valueUserData = useSelector((state) => state.userData.data);
  async function fetchData() {
    if (valueUserData !== "") {
      const decryptDUser = await decryptData(valueUserData);
      setDataUser(decryptDUser);
    } else {
      setDataUser(null);
    }
  }
  useEffect(() => {
    if (valueUserData) fetchData();
  }, [valueUserData]);

  const shortenText = (text, maxLength) => {
    if (text.length > maxLength) {
      const words = text.split(" ");
      if (words.length > 1) {
        const firstName = words[0];
        const lastNameInitial = words[1].charAt(0) + ".";
        return `${firstName} ${lastNameInitial}`;
      } else {
        return text.slice(0, maxLength - 2) + ".";
      }
    }
    return text;
  };

  const [userName, setNameUser] = useState("");
  const fetchNotif = async () => {
    let notifications = await get(`/users/notifs/${dataUser.id}`);
    const updatedMessagesNotif = notifications.data.filter((item) => {
      return item.open === false;
    });
    setCurrNotif(updatedMessagesNotif);
  };
  useEffect(() => {
    if (dataUser === undefined || dataUser === null) return;
    fetchNotif();
    setNameUser(dataUser.lastname);
  }, [dataUser]);

  function switchStateNotif(notif) {
    patchNotif(notif);
  }
  function patchNotif(notif) {
    const updatedMessagesNotif = currNotif.map((item) => {
      return notif.id === item.id ? { ...item, open: true } : item;
    });
    setCurrNotif(updatedMessagesNotif);
    patch(`/users/notifs/${notif.id}`, {
      userId: dataUser.id,
      open: true,
      deletedAt: new Date(),
    });
  }
  const [showNotif, setShowNotif] = useState([]);

  useEffect(() => {
    const updatedMessagesNotif = currNotif.filter((item) => {
      return item.open === false;
    });
    setShowNotif(updatedMessagesNotif);
  }, [currNotif]);

  const formattedDate = (val) => {
    return moment(val).format("DD MMMM, YYYY");
  };
  const location = useLocation();

  const [onProfilPage, setOnProfilPage] = useState([]);

  useEffect(() => {
    if (location.pathname.includes("profilpage")) {
      setOnProfilPage(true);
    } else {
      setOnProfilPage(false);
    }
    if (dataUser === undefined || dataUser === null) return;
    fetchNotif();
  }, [location]);
  const dispatch = useDispatch();
  return (
    <header className={NoBackground ? " header_transparent " : ""}>
      <div
        className={
          "header max-width " +
          (NoBackground ? " header_transparent " : "") +
          (Mobile ? " mobile-header " : " desktop-header")
        }
      >
        <div
          onClick={() => redirectToURL("/")}
          className="header_logo hover-btn"
        >
          <img src={logoPrimaryAutoPlus} alt="autoPlusLogo" />
        </div>

        <button onClick={handleOpenMenu} className="btn-show-menu">
          <div></div>
          <div></div>
          <div></div>
        </button>

        <div className="header-btns">
          <nav className="header_links">
            <div>
              <NavLink
                style={navLinkStyles}
                to="/"
                className={NoBackground ? "links_size_white" : "links_size"}
              >
                {" "}
                Accueil{" "}
              </NavLink>
            </div>
            <div>
              <NavLink
                style={navLinkStyles}
                onClick={() => dispatch(setCarBrand([]))}
                to="/acheter"
                className={NoBackground ? "links_size_white" : "links_size"}
              >
                {" "}
                Acheter{" "}
              </NavLink>
            </div>
            <div>
              <NavLink
                style={navLinkStyles}
                to="/vendre"
                className={NoBackground ? "links_size_white" : "links_size"}
              >
                {" "}
                Vendre{" "}
              </NavLink>
            </div>

            <div className="Funding_header_link">
              <Stack spacing={2} direction="row">
                <Badge badgeContent={"Indisponible"} color="error">
                  <div
                    to="#"
                    className={
                      NoBackground
                        ? "links_size_white badge-financement"
                        : "links_size badge-financement"
                    }
                  >
                    {" "}
                    Financement{" "}
                  </div>
                </Badge>
              </Stack>
            </div>
          </nav>
        </div>

        <div className="header_button-container">
          {menuDesktopOpen && (
            <>
              <div
                onClick={handleDesktopMenu}
                className="hover-btn no-background-click"
              ></div>
              <div className="flex colm container-profil">
                <button
                  onClick={() => goToInternalLink("/profilpage/1")}
                  className="flex row liner hover-btn"
                >
                  <img src={userIconSl} alt="userIcon" />
                  <span>Mon compte</span>
                </button>
                <button
                  onClick={() => goToInternalLink("/profilpage/2")}
                  className="flex row liner hover-btn"
                >
                  <img src={carIconS1} alt="userIcon" />
                  <span>Mon garage</span>
                </button>
                <button
                  onClick={() => navigate("/faq")}
                  className="flex row liner hover-btn"
                >
                  <img src={helpIcon} alt="helpIcon" />
                  <span>Aide</span>
                </button>
                <button
                  onClick={disconnectedMe}
                  className="flex row liner hover-btn"
                >
                  <img src={logoutIconS1} alt="userIcon" />
                  <span>Déconnexion</span>
                </button>
              </div>
            </>
          )}
          {menuDesktopNotifOpen && (
            <>
              <div
                onClick={handleDesktopNotifMenu}
                className="hover-btn no-background-click"
              ></div>
              <div className="flex colm container-profil container-notification-header">
                <button
                  onClick={() => goToInternalLink("/profilpage/5")}
                  className="flex row liner hover-btn open-notif-btn"
                >
                  <div className="open-notif flex colm liner">
                    <div className="flex row liner addgap8">
                      <img
                        style={{ transform: "rotate(180deg)" }}
                        src={logoutIconS1}
                        alt="userIcon"
                      />
                      <span style={{ textAlign: "center" }}>
                        Ouvrir votre messagerie
                      </span>
                    </div>
                    <span
                      style={{
                        direction: "ltr",
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      {showNotif.length > 1
                        ? `${showNotif.length} messages non lu`
                        : `${showNotif.length} message non lu`}
                    </span>
                  </div>
                </button>
                {showNotif.map((item) => {
                  return (
                    <div className="one-contain-notif hidden">
                      <h5>{item.title}</h5>
                      <button
                        style={{
                          opacity: "1",
                        }}
                        onClick={() => switchStateNotif(item)}
                        className="view hover-btn flex liner"
                      >
                        <img src={trashIcon} alt="view-icon" />
                      </button>
                      <div className="view-details flex colm">
                        <p>{item.text}</p>
                        <span>{formattedDate(item.createdAt)}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {dataUser !== null ? (
            <>
              <FavoriteBorderOutlinedIcon
                onClick={() => goToInternalLink("/profilpage/3")}
                sx={{
                  color: "white",
                  position: "relative",
                  top: "7px",
                  left: "-70px",
                  cursor: "pointer",
                }}
              />
              <div
                className={
                  showNotif.length === 0 || onProfilPage || menuDesktopNotifOpen
                    ? "notif-icon"
                    : "notif-icon animed-notif"
                }
              >
                {showNotif.length > 0 && <span>{showNotif.length}</span>}
                <NotificationsNoneOutlinedIcon
                  onClick={handleDesktopNotifMenu}
                  sx={{
                    color: "white",
                    position: "relative",
                    top: "7px",
                    left: "-40px",
                    cursor: "pointer",
                  }}
                ></NotificationsNoneOutlinedIcon>
              </div>

              <button
                onClick={handleDesktopMenu}
                className="addgap8 header_button connected-btn liner flex row"
              >
                {NoBackground ? (
                  <img src={profilIconWW} alt="profil-icon" />
                ) : (
                  <img src={profilIcon2} alt="profil-icon" />
                )}
                <p>{capitalizeFirstLetters(shortenText(userName, 14))}</p>
              </button>
            </>
          ) : (
            <>
              {/* <HelpOutlineOutlined
                onClick={() => goToInternalLink("/faq")}
                sx={{
                  color: "white",
                  position: "relative",
                  top: "7px",
                  left: "-70px",
                  cursor: "pointer",
                }}
              /> */}
              <button
                onClick={() => redirectToURL("/connexion")}
                className="hover-btn header_button"
              >
                {" "}
                <AccountCircleOutlinedIcon
                  sx={{ fontSize: "large", margin: "0 5px -4px 0" }}
                />{" "}
                S'inscrire / Se connecter{" "}
              </button>
            </>
          )}
        </div>

        {mobileMenuIsOpen === true ? (
          <div className="open-mobiel-menu">
            <div onClick={closeOpenMenuMobile} className="background"></div>
            <div className="contains flex colm">
              {dataUser !== null ? (
                <div className="top-user flex colm">
                  <button
                    onClick={() => goToInternalLink("/profilpage/1")}
                    className="hover-btn btn-menu flex row"
                  >
                    <img src={profilIcon} alt="profil-icon" />
                    <span>
                      {capitalizeFirstLetters(shortenText(userName, 22))}
                    </span>
                  </button>
                </div>
              ) : (
                <div className="top-user flex colm">
                  <button
                    onClick={() => redirectToURL("/connexion")}
                    className="hover-btn btn-menu no-conect flex row"
                  >
                    <div className="flex liner row">
                      <img src={profilIcon} alt="profil-icon" />
                      <div className="flex liner row addgap">
                        <span>Se connecter / </span>
                        <span> S'inscrire</span>
                      </div>
                    </div>
                  </button>
                </div>
              )}

              <button
                onClick={closeOpenMenuMobile}
                className="hover-btn close-btn"
              >
                <img src={closeIcon} alt="close-icon" />
              </button>
              <div className="contains-btn flex colm">
                <hr />
                <button
                  onClick={() => {
                    dispatch(setCarBrand([]));
                    redirectToURL("/acheter");
                  }}
                  className="hover-btn btn-menu flex row"
                >
                  <img src={moneyIcon} alt="money-icon" />
                  <span>Acheter</span>
                </button>
                <button
                  onClick={() => redirectToURL("/vendre")}
                  className="hover-btn btn-menu flex row"
                >
                  <img src={dollarIcon} alt="dollar-icon" />
                  <span>Vendre</span>
                </button>
                {dataUser !== null && (
                  <>
                    <button
                      onClick={() => goToInternalLink("/profilpage/3")}
                      className="hover-btn btn-menu flex row"
                    >
                      <img src={heartIcon} alt="heart-icon" />
                      <span>Favoris</span>
                    </button>
                  </>
                )}
                <button
                  onClick={() => goToInternalLink("/#")}
                  className="hover-btn btn-menu flex row wallet-btn"
                >
                  <img src={walletIcon} alt="wallet-icon" />
                  <span>Financement</span>
                </button>
                {dataUser !== null && (
                  <>
                    <hr />
                    <button
                      onClick={() => goToInternalLink("/profilpage/1")}
                      className="hover-btn btn-menu flex row"
                    >
                      <img src={settingIcon} alt="setting-icon" />
                      <span>Paramètres du compte</span>
                    </button>
                    <button
                      onClick={() => goToInternalLink("/profilpage/4")}
                      className="hover-btn btn-menu flex row"
                    >
                      <img src={clockIcon} alt="clock-icon" />
                      <span>Récemment consulté</span>
                    </button>
                    <button
                      onClick={() => goToInternalLink("/profilpage/2")}
                      className="hover-btn btn-menu flex row"
                    >
                      <img src={carIcon} alt="car-icon" />
                      <span>Mon garage</span>
                    </button>
                    <button
                      onClick={() => goToInternalLink("/profilpage/5")}
                      className="hover-btn btn-menu flex row notify-mobile"
                    >
                      {showNotif.length > 0 && (
                        <span className="notif-span">{showNotif.length}</span>
                      )}
                      <img src={smsIcon} alt="sms-icon" />
                      <span>Messages et notification</span>
                    </button>
                    <button
                      onClick={() => navigate("/faq")}
                      className="hover-btn btn-menu flex row"
                    >
                      <img src={helpIconMobile} alt="help-icon" />
                      <span>Aide</span>
                    </button>
                    <button
                      onClick={() => disconnectedMe()}
                      className="hover-btn btn-menu flex row"
                    >
                      <img src={logoutIcon} alt="logout-icon" />
                      <span>Déconnexion</span>
                    </button>
                  </>
                )}
                {dataUser === null && (
                  <>
                    <hr />
                    <button
                      onClick={() => navigate("/faq")}
                      className="hover-btn btn-menu flex row"
                    >
                      <img src={helpIconMobile} alt="help-icon" />
                      <span>Aide</span>
                    </button>
                  </>
                )}
              </div>
              <div className="copyright-menu flex colm">
                <hr />
                <span>Auto Plus 2023. Tous droits réservés.</span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </header>
  );
};
