import "../Styles/infoquicksell.css";
import arrowIcon from "../Images/imports/arrow-right-icon.svg";
import heroBackground from "../Images/caisseHero.png";
import heroBackground2 from "../Images/pngwing 3.png";

import { useEffect, useState } from "react";
import { redirectToURL } from "../Services/redirect.service";

export const InfoQuickSellNoProfil = ({setOpenModalBuyer}) => {
  return (
    <div className="quick-sale-container no-profil-quick-sell flex colm">
      <h2>Vendre mon véhicule</h2>
      <p>
        vendez votre véhicule en un rien de temps avec{" "}
        <span className="bolder">Auto plus</span>.
      </p>
      <img src={heroBackground} alt="hero-background" />
      <img
        className="mobile-hero-caisse"
        src={heroBackground2}
        alt="hero-background"
      />
      <div className="quick-sell flex colm">
        <div className="label-title-sale flex colm">
          <h3>Informations du véhicule</h3>
          <span>
            Vous devez compléter votre profil vendeur avant de pouvoir vendre
            vos différents véhicules.
          </span>
        </div>
        <form className="form-send flex row">
          <button
            onClick={() => setOpenModalBuyer(true)}
            className="flex row"
            type="button"
          >
            Je complète mon profil <img src={arrowIcon} alt="arrow-right" />
          </button>
        </form>
      </div>
    </div>
  );
};
