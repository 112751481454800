import React from "react";
import "../Styles/section-header2.css";

export const SectionHeader2 = ({ title, subtitle }) => {
  return (
    <div className="Header2_container flex colm">
      <p className="client_opinion_title">{title}</p>
      <p className="client_opinion_subtitle">{subtitle}</p>
    </div>
  );
};
