import { useEffect, useRef, useState } from "react";
import wrenchIcon from "../Images/imports/wrengIcon.svg";
import arrowsIconFilter from "../Images/arrow-down.svg";
import fillArrow from "../Images/imports/checkbox-fill-icon.svg";
import "../Styles/filterCondition.css";

export const ConditionFilter = ({ conditions, setCondition }) => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!isOpen);
  };

  const handleSelectCondition = (conditionName) => {
    let conditionTb = [];
    conditions.map((condi) => {
      if (condi.value === conditionName) {
        condi.active = !condi.active;
      }
      conditionTb.push(condi);
    });
    setCondition(conditionTb);
  };
  return (
    <div
      className={
        isOpen === true
          ? "btn-filter year-filter flex colm condition-filter condition-filter-is-open"
          : "btn-filter year-filter condition-filter flex colm"
      }
    >
      <button onClick={handleOpen} className="btn-select flex row btw">
        <div className="left flex row">
          <img src={wrenchIcon} />
          <span className="open-me-filter">Condition</span>
        </div>
        <img
          className={
            isOpen === true
              ? "img-arrow-turn is-open-filter-cars"
              : "img-arrow-turn"
          }
          src={arrowsIconFilter}
        />
      </button>
      <div className="input-condition flex row">
        {conditions.map((condi) => {
          return (
            <button
              onClick={() => handleSelectCondition(condi.value)}
              className={
                condi.active === true
                  ? "flex row active-filter-condition"
                  : "flex row"
              }
            >
              <div className="flex row">
                <img src={fillArrow} />
              </div>
              <span>{condi.value}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};
