import React from "react";
import "../Shared/Styles/politique.css";
import { FooterComposant } from "../Shared/Components/footer.composant";
import { useEffect } from "react";
import autoSimpleIcon from "../Shared/Images/imports/auto-simple.svg";
import { Header } from "../Shared/Components/header.component";
import { ReturnTop } from "../Shared/Components/ReturnTop";
export const Condition = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="politique-container">
      <Header NoBackground={false} />
      <ReturnTop top={0} />
      <div className="politique-contain flex colm max-width center-margin">
        <div className="title flex row liner addgap8">
          <h2>Conditions Générales d'AutoPlus</h2>
          <img
            style={{ width: "30px" }}
            src={autoSimpleIcon}
            alt="auto-plus-icon"
          />
        </div>
        <span>Date effective : 30 Juillet 2023</span>

        <p class="justify">
          Chez AutoPlus, nous vous remercions de votre confiance en utilisant
          notre site web. Veuillez lire attentivement les présentes Conditions
          Générales d'Utilisation <span>(CGU)</span> qui régissent votre
          utilisation du site AutoPlus. En accédant à notre site et en
          l'utilisant, vous acceptez les <span>CGU</span> dans leur intégralité.
          Si vous n'acceptez pas ces <span>CGU</span>, veuillez cesser
          immédiatement d'utiliser notre site.
        </p>

        <h5>I/ Utilisation du site</h5>
        <p class="justify">
          L'utilisation de notre site est soumise aux conditions suivantes :
        </p>

        <p>
          <span class="bolder">a-</span> Vous devez avoir au moins 18 ans pour
          utiliser notre site. Si vous avez moins de 18 ans, vous ne pouvez pas
          créer de compte ou utiliser nos services sans la supervision d'un
          adulte.
        </p>

        <p>
          <span class="bolder">b-</span> Vous êtes responsable de la
          confidentialité de votre compte et de votre mot de passe. Toute
          activité effectuée depuis votre compte est de votre responsabilité.
        </p>

        <p>
          <span class="bolder">c-</span> Vous acceptez de ne pas utiliser notre
          site à des fins illégales, abusives, diffamatoires, offensantes ou
          contraires aux présentes CGU.
        </p>

        <p>
          <span class="bolder">d-</span> Nous nous réservons le droit de
          suspendre ou de résilier votre compte si nous estimons que vous
          enfreignez les CGU ou si votre utilisation du site est inappropriée.
        </p>

        <h5>II/ Propriété intellectuelle</h5>
        <p class="justify">
          Tous les contenus présents sur notre site, y compris les textes, les
          images, les logos, les marques de commerce, les vidéos et les autres
          éléments, sont la propriété exclusive d'AutoPlus ou de ses concédants
          de licence. Vous n'êtes pas autorisé à reproduire, distribuer,
          afficher publiquement, modifier ou utiliser d'une autre manière tout
          contenu de notre site sans notre consentement écrit préalable.
        </p>

        <h5>III/ Liens vers des sites tiers</h5>
        <p class="justify">
          Notre site peut contenir des liens vers des sites web de tiers. Ces
          liens sont fournis uniquement à titre de commodité et nous n'avons
          aucun contrôle sur le contenu de ces sites tiers. Nous n'assumons
          aucune responsabilité quant au contenu ou à l'exactitude des
          informations présentes sur ces sites tiers. L'utilisation de ces sites
          est à vos propres risques.
        </p>

        <h5>IV/ Limitation de responsabilité</h5>
        <p class="justify">
          AutoPlus s'efforce de maintenir son site à jour et accessible en
          permanence. Cependant, nous ne pouvons garantir que le site sera
          toujours disponible, sécurisé ou exempt d'erreurs. Nous déclinons
          toute responsabilité pour tout dommage ou préjudice résultant de
          l'utilisation de notre site ou de l'incapacité à y accéder.
        </p>

        <h5>V/ Modification des CGU</h5>
        <p class="justify">
          Nous pouvons mettre à jour ces Conditions Générales d'Utilisation
          périodiquement. Nous vous informerons des modifications importantes en
          publiant une version mise à jour sur notre site. Il vous est conseillé
          de consulter régulièrement les CGU pour prendre connaissance des
          changements éventuels.
        </p>

        <h5>VI/ Contact</h5>
        <p class="justify">
          Si vous avez des questions ou des préoccupations concernant ces
          Conditions Générales d'Utilisation, veuillez nous contacter à
          l'adresse <span>[Insérer l'adresse e-mail de contact]</span>.
        </p>

        <p class="justify">
          Nous vous remercions de votre confiance en choisissant AutoPlus. Nous
          espérons que vous apprécierez votre expérience sur notre site.
        </p>
      </div>
      <FooterComposant />
    </div>
  );
};
