export function truncateString(str, lgn) {
  if (str.length > lgn) {
    return str.substring(0, lgn - 3) + "...";
  }
  return str;
}

export function capitalizeFirstLetters(str) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
