import "../Styles/encard-buy-car.css";
import essenceIcon from "../Images/EssenceIcon.svg";
import transmissionIcon from "../Images/TransmissionIcon.svg";
import kilometerIcon from "../Images/kilometerCarac.svg";

export const CarEncardBuy = ({ name, oil, transmission, kilometer }) => {
  return (
    <div className="encard-buy-car flex colm">
      <span>{name}</span>
      <div className="flex row">
        <div className="flex row liner">
          <img src={essenceIcon} />
          <p>{oil}</p>
        </div>
        <div className="flex row liner">
          <img src={transmissionIcon} />
          <p>{transmission}</p>
        </div>
        <div className="flex row liner">
          <img src={kilometerIcon} />
          <p>{kilometer}</p>
        </div>
      </div>
    </div>
  );
};
