import React, { useEffect, useRef } from "react";
import "../Shared/Styles/login.css";
import { Header } from "../Shared/Components/header.component";
import AutoPlusLogo from "../Shared/Images/imports/logo-primary-auto-plus-hide.svg";
import carImage from "../Shared/Images/pngwing11.png";
import vectorImage from "../Shared/Images/vectorConnexion.svg";
import eyeSlashIcon from "../Shared/Images/eye-slash.svg";
import smsIcon from "../Shared/Images/sms.svg";
import lockIcon from "../Shared/Images/lock.svg";
import fbIcon from "../Shared/Images/facebook1.svg";
import gIcon from "../Shared/Images/google1.svg";
import checkArrow from "../Shared/Images/imports/checkbox-fill-icon.svg";

import { useState } from "react";
import { Link } from "react-router-dom";
import { FooterComposant } from "../Shared/Components/footer.composant";
import NotificationService from "../Shared/Services/notification.service";
import { redirectToHome } from "../Shared/Services/redirect.service";
import env from "react-dotenv";
import { get, post, patch } from "../Shared/Services/api.service";
import { encryptData } from "../Shared/Services/encryption.service";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { LoginSocialFacebook } from "reactjs-social-login";
import { checkUserData } from "../Shared/Services/user.check.service";

export const Connexion = () => {
  async function checkUserStatus() {
    const valueUserData = await checkUserData();
    if (valueUserData !== null) {
      redirectToHome();
    }
  }
  useEffect(() => {
    checkUserStatus();
    return () => {};
  }, []);

  const [showMdp, setShowMdp] = useState(false);
  const refMail = useRef(null);
  const [remember, setRemember] = useState(false);

  useEffect(() => {
    if (refMail === null) {
      return;
    }
    setMailInput(refMail.current.value);
    return () => {};
  }, [refMail]);

  const handleChangeRemember = () => {
    setRemember(!remember);
  };
  const handleChangeShowMdp = () => {
    setShowMdp(!showMdp);
  };

  const [mailInput, setMailInput] = useState("");
  const [mdpInput, setMdpInput] = useState("");

  const [canLogin, setCanLogin] = useState(false);
  const [connexionInLoading, setConnexionInLoading] = useState(false);

  useEffect(() => {
    setConnexionInLoading(false);
    return () => {};
  }, []);

  useEffect(() => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (mailInput.match(mailformat) && mdpInput.length >= 8) {
      setCanLogin(true);
    } else {
      setCanLogin(false);
    }
    return () => {};
  }, [mailInput, mdpInput]);

  const encryptAndSave = (response) => {
    const encryptedUserDataSave = encryptData({
      token: response.data.token,
      email: response.data.email,
    });
    if (remember) {
      window.localStorage.setItem("userDataSave", encryptedUserDataSave);
    } else {
      window.sessionStorage.setItem("userDataSave", encryptedUserDataSave);
    }
    redirectToHome();
  };
  const connexionToAutoPlus = async () => {
    let data = {
      email: mailInput,
      password: mdpInput,
    };
    setConnexionInLoading(true);

    post("/users/login", data)
      .then(async (response) => {
        console.log(response.data);
        encryptAndSave(response);
      })
      .catch((error) => {
        console.error(error);
        NotificationService.addToQueue("Mot de passe ou email incorrect");
        setConnexionInLoading(false);
      });
  };

  const [loginSocialDisable, setLoginSocialDisable] = useState(false);
  const loginSocial = (val) => {
    setLoginSocialDisable(true);
    if (val === "g") launchGoogleLogin();
    if (val === "f") launchFacebookLogin();
  };
  const launchFacebookLogin = () => {};
  const launchGoogleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      // console.log(response);
      const url = "https://www.googleapis.com/oauth2/v3/userinfo";
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${response.access_token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          let datasend = {
            email: data.email.toLowerCase(),
            enable: data.email_verified,
            userId: data.sub,
            password: data.sub + "/" + env.SECRET_KEY,
            firstname: data.given_name,
            lastname: data.family_name,
            profile: "Buyer",
            phone: "",
            isSocial: "Google",
          };
          post("/users/social", datasend)
            .then((response) => {
              NotificationService.addToQueue("Connexion google réussie");
              setTimeout(() => {
                encryptAndSave(response);
              }, 2000);
            })
            .catch((error) => {
              setLoginSocialDisable(false);
              NotificationService.addToQueue(
                "Échec de la connexion avec Google"
              );
              return error;
            });
        })
        .catch((error) => {
          console.log(error);
          setLoginSocialDisable(false);
          NotificationService.addToQueue("Échec de la connexion avec Google");
        });
    },
    onError: () => {
      setLoginSocialDisable(false);
      NotificationService.addToQueue("Echec de la connexion avec google");
    },
  });

  return (
    <div className="login-connect-page connexion-page">
      <Header NoBackground={false} />
      <div className="login-page flex center-margin max-width">
        <img className="back-car" src={carImage} alt="cars-image" />
        <img className="back-vector" src={vectorImage} alt="vector-image" />
        <div className="contains flex colm">
          <div className="top-img-label flex colm">
            <img src={AutoPlusLogo} alt="autoPlusLogo" />
            <h4>Connexion</h4>
          </div>
          <form className="input-contains flex colm">
            <div className="input-me-login">
              <img src={smsIcon} alt="sms-icon" />
              <input
                disabled={connexionInLoading}
                onChange={(e) => setMailInput(e.target.value)}
                ref={refMail}
                type="email"
                placeholder="Adresse mail"
              />
            </div>
            <div className="input-me-login">
              <img src={lockIcon} alt="lock-icon" />
              <input
                disabled={connexionInLoading}
                onChange={(e) => setMdpInput(e.target.value)}
                type={showMdp === true ? "text" : "password"}
                placeholder="Mot de passe"
              />
              <button
                type="button"
                onClick={handleChangeShowMdp}
                className={showMdp === true ? "eye-slage o-back" : "hover-btn"}
              >
                <img
                  className={
                    showMdp === true ? "eye-slage o-back" : "eye-slage"
                  }
                  src={eyeSlashIcon}
                  alt="eye-slage-icon"
                />
              </button>
            </div>
            <div className="input-politique flex row">
              <button
                disabled={connexionInLoading}
                onClick={handleChangeRemember}
                className="flex hover-btn"
                type="button"
              >
                {remember === true ? (
                  <img src={checkArrow} alt="check-arrow" />
                ) : null}
              </button>
              <div className="flex row btw">
                <p>Se souvenir de moi</p>
                <p>
                  <Link className="hover-btn" to={"/password-forget"}>
                    Mot de passe oublié ?
                  </Link>
                </p>
              </div>
            </div>
          </form>
          <button
            disabled={!(canLogin && !connexionInLoading)}
            onClick={connexionToAutoPlus}
            className="hover-btn action-btn"
          >
            Se connecter
          </button>
          <div className="flex colm connect-gfb">
            <span>ou</span>
            <div className="flex row btn-login-soc">
              <LoginSocialFacebook
                appId="1932845737094413"
                onResolve={(response) => {
                  let datasend = {
                    email: response.data.email.toLowerCase(),
                    enable: true,
                    password: response.data.userID + "/" + env.SECRET_KEY,
                    firstname: response.data.short_name,
                    lastname: response.data.last_name,
                    profile: "Buyer",
                    phone: "",
                    isSocial: "Facebook",
                    userId: response.data.userID,
                  };
                  post("/users/social", datasend)
                    .then((responseApi) => {
                      NotificationService.addToQueue(
                        "Connexion Facebook réussie"
                      );
                      setTimeout(() => {
                        encryptAndSave(responseApi);
                      }, 2000);
                    })
                    .catch((error) => {
                      console.log(error);
                      setLoginSocialDisable(false);
                      NotificationService.addToQueue(
                        "Echec de la connexion avec FB"
                      );
                    });
                }}
                onReject={(response) => {
                  console.log(response);
                  setLoginSocialDisable(false);
                  NotificationService.addToQueue(
                    "Echec de la connexion avec FB"
                  );
                }}
                onError={(response) => {
                  console.log(response);
                  setLoginSocialDisable(false);
                  NotificationService.addToQueue(
                    "Echec de la connexion avec FB"
                  );
                }}
              >
                <button disabled={loginSocialDisable} className="hover-btn">
                  <img src={fbIcon} alt="fb-icon" />
                </button>
              </LoginSocialFacebook>
              <button
                disabled={loginSocialDisable}
                onClick={() => loginSocial("g")}
                className="hover-btn"
              >
                <img src={gIcon} alt="google-icon" />
              </button>
            </div>
          </div>
          <p className="switch-over">
            Vous n'avez pas de compte ?{" "}
            <Link className="hover-btn" to={"/inscription"}>
              Inscription
            </Link>
          </p>
        </div>
      </div>
      <FooterComposant />
    </div>
  );
};
