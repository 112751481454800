import { useEffect, useRef, useState } from "react";
import "./input.quick-sell.css";
import {
  checkIfCorrespond,
  compareMyInputInDatas,
} from "../../Services/filter-input.data.service";
import logoAutoPlus from "../../Images/imports/logo-primary-auto-plus.svg";
import fillArrow from "../../Images/imports/checkbox-fill-icon.svg";

export const QuickSelectedInput = ({
  title,
  inputPlaceholder,
  setInputVal,
  myAllPotentialData,
  iconImg,
  arrowImg,
  inputType = "text",
  maxheight = "107px",
  onlybinarysearch = false,
  onlyselectInput = false,
  preloadValue = "",
}) => {
  const refInput = useRef(null);
  const [allDatas, setAllDatas] = useState([]);
  const [currentPotentialDatas, setCurrentPotentialDatas] = useState([]);
  const [selectedMenuIsOpen, setSelectedMenuIsOpen] = useState(false);
  const [loadingIsOn, setLoadingOn] = useState(false);
  const onFocus = useRef(false);
  const [selected, setSelected] = useState("");
  useEffect(() => {
    if (myAllPotentialData === null || myAllPotentialData === undefined) return;
    setAllDatas(myAllPotentialData);
    // console.log(myAllPotentialData, title);
  }, [myAllPotentialData]);

  async function returnedCheck() {
    try {
      let dataTab = allDatas;
      return compareMyInputInDatas(
        refInput.current.value,
        dataTab,
        onlybinarysearch
      );
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async function verifyValue() {
    setLoadingOn(true);
    try {
      const returned = await returnedCheck();
      setCurrentPotentialDatas(returned.reverse());
      setLoadingOn(false);
    } catch (error) {
      setLoadingOn(false);
      console.log(error);
    }
  }

  function handleEditMe() {
    refInput.current.focus();
  }

  function checkAndShowValue() {
    let inputValue = "";
    inputValue = refInput.current.value;
    if (inputValue.length >= 3) {
      setSelectedMenuIsOpen(true);
      verifyValue();
    } else {
      setSelectedMenuIsOpen(false);
    }
  }

  const handleOnChange = () => {
    if (onlyselectInput) {
      refInput.current.value = selected;
    } else {
      setInputVal(refInput.current.value);
    }
    if (!onlyselectInput) checkAndShowValue();
    if (refInput.current.value.length >= 3 || onlyselectInput) {
      setSelectedMenuIsOpen(true);
    } else {
      setSelectedMenuIsOpen(false);
    }
  };

  const handleFocus = () => {
    onFocus.current = true;
    if (onlyselectInput) {
      setSelectedMenuIsOpen(true);
    } else checkAndShowValue();
  };

  const handleBlur = () => {
    onFocus.current = false;
    if (!onlyselectInput) {
      let find = [];
      find = checkIfCorrespond(refInput.current.value, allDatas);
      if (find.length > 0) {
        refInput.current.value = find[0].name;
        setSelected(find[0].name);
        setInputVal(find[0].name);
      }
    }
    setSelectedMenuIsOpen(false);
  };

  const valueSelected = (recievedValue) => {
    refInput.current.blur();
    if (
      onlyselectInput &&
      recievedValue.toString().toLowerCase() ===
        selected.toString().toLowerCase()
    ) {
      refInput.current.value = "";
      setSelected("");
      setInputVal("");
    } else {
      refInput.current.value = recievedValue;
      setSelected(recievedValue);
      setInputVal(recievedValue);
    }
    setSelectedMenuIsOpen(false);
  };

  useEffect(() => {
    if (refInput && preloadValue != "") {
      refInput.current.value = preloadValue;
      setInputVal(preloadValue);
      if (onlyselectInput === true) setSelected(preloadValue);
    }
  }, [refInput, preloadValue]);

  return (
    <div
      onClick={handleEditMe}
      className={
        (onFocus.current && "focus-selected-input-btn") +
        " selected-input-btn flex row btw addgap8 liner"
      }
    >
      <div className="flex addgap8 row liner">
        <img src={iconImg} alt={`icon-${title}`} />
        <input
          style={{ caretColor: onlyselectInput && "transparent" }}
          ref={refInput}
          onClick={handleEditMe}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleOnChange}
          type={inputType}
          placeholder={inputPlaceholder}
        />
      </div>
      {arrowImg && (
        <img
          className={
            (!onlyselectInput
              ? selectedMenuIsOpen &&
                currentPotentialDatas.length > 0 &&
                "open-menu"
              : selectedMenuIsOpen && "open-menu") + " arrow-icon-img"
          }
          src={arrowImg}
          alt={`arrow-${title}`}
        />
      )}

      {selectedMenuIsOpen && (
        <div
          style={{ maxHeight: maxheight }}
          className={
            (!loadingIsOn &&
              !onlyselectInput &&
              currentPotentialDatas.length === 0 &&
              "not-visible-selected ") + " open-selected-menu flex colm liner"
          }
        >
          {loadingIsOn ? (
            <div className="contains-load flex row liner addgap8">
              <div className="loading-div-circle"></div>
              <img src={logoAutoPlus} alt="logo-autoplus" />
            </div>
          ) : onlyselectInput ? (
            allDatas.map((item, count) => {
              return (
                <button
                  key={`btn-select-${title}-${count}`}
                  className="hover-btn selected-btn-menu flex row liner"
                  // style={{ alignItems: "flex-start" }}
                  onMouseDown={() => valueSelected(item.name)}
                >
                  <div className={"flex"}>
                    {item.name.toString().toLowerCase() ===
                      selected.toString().toLowerCase() && (
                      <img src={fillArrow} alt="arrow-fill" />
                    )}
                  </div>
                  <span>{item.name}</span>
                </button>
              );
            })
          ) : (
            currentPotentialDatas
              .slice(
                0,
                currentPotentialDatas.length > 10
                  ? 10
                  : currentPotentialDatas.length
              )
              .map((item) => {
                return (
                  <button
                    className="hover-btn selected-btn-menu"
                    onMouseDown={() => valueSelected(item.name)}
                  >
                    <span>{item.name}</span>
                  </button>
                );
              })
          )}
        </div>
      )}
    </div>
  );
};
