import { useEffect, useRef, useState } from "react";
import logoAutoPlus from "../Images/imports/logo-primary-white-auto-plus.svg";
import googlePlayBtnIcon from "../Images/PlayStore Button.svg";
import AppStoreBtnIcon from "../Images/AppStore Button.svg";
import fbIcon from "../Images/Facebook.svg";
import linkeIcon from "../Images/Linkedin.svg";
import instaIcon from "../Images/Instagram.svg";
import searchIcon from "../Images/imports/search-normal-icon.svg";

import "../Styles/footer-composant.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import env from "react-dotenv";
import NotificationService from "../Services/notification.service";
import { get, post, patch } from "../Services/api.service";
import { useDispatch } from "react-redux";
import { setCarBrand, setSearchTxt } from "../../feature/filter.data";
import { redirectToURL } from "../Services/redirect.service";
export const FooterComposant = () => {
  const [adresseMail, setAdresseMail] = useState("");
  const [canSendToNews, setCanSendToNews] = useState(false);
  const [addNewsInLoading, setAddNewsInLoading] = useState(false);
  const refMail = useRef();

  useEffect(() => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (adresseMail.match(mailformat)) {
      setCanSendToNews(true);
    } else {
      setCanSendToNews(false);
    }
  }, [adresseMail]);

  const handleChangeMail = (event) => {
    setAdresseMail(event.target.value);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goToInternalLink = (link, adder = null) => {
    dispatch(setCarBrand([]));
    if (adder) {
      dispatch(setSearchTxt(adder));
    } else {
      dispatch(setSearchTxt(""));
    }
    navigate(link);
  };

  const sendMailToNewsletter = () => {
    let data = {
      email: adresseMail,
    };
    setAddNewsInLoading(true);
    post("/services/send_newsletter", data)
      .then((response) => {
        refMail.current.value = "";
        setAdresseMail("");
        NotificationService.addToQueue(
          "Vôtre adresse mail a été ajouté à nôtre newsletter"
        );
        console.log(response.data);
        setAddNewsInLoading(false);
      })
      .catch((error) => {
        console.log(error);
        NotificationService.addToQueue(
          "Erreur lors de l'ajout à nôtre newsletter"
        );
        setAddNewsInLoading(false);
      });
  };
  const handleScrollToTop = () => {
    const element = document.querySelector("body");
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  return (
    <div id="footerDiv" className="footer-page">
      <footer className="mobile-footer center-margin max-width flex colm">
        <div className="logo-links flex row btw">
          <img
            onClick={handleScrollToTop}
            className="hover-btn"
            src={logoAutoPlus}
            alt="logo-autoplus"
          />
          <div className="flex row">
            <Link className="hover-btn" to={"#"} target="_blank">
              <img src={fbIcon} alt="facebook" />
            </Link>
            <Link className="hover-btn" to={"#"} target="_blank">
              <img src={linkeIcon} alt="linkedin" />
            </Link>
            <Link className="hover-btn" to={"#"} target="_blank">
              <img src={instaIcon} alt="instagram" />
            </Link>
          </div>
        </div>
        <hr />
        <div className="footer-contains flex colm">
          <div className="find-us flex colm">
            <img
              onClick={handleScrollToTop}
              className="hover-btn"
              src={logoAutoPlus}
              alt="logo-autoplus"
            />
            <p>Retrouvez nous sur</p>
            <div className="btn-store flex row">
              <button className="hover-btn">
                <img src={googlePlayBtnIcon} alt="google-store" />
              </button>
              <button className="hover-btn">
                <img src={AppStoreBtnIcon} alt="appstore-store" />
              </button>
            </div>
          </div>
          <div className="our-mark flex colm">
            <p>Nos marques</p>
            <div className="list flex row">
              <div className="flex colm">
                <button
                  onClick={() => goToInternalLink("/acheter", "Bmw")}
                  className="hover-btn"
                >
                  Bmw
                </button>
                <button
                  onClick={() => goToInternalLink("/acheter", "Mercedes")}
                  className="hover-btn"
                >
                  Mercedes
                </button>
                <button
                  onClick={() => goToInternalLink("/acheter", "Changan")}
                  className="hover-btn"
                >
                  Changan
                </button>
                <button
                  onClick={() => goToInternalLink("/acheter", "Citroen")}
                  className="hover-btn"
                >
                  Citroen
                </button>
              </div>
              <div className="flex colm">
                <button
                  onClick={() => goToInternalLink("/acheter", "Mazda")}
                  className="hover-btn"
                >
                  Mazda
                </button>
                <button
                  onClick={() => goToInternalLink("/acheter", "Susuki")}
                  className="hover-btn"
                >
                  Susuki
                </button>
                <button
                  onClick={() => goToInternalLink("/acheter", "Peugeot")}
                  className="hover-btn"
                >
                  Peugeot
                </button>
                <button
                  onClick={() => goToInternalLink("/acheter", "Citroen")}
                  className="hover-btn"
                >
                  Citroen
                </button>
              </div>
            </div>
          </div>
          <div className="navigation flex colm">
            <p>Navigation</p>
            <div className="list flex row">
              <div className="flex colm">
                <button
                  onClick={() => goToInternalLink("/vendre")}
                  className="hover-btn"
                >
                  Vendre
                </button>
                <button
                  onClick={() => goToInternalLink("/acheter")}
                  className="hover-btn"
                >
                  Acheter
                </button>
                <button
                  onClick={() => goToInternalLink("/financer")}
                  className="hover-btn"
                >
                  Financer
                </button>
              </div>
            </div>
          </div>
          <div className="contact-us flex colm">
            <p>Contactez nous</p>
            <div className="list flex row">
              <div className="flex colm">
                <Link to={"#"} className="hover-btn">
                  +225 00 00 00 00
                </Link>
                <Link
                  to={"mailto:service-autoplus@gmail.com"}
                  className="hover-btn"
                >
                  service-autoplus@gmail.com
                </Link>
                <span>Abidjan, Côte d'Ivoire</span>
                <div
                  style={{ columnGap: 24, marginTop: 16 }}
                  className="flex row"
                >
                  <Link className="hover-btn" to={"#"} target="_blank">
                    <img src={fbIcon} alt="facebook" />
                  </Link>
                  <Link className="hover-btn" to={"#"} target="_blank">
                    <img src={linkeIcon} alt="linkedin" />
                  </Link>
                  <Link className="hover-btn" to={"#"} target="_blank">
                    <img src={instaIcon} alt="instagram" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="newsletter flex colm">
            <p>Newsletter</p>
            <span>
              Abonnez vous à notre newsletter et recevez les dernières
              informations sur les deals.
            </span>
            <div className="input-newsletter flex row btw">
              <input
                onChange={(e) => handleChangeMail(e)}
                type="mail"
                placeholder="Adresse mail"
                ref={refMail}
              />
              <button
                disabled={
                  !(canSendToNews === true && addNewsInLoading === false)
                }
                onClick={sendMailToNewsletter}
                className="hover-btn flex row"
              >
                <img src={searchIcon} alt="search" />
                Envoyer
              </button>
            </div>
          </div>
          <div className="last-info flex colm">
            <hr />
            <div className="info flex colm">
              <button
                onClick={() => goToInternalLink("/faq")}
                className="hover-btn"
              >
                Aide
              </button>
              <button
                onClick={() => goToInternalLink("/condition")}
                className="hover-btn"
              >
                Conditions d'utilisation
              </button>
              <button
                onClick={() => goToInternalLink("/politique")}
                className="hover-btn"
              >
                Politique de confidentialité
              </button>
              <span>© 2023 Auto plus. Tous droits réservés.</span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
