import React, { useState } from "react";
import "../Styles/car-category-card.css";
import { CarCategoryCardContainer } from "./car-category-card-container";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Monospace from "../Images/Monospace.svg";
import Suv from "../Images/Suv.svg";
import Berline from "../Images/Berline.svg";
import MiniSuv from "../Images/Mini suv.svg";
import Coupé from "../Images/Coupé.svg";
import Pickup from "../Images/Pickup.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, Scrollbar } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { setCarBrand } from "../../feature/filter.data";

export const CarCategoryCard = ({ catego, buyingMenu = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataCategoryFilter = useSelector(
    (state) => state.filterDataConf.carBrand
  );
  
  const [alreadyVerify, setAlreadyVerify] = useState(false);

  let cats = [
    { name: "Crossover", img: Monospace },
    { name: "Suv", img: Suv },
    { name: "Berline", img: Berline },
    { name: "Limousine", img: MiniSuv },
    { name: "Coupé", img: Coupé },
    { name: "Pick-Up", img: Pickup },
  ];

  const [idActiveName, setIsActiveName] = useState([]);

  const checkAndActiveCatego = () => {
    if (JSON.stringify(dataCategoryFilter) === JSON.stringify(idActiveName)) {
      return;
    }
    let data = dataCategoryFilter;
    setIsActiveName(dataCategoryFilter);
    // dispatch(setCarBrand([]));
    setAlreadyVerify(true);
    setTimeout(() => {
      dispatch(setCarBrand(data));
    }, 100);
  };

  useEffect(() => {
    if (buyingMenu === true) {
      if (dataCategoryFilter)
        if (dataCategoryFilter.length > 0 ) checkAndActiveCatego();
    }
  }, [dataCategoryFilter]);

  const selectedFilter = (val) => {
    let find = false;
    let tab = [];
    idActiveName.map((cat) => {
      if (cat === val) {
        find = true;
      } else {
        tab.push(cat);
      }
    });

    if (find === false) {
      tab.push(val);
    }
    setIsActiveName(tab);

    dispatch(setCarBrand(tab));
    if (buyingMenu === false) {
      navigate("/acheter");
    }
  };

  const checkContains = (val) => {
    let find = false;
    idActiveName.map((cat) => {
      let a = cat.toString().toLowerCase();
      let b = val.toString().toLowerCase();
      if (a === b && a.length === b.length) {
        find = true;
      }
    });
    return find;
  };

  return (
    <div className="CarCategory_card_container car-cats">
      <div className="container">
        {cats.map((cat, count) => {
          return (
            <button
              key={count + "btn-catego"}
              className={
                checkContains(cat.name) ? "one-cat selected-one-cat" : "one-cat"
              }
              onClick={() => {
                selectedFilter(cat.name);
              }}
            >
              <img src={cat.img} />
              <span>{cat.name}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};
