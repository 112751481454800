import React from "react";
import "../Styles/quicksale.css";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import SellMyCar from "../Images/VENDRE MON VEHICULE.png";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import DirectionsCarFilledRoundedIcon from "@mui/icons-material/DirectionsCarFilledRounded";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import LocalGasStationOutlinedIcon from "@mui/icons-material/LocalGasStationOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import ModeleVehiculeIcon from "../Images/ModeleVehiculeIcon.svg";
import WrenchScrewDriverIcon from "../Images/wrench-screwdriver-Icon.svg";
import GearShiftIcon from "../Images/QuickSaleTransmissionIcon.svg";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const card = <></>;

export const QuickSale = () => {
  const [brand, setBrand] = React.useState("");

  const handleChange = (event) => {
    setBrand(event.target.value);
  };

  const [oil, setOil] = React.useState("");

  const handleChange2 = (event) => {
    setOil(event.target.value);
  };

  const [transmission, setTransmission] = React.useState("");

  const handleChange3 = (event) => {
    setTransmission(event.target.value);
  };

  const [carstatut, setCarstatut] = React.useState("");

  const handleChange4 = (event) => {
    setCarstatut(event.target.value);
  };
  return (
    <>
      <img src={SellMyCar} alt="SellMyCar" className="BackG" />
      <div className="quick-sale-container-input">
        <p className="QuickSale_title">Vendre mon véhicule</p>

        <div className="QuickSale_card">
          <p className="QuickSale_Card_title">Informations du véhicule</p>

          <p className="QuickSale_Card_subtitle">
            Remplissez le formulaire ci dessous.
          </p>

          <div className="inputs-quick-sale flex colm">
            <div className="QuickSale_input_container">
              <div className="flex row info-input">
                <div className="QuickSale_icon flex">
                  <DirectionsCarFilledRoundedIcon className="QuickSale_card_icon" />
                </div>
                <div className="QuickSale_input">
                  <input
                    className="QuickSale_card_input"
                    placeholder="Marque du véhicule"
                  />
                </div>
              </div>
            </div>

            <div className="QuickSale_SmallInput_container">
              <div className="QuickSale_input_container2">
                <div className="flex row info-input">
                  <div className="QuickSale_icon flex">
                    <img src={ModeleVehiculeIcon} alt="VehiculePicture" />
                  </div>
                  <div className="QuickSale_input">
                    <input
                      className="QuickSale_card_input"
                      placeholder="Modèle du véhicule"
                    />
                  </div>
                </div>
              </div>

              <div className="QuickSale_input_container2">
                <div className="flex row info-input">
                  <div className="QuickSale_icon flex">
                    <CalendarMonthOutlinedIcon className="QuickSale_card_icon" />
                  </div>
                  <div className="QuickSale_input">
                    <input
                      className="QuickSale_card_input"
                      placeholder="Année du véhicule"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="QuickSale_SmallInput_container">
              <div className="QuickSale_input_container2">
                <div className="flex row info-input">
                  <div className="QuickSale_icon flex">
                    <LocalGasStationOutlinedIcon className="QuickSale_card_icon" />
                  </div>
                  <div className="QuickSale_input">
                    <input
                      className="QuickSale_card_input"
                      placeholder="Type de carburant"
                    />
                  </div>
                </div>
                <div>
                  <KeyboardArrowDownOutlinedIcon />
                </div>
              </div>

              <div className="QuickSale_input_container2">
                <div className="flex row info-input">
                  <div className="QuickSale_icon flex">
                    <SpeedOutlinedIcon className="QuickSale_card_icon" />
                  </div>
                  <div className="QuickSale_input">
                    <input
                      className="QuickSale_card_input"
                      placeholder="Rentrer le Kilometrage"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="QuickSale_SmallInput_container">
              <div className="QuickSale_input_container2">
                <div className="flex row info-input">
                  <div className="QuickSale_icon flex">
                    <img src={GearShiftIcon} alt="GearShiftIcon" />
                  </div>
                  <div className="QuickSale_input">
                    <input
                      className="QuickSale_card_input"
                      placeholder="Type de transmission"
                    />
                  </div>
                </div>
                <div>
                  <KeyboardArrowDownOutlinedIcon />
                </div>
              </div>

              <div className="QuickSale_input_container2">
                <div className="flex row info-input">
                  <div className="QuickSale_icon flex">
                    <img
                      src={WrenchScrewDriverIcon}
                      alt="WrenchScrewDriverIcon"
                    />
                  </div>
                  <div className="QuickSale_input">
                    <input
                      className="QuickSale_card_input"
                      placeholder="Condition du véhicule"
                    />
                  </div>
                </div>
                <div>
                  <KeyboardArrowDownOutlinedIcon />
                </div>
              </div>
            </div>

            <div>
              <button className="QuickSale_Send_Button">Envoyer</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
