import React from "react";
import "../Styles/section-header4.css";

export const SectionHeader4 = ({ title, subtitle }) => {
  return (
    <div className="header-title-second flex colm">
      <p className="BuyPage_title">{title}</p>
      <p className="BuyPage_subtitle">{subtitle}</p>
    </div>
  );
};
