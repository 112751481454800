import { createSlice } from "@reduxjs/toolkit";

export const changeDataPlusData = createSlice({
  name: "autoplus-input-value",
  initialState: {
    brands: [],
    motorTypes: [],
    carTypes: [],
    transmissions: [],
    conditions: [],
    motorPowers: [],
    favoris: [],
  },
  reducers: {
    setDataAutoplusBMTC: (state, { payload }) => {
      state.brands = payload.brands;
      state.motorTypes = payload.motorTypes;
      state.carTypes = payload.carTypes;
      state.transmissions = payload.transmissions;
      state.conditions = payload.conditions;
      state.motorPowers = payload.motorPowers;
    },
    setDataFavoris: (state, { payload }) => {
      state.favoris = payload;
    },
  },
});

export const { setDataAutoplusBMTC, setDataFavoris } =
  changeDataPlusData.actions;
export default changeDataPlusData.reducer;
