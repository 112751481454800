import React from "react";
import "../Styles/section-header3.css";

export const SectionHeader3 = ({ title, subtitle }) => {
  return (
    <div className="why-us-container flex colm">
      <p className="why_us_title">{title}</p>
      <p className="why_us_subtitle">{subtitle}</p>
    </div>
  );
};
