import { useState } from "react";
import App from "./App";
import { useEffect } from "react";
import { decryptData, encryptData } from "./Shared/Services/encryption.service";
import { post } from "./Shared/Services/api.service";
import { useDispatch } from "react-redux";
import { setDataUser } from "./feature/user.data";

function CheckerData() {
  const dispatch = useDispatch();
  const [loadApp, setLoadApp] = useState(false);

  const hideLoader = () => {
    setLoadApp(true);
    document
      .getElementsByClassName("preloader-container")[0]
      .classList.add("preloader-out");

    setTimeout(function () {
      document.getElementsByClassName("preloader-container")[0].style.display =
        "none";
    }, 700);
  };
  const encryptAndSave = (response) => {
    const encryptedUserDataSave = encryptData({
      token: response.data.token,
      email: response.data.email,
    });
    if (sessionStorage.getItem("userDataSave")) {
      window.sessionStorage.setItem("userDataSave", encryptedUserDataSave);
    } else {
      window.localStorage.setItem("userDataSave", encryptedUserDataSave);
    }
    hideLoader();
  };
  const searchUserInfo = async (data) => {
    const decryptUserData = await decryptData(data);
    post("/users/auto_login", {
      token: decryptUserData.token,
      email: decryptUserData.email,
    })
      .then(async (response) => {
        let crypt = await encryptData({
          id: response.data.id,
          email: response.data.email,
          firstname: response.data.firstname,
          lastname: response.data.lastname,
          phone: response.data.phone,
          location: response.data.location,
          profile: response.data.profile,
          isSocial: response.data.isSocial,
          emailConfirmedAt: response.data.emailConfirmedAt,
          checkVendor: response.data.checkVendor,
          cni: response.data.cni,
        });
        dispatch(
          setDataUser({
            data: crypt,
            dataFetched: true,
          })
        );
        encryptAndSave(response);
      })
      .catch((error) => {
        console.log(error);
        window.sessionStorage.removeItem("userdata");
        window.sessionStorage.removeItem("userDataSave");
        window.localStorage.removeItem("userdata");
        window.localStorage.removeItem("userDataSave");
        hideLoader();
      });
  };
  useEffect(() => {
    const userDataSaveInSession = sessionStorage.getItem("userDataSave");
    const userDataSaveInLocalStorage = localStorage.getItem("userDataSave");
    if (userDataSaveInSession || userDataSaveInLocalStorage) {
      searchUserInfo(userDataSaveInSession || userDataSaveInLocalStorage);
    } else {
      hideLoader();
    }
  }, []);
  return <>{loadApp && <App />}</>;
}

export default CheckerData;
