import "../Styles/infoquicksell.css";
import marqueIcon from "../Images/car2.svg";
import calendarIcon from "../Images/calendar.svg";
import gassIcon from "../Images/gassIcon.svg";
import gearShiftIcon from "../Images/gearshift.svg";
import kilometerIcon from "../Images/kilometer.svg";
import wingIcon from "../Images/wing.svg";
import wrenchIcon from "../Images/wrench.svg";
import arrowIcon from "../Images/arrow-down.svg";
import heroBackground from "../Images/pngwing 3.png";
import vectorBackground from "../Images/imports/vectorquicksell.svg";
import fillArrow from "../Images/imports/checkbox-fill-icon.svg";
import arrowTil from "../Images/imports/send.svg";
import trashIcon from "../Images/imports/trash-icon.svg";
import coinIcon from "../Images/imports/coin-icon.svg";
import seatIcon from "../Images/imports/seat-icon.svg";
import doorIcon from "../Images/imports/car-doors.svg";
import carTypeIcon from "../Images/imports/car.svg";

import { useEffect, useState } from "react";
import { QuickSelectedInput } from "./quick-sell-input/QuickSelectedInput";
import axios from "axios";
import { get, post, patch } from "../Services/api.service";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { useSelector } from "react-redux";
import { getMyId } from "../Services/get-goodId-static";
import NotificationService from "../Services/notification.service";
import { useNavigate } from "react-router-dom";
import { checkUserData } from "../Services/user.check.service";
import env from "react-dotenv";
import { SelectComplementary } from "./quick-sell-input/QuickSelectComplementary";
import { encryptData } from "../Services/encryption.service";

export const InfoQuickSell = () => {
  const [dataUser, setDataUser] = useState();

  const fetchUserData = async () => {
    const valueUserData = await checkUserData();
    if (valueUserData !== null) {
      setDataUser(valueUserData);
    } else {
      setDataUser(null);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const dataBrands = useSelector((state) => state.dataConf.brands);
  const dataModels = useSelector((state) => state.dataConf.carTypes);
  const dataCarsType = useSelector((state) => state.dataConf.carTypes);
  const dataOilsType = useSelector((state) => state.dataConf.motorTypes);
  const dataTransmissions = useSelector(
    (state) => state.dataConf.transmissions
  );
  const dataMotorPowers = useSelector((state) => state.dataConf.motorPowers);
  const dataConditions = useSelector((state) => state.dataConf.conditions);

  const [allBrands, setBrands] = useState([]);
  const [allModels, setModels] = useState([]);
  const [currentOilType, setCurrentOilType] = useState([]);
  const [allCarsType, setCarsType] = useState([]);
  const [allCarsMotors, setCarsMotors] = useState([]);
  const [currentTransmission, setCurrentTransmission] = useState([]);
  const [currentCondition, setCurrentCondition] = useState([]);
  const [lastYears, setLastYears] = useState([]);
  const [currCarTypeValue, setCurrCartypeValue] = useState("");
  const [currCarMotorValue, setCurrCarMotorValue] = useState("");

  const fetchFindYear = () => {
    const currentYear = new Date().getFullYear();
    let last200Years = [];
    for (let i = currentYear; i >= currentYear - 199; i--) {
      last200Years.push({ name: i, value: i });
    }
    setLastYears(last200Years);
  };
  useEffect(() => {
    fetchFindYear();
  }, []);

  const [currMarqueValue, setCurrMarqueValue] = useState("");
  const [currModelValue, setCurrModelValue] = useState("");
  const [currentYearValue, setCurrentYearValue] = useState(0);
  const [currOilTypeValue, setCurrOilTypeValue] = useState("");
  const [currKilometerValue, setKilometerValue] = useState("");
  const [currTransmissionValue, setCurrTransmissionValue] = useState("");
  const [currConditionValue, setCurrConditionValue] = useState("");
  const [currPriceValue, setCurrPriceValue] = useState(0);
  const [currDoorValue, setCurrDoorValue] = useState(0);
  const [currPlaces, setCurrPlaces] = useState(0);

  useEffect(() => {
    if (dataBrands === undefined || dataBrands === null) return;
    if (dataModels === undefined || dataModels === null) return;
    if (dataCarsType === undefined || dataCarsType === null) return;
    if (dataOilsType === undefined || dataOilsType === null) return;
    if (dataTransmissions === undefined || dataTransmissions === null) return;
    if (dataConditions === undefined || dataConditions === null) return;
    if (dataMotorPowers === undefined || dataMotorPowers === null) return;
    setBrands(dataBrands);
    setModels(dataModels);
    setCurrentOilType(dataOilsType);
    setCarsType(dataCarsType);
    setCurrentTransmission(dataTransmissions);
    setCurrentCondition(dataConditions);
    setCarsMotors(dataMotorPowers);
  }, [
    dataBrands,
    dataModels,
    dataOilsType,
    dataTransmissions,
    dataConditions,
    dataMotorPowers,
  ]);

  const [canGoNext, setCanGoNext] = useState(false);
  const [canSend, setCanSend] = useState(false);

  useEffect(() => {
    if (
      currMarqueValue.trim().length >= 1 &&
      currModelValue.trim().length >= 1 &&
      currentYearValue.toString().trim().length >= 4 &&
      currOilTypeValue.trim().length >= 2 &&
      parseInt(currKilometerValue.toString().trim()) >= 0 &&
      currTransmissionValue.trim().length >= 2 &&
      currConditionValue.trim().length >= 2 &&
      currCarTypeValue.trim().length >= 2
    ) {
      setCanGoNext(true);
    } else setCanGoNext(false);
  }, [
    currMarqueValue,
    currCarTypeValue,
    currModelValue,
    currentYearValue,
    currOilTypeValue,
    currKilometerValue,
    currTransmissionValue,
    currConditionValue,
  ]);

  const [page, setPage] = useState(1);

  const goOnInputPage = () => {
    setPage(1);
  };
  const goOnPhotosPage = () => {
    setPage(2);
  };
  const goBackPage = () => {
    setPage(page - 1);
  };
  const goNextPage = () => {
    setPage(page + 1);
  };
  const goOnImportPage = () => {
    if (dataUser === null) {
      NotificationService.addToQueue(
        "Vous devez être connecté et avoir renseigner vôtre profil dans le menu Mon compte"
      );
      return;
    }
    if (dataUser.profile.toLowerCase() !== "seller") {
      NotificationService.addToQueue(
        "Completer vôtre profil dans le menu Mon compte pour continuer"
      );
      return;
    }
    setPage(2);
  };
  const goToOthersCarPage = () => {
    setPage(3);
  };

  /*IMPORT IMAGES*/
  const [selectedImages, setSelectedImages] = useState([]);
  const handleImageChange = (event) => {
    const files = event.target.files;
    const imageFiles = Array.from(files).filter((file) => {
      return file.type === "image/jpeg" || file.type === "image/png";
    });
    setSelectedImages([...selectedImages, ...imageFiles]);
  };
  const handleRemoveImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };
  const [quickComplementaryExterieur, setQuickComplementaryExterieur] =
    useState([
      { id: 1, text: "Aide parking", active: false },
      { id: 2, text: "Phares à allumage automatique", active: false },
      { id: 3, text: "Ampoules de phares led", active: false },
      { id: 4, text: "Feux arrières à LED", active: false },
      {
        id: 5,
        text: "Rétroviseurs extérieurs réglage électrique",
        active: false,
      },
      { id: 6, text: "Rétroviseurs rabattables électriquement", active: false },
      { id: 7, text: "Démarrage à distance", active: false },
    ]);
  const [quickComplementaryInterieur, setQuickComplementaryInterieur] =
    useState([
      { id: 1, text: "Palettes au volant", active: false },
      { id: 2, text: "Fermerture électrique du coffre", active: false },
      { id: 3, text: "Air conditionné", active: false },
      { id: 4, text: "Apple ou Android Car play", active: false },
      { id: 5, text: "Sièges électrique", active: false },
      { id: 6, text: "Ouverture du coffre à distance", active: false },
      { id: 7, text: "Bluetooth", active: false },
      { id: 8, text: "Écran de bord", active: false },
      { id: 9, text: "Affichage tête haute projeté (ADD)", active: false },
      { id: 10, text: "Apple CarPlay / Android Auto", active: false },
    ]);
  const [quickComplementarySecurity, setQuickComplementarySecurity] = useState([
    { id: 1, text: "Capteur d'angle mort", active: false },
    { id: 2, text: "Contrôle de pression des pneus", active: false },
  ]);
  const [quickComplementaryOthers, setQuickComplementaryOthers] = useState([
    { id: 1, text: "Caméra de recul", active: false },
  ]);
  const [addCarsLoading, setLoadAddCars] = useState(false);

  const checkCanGoSendCar = () => {
    if (currMarqueValue.trim().length < 1) return false;
    if (currCarMotorValue.toString().trim().length < 2) return false;
    if (getMyId(currConditionValue, dataConditions) === null) return false;
    if (currDoorValue.toString().trim().length === 0) return false;
    if (parseInt(currKilometerValue.toString().trim()) < 0) return false;
    if (currModelValue.toString().trim().length < 1) return false;
    if (getMyId(currOilTypeValue, dataOilsType) === 0) return false;
    if (currPlaces.toString().trim().length === 0) return false;
    if (currPriceValue.toString().trim().length === 0) return false;
    if (getMyId(currTransmissionValue, dataTransmissions) === 0) return false;
    if (currentYearValue.toString().trim().length < 4) return false;
    if ([...selectedImages].length < 5) return false;
    return true;
  };
  useEffect(() => {
    setCanSend(checkCanGoSendCar());
  }, [
    currMarqueValue,
    currConditionValue,
    currCarMotorValue,
    currDoorValue,
    currKilometerValue,
    currModelValue,
    currOilTypeValue,
    currPlaces,
    currPriceValue,
    currTransmissionValue,
    currentYearValue,
    selectedImages,
  ]);
  const navigate = useNavigate();
  const sendCar = async (newImg) => {
    let newCarData = await {
      ownerId: dataUser.id,
      brandName: currMarqueValue,
      carTypeId: getMyId(currCarTypeValue, allCarsType),
      motorPowerId: getMyId(currCarMotorValue, allCarsMotors)
        ? getMyId(currCarMotorValue, allCarsMotors)
        : null,
      conditionId: getMyId(currConditionValue, dataConditions),
      doors: currDoorValue,
      usedBy: 0,
      kilometers: currKilometerValue,
      model: currModelValue,
      reportingNotes: "",
      motorTypeId: getMyId(currOilTypeValue, dataOilsType),
      places: currPlaces,
      price: currPriceValue,
      transmissionId: getMyId(currTransmissionValue, dataTransmissions),
      year: currentYearValue,
      thumb: newImg[0],
      images: newImg.slice(1),
      exteriorsTabObjectValue: encryptData(quickComplementaryExterieur),
      interiorsTabObjectValue: encryptData(quickComplementaryInterieur),
      securityTabObjectValue: encryptData(quickComplementarySecurity),
      othersTabObjectValue: encryptData(quickComplementaryOthers),
    };
    post(`/cars`, newCarData)
      .then((response) => {
        NotificationService.addToQueue(
          `Ajout reussi de la ${currMarqueValue} ${currModelValue}`
        );
        setTimeout(() => {
          navigate("/profilPage/2");
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        setLoadAddCars(false);
        NotificationService.addToQueue("Erreur lors de l'ajout de la voiture");
      });
  };
  const sendVehicule = async () => {
    if (checkCanGoSendCar() === false) return;
    if ([...selectedImages].length <= 4) {
      NotificationService.addToQueue(
        "Il faut au minimun 5 images du véhicule pour continuer"
      );
      return;
    }
    NotificationService.addToQueue("Ajout de votre voiture en cours");
    const imageNames = [];
    setLoadAddCars(true);
    try {
      for await (const image of selectedImages) {
        const data = new FormData();
        data.append("image", image, "cars");
        await axios
          .post(env.API_PRIVATE_URL + "/services/upload_image", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            imageNames.push(response.data.filename);
          })
          .catch((error) => {
            return error;
          });
      }
      sendCar(imageNames);
    } catch (error) {
      console.error("Erreur lors de l'upload des images :", error);
    }
  };

  const getNewTab = (itemId, table) => {
    let newTab = [];
    table.map((item) => {
      let objectI = item;
      if (objectI.id === itemId)
        newTab.push({ ...objectI, active: !objectI.active });
      else newTab.push(objectI);
    });
    return newTab;
  };

  function applyOnExterieurTab(e, itemId) {
    setQuickComplementaryExterieur(
      getNewTab(itemId, quickComplementaryExterieur)
    );
    e.stopPropagation();
  }
  function applyOnInterieurTab(e, itemId) {
    setQuickComplementaryInterieur(
      getNewTab(itemId, quickComplementaryInterieur)
    );
    e.stopPropagation();
  }
  function applyOnSecurityTab(e, itemId) {
    setQuickComplementarySecurity(
      getNewTab(itemId, quickComplementarySecurity)
    );
    e.stopPropagation();
  }
  function applyOnOthersTab(e, itemId) {
    setQuickComplementaryOthers(getNewTab(itemId, quickComplementaryOthers));
    e.stopPropagation();
  }
  return (
    <div className="quick-sale-container center-margin max-width flex colm">
      <h2>Vendre mon véhicule</h2>
      <p>vendez votre véhicule en un rien de temps avec Auto plus.</p>
      <img src={vectorBackground} alt="vector-background" />
      <img src={heroBackground} alt="hero-background" />
      {addCarsLoading && (
        <div className="load-patch-data flex">
          <div className="no-find-car loader-coming max-width center-margin flex">
            <div class="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      )}
      <div className="quick-sell-container">
        <div className="page flex row line">
          <div className={page === 1 ? "active" : ""}></div>
          <div className={page === 2 ? "active" : ""}></div>
          <div className={page === 3 ? "active" : ""}></div>
          <div className={page === 4 ? "active" : ""}></div>
          <div className={page === 5 ? "active" : ""}></div>
        </div>
        <div
          className={
            (page === 1 ? "onepage-input" : "noinpage-input") +
            " quick-sell input-quick-sell flex colm"
          }
        >
          <div className="label-title-sale flex colm">
            <h3>Informations du véhicule</h3>
            <span>
              vendez votre véhicule en un rien de temps avec Auto plus.
            </span>
          </div>
          <form className="form-send flex row">
            <QuickSelectedInput
              title={"marquevehicule"}
              inputPlaceholder={"Marque du véhicule"}
              setInputVal={setCurrMarqueValue}
              myAllPotentialData={allBrands}
              iconImg={marqueIcon}
              arrowImg={null}
              maxheight="180px"
            />
            <QuickSelectedInput
              title={"modelevehicule"}
              inputPlaceholder={"Modèle du véhicule"}
              setInputVal={setCurrModelValue}
              myAllPotentialData={[]}
              iconImg={wingIcon}
              arrowImg={null}
            />
            <QuickSelectedInput
              title={"cartype"}
              inputPlaceholder={"Type de véhicule"}
              setInputVal={setCurrCartypeValue}
              myAllPotentialData={allCarsType}
              iconImg={carTypeIcon}
              arrowImg={arrowIcon}
              maxheight="180px"
              onlyselectInput={true}
            />
            <QuickSelectedInput
              title={"calendar"}
              inputPlaceholder={"Année du véhicule"}
              setInputVal={setCurrentYearValue}
              myAllPotentialData={lastYears}
              iconImg={calendarIcon}
              arrowImg={null}
              inputType="number"
              onlybinarysearch={true}
              maxheight="230px"
            />
            <QuickSelectedInput
              title={"oiltype"}
              inputPlaceholder={"Type de carburant"}
              setInputVal={setCurrOilTypeValue}
              myAllPotentialData={currentOilType}
              iconImg={gassIcon}
              arrowImg={arrowIcon}
              maxheight="180px"
              onlyselectInput={true}
            />
            <QuickSelectedInput
              title={"kilometer"}
              inputPlaceholder={"Rendre le kilometrage"}
              setInputVal={setKilometerValue}
              myAllPotentialData={[]}
              iconImg={kilometerIcon}
              arrowImg={null}
            />
            <QuickSelectedInput
              title={"transmission"}
              inputPlaceholder={"Type de transmission"}
              setInputVal={setCurrTransmissionValue}
              myAllPotentialData={currentTransmission}
              iconImg={gearShiftIcon}
              arrowImg={arrowIcon}
              onlyselectInput={true}
            />
            <QuickSelectedInput
              title={"condition"}
              inputPlaceholder={"Condition du véhicule"}
              setInputVal={setCurrConditionValue}
              myAllPotentialData={currentCondition}
              iconImg={wrenchIcon}
              arrowImg={arrowIcon}
              maxheight="180px"
              onlyselectInput={true}
            />
            <button
              onClick={goOnImportPage}
              disabled={!canGoNext}
              type="button"
            >
              Suivant
            </button>
          </form>
        </div>
        <div
          className={
            (page === 1
              ? "noinpage-import"
              : page >= 3
              ? "noinpage-car"
              : "inpage-import") + " quick-sell import-quick-sell flex colm"
          }
        >
          <div className="label-title-sale flex colm">
            <h3>Photos et details du véhicule</h3>
            <span>
              Ajoutez quelques détails important comme le prix et une ou
              plusieurs images du véhicule.
            </span>
          </div>
          <form className="form-send flex row addgap8">
            <QuickSelectedInput
              title={"price"}
              inputPlaceholder={"Votre prix de vente"}
              setInputVal={setCurrPriceValue}
              myAllPotentialData={[]}
              iconImg={coinIcon}
              arrowImg={null}
              inputType="number"
            />
            <QuickSelectedInput
              title={"carMotor"}
              inputPlaceholder={"Type de moteur"}
              setInputVal={setCurrCarMotorValue}
              myAllPotentialData={allCarsMotors}
              iconImg={carTypeIcon}
              arrowImg={null}
              maxheight="180px"
            />
            <QuickSelectedInput
              title={"doors"}
              inputPlaceholder={"Nombre de portes"}
              setInputVal={setCurrDoorValue}
              myAllPotentialData={[]}
              iconImg={doorIcon}
              arrowImg={null}
              inputType="number"
            />
            <QuickSelectedInput
              title={"places"}
              inputPlaceholder={"Nombre de places"}
              setInputVal={setCurrPlaces}
              myAllPotentialData={[]}
              iconImg={seatIcon}
              arrowImg={null}
              inputType="number"
            />
            <div className="clipped-swipper-scroll">
              <Swiper
                modules={[FreeMode, Navigation]}
                spaceBetween={10}
                slidesPerView={"auto"}
                freeMode={true}
                navigation={true}
                className="swipper-import-img"
              >
                {selectedImages.map((image, index) => (
                  <SwiperSlide key={`import-img${index}`} className="one-img">
                    <div key={index} className="image-button">
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Image ${index}`}
                      />
                      <button
                        type="button"
                        className="flex liner"
                        onClick={() => handleRemoveImage(index)}
                      >
                        <img src={trashIcon} alt="trash-icon" />
                      </button>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="import-image flex row liner">
              <input
                className="input-file-photo"
                type="file"
                multiple
                onChange={handleImageChange}
                accept=".jpg, .png"
              />
              <img src={arrowTil} alt="arrow-t" />
            </div>
            {selectedImages.length < 5 && (
              <div
                className="flex liner desktop-validate-image"
                style={{ width: "100%" }}
              >
                <span style={{ textAlign: "center", width: "100%" }}>
                  5 images minimum afin de valider le formulaire
                </span>
              </div>
            )}

            <div
              className="flex liner mobile-validate-image"
              style={{ width: "100%" }}
            >
              <span style={{ textAlign: "center", width: "100%" }}>
                5 images au minimum
              </span>
            </div>
            <div className="flex row btn-actions">
              <button
                className="hover-btn return-btn"
                onClick={goOnInputPage}
                type="button"
              >
                Retour
              </button>
              <button
                className="confirm-btn hover-btn"
                disabled={!(canSend && !addCarsLoading)}
                onClick={goToOthersCarPage}
                type="button"
              >
                Suivant
              </button>
            </div>
          </form>
        </div>
        <div
          className={
            (page === 3
              ? "inpage-import"
              : page >= 4
              ? "noinpage-car"
              : "noinpage-import") + " quick-sell import-quick-sell flex colm"
          }
        >
          <div className="label-title-sale flex colm">
            <h3>Informations Complementaires</h3>
          </div>
          <form className="form-send flex row addgap8 complementary-form">
            <h4>Extérieur et Chassis</h4>
            {quickComplementaryExterieur.map((item, count) => (
              <div
                onClick={(e) => applyOnExterieurTab(e, item.id)}
                key={`${count + "exte" + item.id}`}
                className="flex row liner btw addgap8 | quick-selector-complementary"
              >
                <SelectComplementary
                  text={item.text}
                  mineId={item.id}
                  value={item.active}
                  allValue={quickComplementaryExterieur}
                  setValueTab={setQuickComplementaryExterieur}
                />
              </div>
            ))}
            <div className="flex row btn-actions">
              <button
                className="hover-btn return-btn"
                onClick={goBackPage}
                type="button"
              >
                Retour
              </button>
              <button
                className="confirm-btn hover-btn"
                onClick={goNextPage}
                type="button"
              >
                Suivant
              </button>
            </div>
          </form>
        </div>
        <div
          className={
            (page === 4
              ? "inpage-import"
              : page >= 5
              ? "noinpage-car"
              : "noinpage-import") + " quick-sell import-quick-sell flex colm"
          }
        >
          <div className="label-title-sale flex colm">
            <h3>Informations Complementaires</h3>
          </div>
          <form className="form-send flex row addgap8 complementary-form">
            <h4>Intérieur</h4>
            {quickComplementaryInterieur.map((item, count) => (
              <div
                onClick={(e) => applyOnInterieurTab(e, item.id)}
                key={`${count + "exte" + item.id}`}
                className="flex row liner btw addgap8 | quick-selector-complementary"
              >
                <SelectComplementary
                  text={item.text}
                  mineId={item.id}
                  value={item.active}
                  allValue={quickComplementaryInterieur}
                  setValueTab={setQuickComplementaryInterieur}
                />
              </div>
            ))}
            <div className="flex row btn-actions">
              <button
                className="hover-btn return-btn"
                onClick={goBackPage}
                type="button"
              >
                Retour
              </button>
              <button
                className="confirm-btn hover-btn"
                onClick={goNextPage}
                type="button"
              >
                Suivant
              </button>
            </div>
          </form>
        </div>
        <div
          className={
            (page === 5
              ? "inpage-import"
              : page >= 6
              ? "noinpage-car"
              : "noinpage-import") + " quick-sell import-quick-sell flex colm"
          }
        >
          <div className="label-title-sale flex colm">
            <h3>Informations Complementaires</h3>
          </div>
          <form className="form-send flex row addgap8 complementary-form">
            <h4>Sécurité </h4>
            {quickComplementarySecurity.map((item, count) => (
              <div
                onClick={(e) => applyOnSecurityTab(e, item.id)}
                key={`${count + "exte" + item.id}`}
                className="flex row liner btw addgap8 | quick-selector-complementary"
              >
                <SelectComplementary
                  text={item.text}
                  mineId={item.id}
                  value={item.active}
                  allValue={quickComplementarySecurity}
                  setValueTab={setQuickComplementarySecurity}
                />
              </div>
            ))}
            <h4>Autre</h4>
            {quickComplementaryOthers.map((item, count) => (
              <div
                onClick={(e) => applyOnOthersTab(e, item.id)}
                key={`${count + "exte" + item.id}`}
                className="flex row liner btw addgap8 | quick-selector-complementary"
              >
                <SelectComplementary
                  text={item.text}
                  mineId={item.id}
                  value={item.active}
                  allValue={quickComplementaryOthers}
                  setValueTab={setQuickComplementaryOthers}
                />
              </div>
            ))}
            <div className="flex row btn-actions">
              <button
                className="hover-btn return-btn"
                onClick={goBackPage}
                type="button"
              >
                Retour
              </button>
              <button
                className="confirm-btn hover-btn"
                disabled={!(canSend && !addCarsLoading)}
                onClick={sendVehicule}
                type="button"
              >
                Envoyer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
