import axios from "axios";
import env from "react-dotenv";
import { get, post, patch } from "../Shared/Services/api.service";

import React, { useEffect, useRef } from "react";
import "../Shared/Styles/login.css";
import { Header } from "../Shared/Components/header.component";
import AutoPlusLogo from "../Shared/Images/imports/logo-primary-auto-plus-hide.svg";
import carImage from "../Shared/Images/pngwing11.png";
import vectorImage from "../Shared/Images/vectorConnexion.svg";
import smsIcon from "../Shared/Images/sms.svg";
import lockIcon from "../Shared/Images/lock.svg";

import { useState } from "react";
import { Link } from "react-router-dom";
import { FooterComposant } from "../Shared/Components/footer.composant";
import NotificationService from "../Shared/Services/notification.service";
import {
  redirectToHome,
  redirectToURL,
} from "../Shared/Services/redirect.service";
import { checkUserData } from "../Shared/Services/user.check.service";

export const PasswordForget = () => {
  async function checkUserStatus() {
    const valueUserData = await checkUserData();
    if (valueUserData !== null) {
      redirectToHome();
    }
  }
  useEffect(() => {
    checkUserStatus();
    return () => {};
  }, []);
  
  const [mailInput, setMailInput] = useState("");
  const [codeInput, setCodeInput] = useState("");
  const [canLogin, setCanLogin] = useState(false);
  const [canInputPass, setCanInputPass] = useState(false);
  const [loadingResetPass, setLoadingResetPass] = useState(false);
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);
  const [resendPass, setResendPass] = useState(false);

  useEffect(() => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (mailInput.match(mailformat)) {
      setCanLogin(true);
    } else {
      setCanLogin(false);
    }
  }, [mailInput]);

  const resetPassFunction = () => {
    let data = {
      email: mailInput,
    };
    setLoadingResetPass(true);
    post("/users/password_forget", data)
      .then((response) => {
        console.log(response.data);
        setSeconds(59);
        setMinutes(2);
        setCanInputPass(true);
      })
      .catch((error) => {
        console.error(error);
        NotificationService.addToQueue(
          "Ce email n'exite pas dans notre base de donnée"
        );
      })
      .finally(setLoadingResetPass(false));
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes === 0) {
          clearInterval(interval);
          setResendPass(true);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  const resendPassCode = () => {
    setResendPass(false);
    setMinutes(3);
    resetPassFunction();
  };

  const checkAndConfirmCode = () => {
    let data = {
      email: mailInput,
      code: codeInput,
    };
    setLoadingResetPass(true);
    post("/users/password_confirm", data)
      .then((response) => {
        console.log(response.data);
        redirectToURL("/new-password/" + response.data.id);
      })
      .catch((error) => {
        setLoadingResetPass(false);
        console.error(error);
        NotificationService.addToQueue("Ce code est erronée");
      });
  };
  return (
    <div className="login-connect-page connexion-page">
      <Header NoBackground={false} />
      <div className="login-page flex center-margin max-width">
        <img className="back-car" src={carImage} alt="cars-image" />
        <img className="back-vector" src={vectorImage} alt="vector-image" />
        <div className="contains flex colm">
          {canInputPass === true ? (
            <>
              <div className="top-img-label flex colm">
                <img src={AutoPlusLogo} alt="autoPlusLogo" />
                <h4>Mot de passe oublié ?</h4>
                <span>
                  Un e-mail contenant un code à 5 chiffres à été envoyer sur
                  l'adresse email saisi précédement{" "}
                  <span className="bolder">{mailInput}</span> Saisissez le pour
                  pour changer votre mot de passe.
                </span>
              </div>
              <form className="input-contains code-pass flex colm">
                <div style={{ display: "none" }} className="input-me-login">
                  <img src={lockIcon} alt="lock-icon" />
                  <input
                    onChange={(e) => setCodeInput(e.target.value)}
                    type="text"
                    placeholder="Entrez le code réçu par mail"
                  />
                </div>
                <div className="input-me-login">
                  <img src={lockIcon} alt="lock-icon" />
                  <input
                    onChange={(e) => setCodeInput(e.target.value)}
                    type="text"
                    placeholder="Entrez le code réçu par mail"
                  />
                </div>
                {resendPass === false ? (
                  <button disabled={true} className="hover-btn action-btn">
                    Renvoyer le code dans{" "}
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </button>
                ) : (
                  <button
                    disabled={false}
                    type="button"
                    onClick={resendPassCode}
                    className="hover-btn action-btn"
                  >
                    Renvoyer le code
                  </button>
                )}
              </form>
              <div className="flex colm liner addgap8 btn-click">
                <button
                  type="button"
                  disabled={
                    !(codeInput.length >= 4 && loadingResetPass === false)
                  }
                  onClick={checkAndConfirmCode}
                  className="hover-btn action-btn"
                >
                  Confirmer
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="top-img-label flex colm">
                <img src={AutoPlusLogo} alt="autoPlusLogo" />
                <h4>Mot de passe oublié ?</h4>
                <span>
                  Veuillez saisir l’adresse e-mail utilisée lors de votre
                  inscription. Vous recevrez un e-mail concernant les
                  instructions à suivre pour changer votre mot de passe.
                </span>
              </div>
              <form className="input-contains flex colm">
                <div className="input-me-login">
                  <img src={smsIcon} alt="sms-icon" />
                  <input
                    onChange={(e) => setMailInput(e.target.value)}
                    type="email"
                    placeholder="Adresse mail"
                  />
                </div>
              </form>
              <button
                disabled={!(canLogin && !loadingResetPass)}
                onClick={resetPassFunction}
                className="hover-btn action-btn"
              >
                Envoyer un mail
              </button>
            </>
          )}

          <p className="switch-over">
            Vous vous souvenez du mot de passe ?{" "}
            <Link className="hover-btn" to={"/connexion"}>
              Connexion.
            </Link>
          </p>
        </div>
      </div>
      <FooterComposant />
    </div>
  );
};
