import { useEffect, useRef, useState } from "react";
import settingIconFilter from "../Images/imports/settingIcon.svg";
import arrowsIconFilter from "../Images/arrow-down.svg";
import EssenceIcon from "../Images/EssenceIcon.svg";
import TransmissionIcon from "../Images/TransmissionIcon.svg";
import KilometerIcon from "../Images/kilometerCarac.svg";
import fillArrow from "../Images/imports/checkbox-fill-icon.svg";
import "../Styles/filterPriceCaracteristiques.css";

export const CaracteristiquesFilter = ({
  transmission,
  oilType,
  killometer,
  setCurrentKilometerLimitV,
  setCurrentOilTypeV,
  setCurrentTransmissionV,
}) => {
  const [currentTransmission, setCurrentTransmission] = useState([]);
  const [isOpenTransmission, setOpenTransmission] = useState(false);

  const [currentOilType, setCurrentOilType] = useState([]);
  const [isOpenOilType, setOpenOilType] = useState(false);

  const [currentKillometer, setCurrentKillometer] = useState([]);
  const [isOpenKillometer, setOpenKillometer] = useState(false);

  const [isOpen, setOpen] = useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setOpenTransmission(false);
        setOpenOilType(false);
        setOpenKillometer(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  useEffect(() => {
    if (
      currentTransmission.length > 1 &&
      currentOilType.length > 1 &&
      currentKillometer.length > 1
    )
      return;
    let currTab = [];
    transmission.map((transmission) => {
      currTab.push({ ...transmission });
    });
    if (currTab.length != 0) currTab[0].active = true;
    setCurrentTransmission(currTab);
    currTab = [];
    oilType.map((oilType) => {
      currTab.push({ ...oilType });
    });
    if (currTab.length != 0) currTab[0].active = true;
    setCurrentOilType(currTab);
    currTab = [];
    killometer.map((killometer) => {
      currTab.push({ ...killometer, active: false });
    });
    if (currTab.length != 0) currTab[0].active = true;
    setCurrentKillometer(currTab);
  }, [oilType, transmission, killometer]);

  const handleOpen = () => {
    setOpen(!isOpen);
  };
  const handleOpenTransmission = () => {
    setOpenTransmission(!isOpenTransmission);
    setOpenOilType(false);
    setOpenKillometer(false);
  };
  const handleOpenOilType = () => {
    setOpenOilType(!isOpenOilType);
    setOpenTransmission(false);
    setOpenKillometer(false);
  };
  const handleOpenKillometer = () => {
    setOpenKillometer(!isOpenKillometer);
    setOpenOilType(false);
    setOpenTransmission(false);
  };

  const handleSelectFilterTransmission = (nameTransmission) => {
    let newM = [];
    currentTransmission.map((transmission) => {
      if (transmission.value === nameTransmission) {
        transmission.active = !transmission.active;
      } else {
        transmission.active = false;
      }
      newM.push(transmission);
    });
    let foundTrue = false;

    newM.map((transmission) => {
      if (transmission.active === true) {
        setCurrentTransmissionV(transmission.value);
        foundTrue = true;
      }
    });

    if (foundTrue === false) {
      setCurrentTransmissionV("");
      newM[0].active = true;
    }
    setCurrentTransmission(newM);
  };

  const handleSelectFilterOilType = (nameOilType) => {
    let newM = [];
    currentOilType.map((OilType) => {
      if (OilType.value === nameOilType) {
        OilType.active = !OilType.active;
      } else {
        OilType.active = false;
      }
      newM.push(OilType);
    });
    let foundTrue = false;

    newM.map((OilType) => {
      if (OilType.active === true) {
        setCurrentOilTypeV(OilType.value);
        foundTrue = true;
      }
    });

    if (foundTrue === false) {
      setCurrentOilTypeV("");
      newM[0].active = true;
    }
    setCurrentOilType(newM);
  };

  const handleSelectFilterKillometer = (nameKillometer) => {
    let newM = [];
    currentKillometer.map((Killometer) => {
      if (Killometer.value === nameKillometer) {
        Killometer.active = !Killometer.active;
      } else {
        Killometer.active = false;
      }
      newM.push(Killometer);
    });
    let foundTrue = false;

    newM.map((Killometer) => {
      if (Killometer.active === true) {
        setCurrentKilometerLimitV(Killometer.value);
        foundTrue = true;
      }
    });

    if (foundTrue === false) {
      setCurrentKilometerLimitV("");
      newM[0].active = true;
    }
    setCurrentKillometer(newM);
  };

  return (
    <div
      ref={divRef}
      className={
        isOpen === true
          ? isOpenOilType
            ? "btn-filter year-filter flex colm setting-filter setting-filter-is-open extend-filter-1"
            : "btn-filter year-filter flex colm setting-filter setting-filter-is-open"
          : "btn-filter year-filter setting-filter flex colm"
      }
    >
      <button onClick={handleOpen} className="btn-select flex row btw">
        <div className="left flex row">
          <img src={settingIconFilter} />
          <span className="open-me-filter">Caractéristiques</span>
        </div>
        <img
          className={
            isOpen === true
              ? "img-arrow-turn is-open-filter-cars"
              : "img-arrow-turn"
          }
          src={arrowsIconFilter}
        />
      </button>
      <div className="input-setting flex colm">
        <button
          onClick={handleOpenTransmission}
          className={
            isOpenTransmission
              ? "transmission-filter is-open-filter flex row btw"
              : "transmission-filter flex row btw"
          }
        >
          <div className="left-filter-name flex row">
            <img src={TransmissionIcon} alt="transmission-icon" />
            {currentTransmission.map((transmission) => {
              if (transmission.active === true) {
                return <span>{transmission.value}</span>;
              }
            })}
          </div>
          <img
            className={
              isOpenTransmission === true
                ? "img-arrow-turn is-open-filter-cars"
                : "img-arrow-turn"
            }
            src={arrowsIconFilter}
          />
          {isOpenTransmission ? (
            <div className="selection-input-filter">
              {currentTransmission.map((transmission) => {
                return (
                  <button
                    className="flex"
                    onClick={() =>
                      handleSelectFilterTransmission(transmission.value)
                    }
                  >
                    <div
                      className={
                        transmission.active ? "flex active-select" : "flex"
                      }
                    >
                      <img src={fillArrow} alt="arrow-fill" />
                    </div>
                    <span>{transmission.value}</span>
                  </button>
                );
              })}
            </div>
          ) : null}
        </button>
        <button
          onClick={handleOpenOilType}
          className={
            isOpenOilType
              ? "oiltype-filter is-open-filter flex row btw"
              : "oiltype-filter flex row btw"
          }
        >
          <div className="left-filter-name flex row">
            <img src={EssenceIcon} alt="essence-icon" />
            {currentOilType.map((oilType) => {
              if (oilType.active === true) {
                return <span>{oilType.value}</span>;
              }
            })}
          </div>
          <img
            className={
              isOpenOilType === true
                ? "img-arrow-turn is-open-filter-cars"
                : "img-arrow-turn"
            }
            src={arrowsIconFilter}
          />
          {isOpenOilType ? (
            <div className="selection-input-filter">
              {currentOilType.map((oilType) => {
                return (
                  <button
                    className="flex"
                    onClick={() => handleSelectFilterOilType(oilType.value)}
                  >
                    <div
                      className={oilType.active ? "flex active-select" : "flex"}
                    >
                      <img src={fillArrow} alt="arrow-fill" />
                    </div>
                    <span>{oilType.value}</span>
                  </button>
                );
              })}
            </div>
          ) : null}
        </button>
        <button
          onClick={handleOpenKillometer}
          className={
            isOpenKillometer
              ? "killometer-filter is-open-filter flex row btw"
              : "killometer-filter flex row btw"
          }
        >
          <div className="left-filter-name flex row">
            <img
              style={{ width: "22px", height: "22px" }}
              src={KilometerIcon}
              alt="killometer-icon"
            />
            {currentKillometer.map((killometer, count) => {
              if (killometer.active === true) {
                return (
                  <span>
                    {count > 0
                      ? "<= " + killometer.value + " km"
                      : killometer.value}
                  </span>
                );
              }
            })}
          </div>
          <img
            className={
              isOpenKillometer === true
                ? "img-arrow-turn is-open-filter-cars"
                : "img-arrow-turn"
            }
            src={arrowsIconFilter}
          />
          {isOpenKillometer ? (
            <div className="selection-input-filter">
              {currentKillometer.map((killometer, count) => {
                return (
                  <button
                    className="flex"
                    onClick={() =>
                      handleSelectFilterKillometer(killometer.value)
                    }
                  >
                    <div
                      className={
                        killometer.active ? "flex active-select" : "flex"
                      }
                    >
                      <img src={fillArrow} alt="arrow-fill" />
                    </div>
                    <span>
                      {count > 0
                        ? "<= " + killometer.value + " km"
                        : killometer.value}
                    </span>
                  </button>
                );
              })}
            </div>
          ) : null}
        </button>
      </div>
    </div>
  );
};
