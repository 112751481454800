import React, { useEffect, useState } from "react";
import "../Shared/Styles/vendre.css";
import { Header } from "../Shared/Components/header.component";
import { QuickSale } from "../Shared/Components/section-quicksale";
import { SectionHeader5 } from "../Shared/Components/section-header5.component";
import { StepToSellCard } from "../Shared/Components/steptosell-card";
import { SectionHeader1 } from "../Shared/Components/section-header1.component";
import { CarCarousel } from "../Shared/Components/car-carousel.component";
import { Footer } from "../Shared/Components/footer.component";
import VehiculePicture from "../Shared/Images/VehiculePicture.svg";
import { FooterComposant } from "../Shared/Components/footer.composant";
import { InfoQuickSell } from "../Shared/Components/info-quick-sell";
import { CardBuySection } from "../Shared/Components/card-how-buy";
import { InfoQuickSellNoProfil } from "../Shared/Components/info-quick-sell-no-profil";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../feature/loading.state";
import { returnStaticInfo } from "../Shared/Services/simplerequest.service";
import NotificationService from "../Shared/Services/notification.service";
import { decryptData } from "../Shared/Services/encryption.service";
import { checkUserData } from "../Shared/Services/user.check.service";
import { CardSellerSection } from "../Shared/Components/card-how-sell";
import { redirectToURL } from "../Shared/Services/redirect.service";

export const Vendre = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoadingState(true));
    window.scrollTo(0, 0);
  }, []);
  const [loadEnd, setLoadEnd] = useState(false);

  const [dataUser, setDataUser] = useState();
  const [isLogin, setIsLogin] = useState(false);
  const [profilVendeurCompleted, setProfilVendeurCompleted] = useState(false);

  const [sellerCars, setSellerCars] = useState([]);
  const [lastAddCars, setLastAddCars] = useState([]);

  const fetchUserData = async () => {
    const valueUserData = await checkUserData();
    if (valueUserData !== null) {
      setIsLogin(true);
      let profile = valueUserData.profile;
      if (profile.toLowerCase() === "seller") {
        setProfilVendeurCompleted(true);
      }
      setDataUser(valueUserData);
    } else {
      setDataUser(null);
    }
  };

  const fetchCarData = async () => {
    if (dataUser !== undefined && dataUser !== null) {
      const selCars = await Promise.resolve(
        returnStaticInfo(`/users/cars/${dataUser.id}`)
      );
      if (selCars === null) {
        setSellerCars([]);
      } else setSellerCars(selCars);
    }

    const lastACars = await Promise.resolve(returnStaticInfo("/cars/latest"));
    setLastAddCars(lastACars);
    setLoadEnd(true);
  };

  const checkLoadEnd = () => {
    if (lastAddCars.length === 0) {
      NotificationService.addToQueue("Erreur lors du chargement des véhicules");
    } else {
      dispatch(setLoadingState(false));
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (dataUser === undefined) return;
    fetchCarData();
  }, [dataUser]);

  useEffect(() => {
    if (loadEnd === false) return;
    checkLoadEnd();
  }, [loadEnd]);

  const [openModalBuyer, setOpenModalBuyer] = useState(false);

  const openTheModal = (e, val) => {
    setOpenModalBuyer(val);
    e.stopPropagation();
  };

  return (
    <div className="sell-page">
      <Header NoBackground={false} />
      <div className="max-width center-margin">
        {isLogin === true && profilVendeurCompleted === true ? (
          <InfoQuickSell />
        ) : (
          <InfoQuickSellNoProfil setOpenModalBuyer={setOpenModalBuyer} />
        )}
        <CardSellerSection />
        <div className="my-cars flex colm">
          {isLogin === true &&
          profilVendeurCompleted === true &&
          sellerCars.length > 0 ? (
            <div className="container-section flex colm">
              <SectionHeader1
                title={"Mes vehicules"}
                subtitle={"La liste de vos véhicules en vente"}
              />
              <div className="cliped-contains">
                <CarCarousel tabCar={sellerCars} />
              </div>
            </div>
          ) : (
            <div className="container-section flex colm">
              <SectionHeader1
                title={"Derniers ajouts"}
                subtitle={
                  "Retrouvez les derniers véhicules ajouté à notre catalogue"
                }
              />
              <div className="cliped-contains">
                <CarCarousel tabCar={lastAddCars} />
              </div>
            </div>
          )}
        </div>
      </div>
      {openModalBuyer && (
        <div className="modal-how-buy flex colm">
          <div
            onClick={(e) => openTheModal(e, false)}
            className="background-patch flex"
          ></div>
          <div className="div-explain flex liner colm">
            <div className="title-header flex colm">
              <h5>Comment être vendeur</h5>
              <span>
                Dévenez un vendeur sur <span className="bolder">AUTOPLUS</span>{" "}
                en 3 étapes
              </span>
            </div>

            <p>
              <span className="bolder">etape 1 :</span> Créer un compte{" "}
              <span className="bolder">AUTOPLUS</span> à l'aide du formulaire ou
              en vous connectant à l'aide de votre compte Google ou Facebook.
            </p>
            <p>
              <span className="bolder">etape 2 :</span> Rendez vous sur la page
              mon profil et renseigner tout les champs manquants, surtout
              n'oubliez pas d'importer la photo de vôtre Carte Nationale
              d'Identité {"("}
              <span className="bolder">CNI</span>
              {")"}.
            </p>
            <p>
              <span className="bolder">etape 3 :</span> Cliquer sur le bouton
              "Je souhaite être vendeur",{" "}
              <span className="bolder">AUTOPLUS</span> analysera les données que
              vous avez fournis et validera vôtre demande si tout est ok{" "}
              <span className="bolder">
                (vous récéverez un email confirmant vôtre statut vendeur
              </span>
              ).
            </p>
            <div className="btn-actions-modal flex row addgap8">
              <button
                type="button"
                onClick={(e) => openTheModal(e, false)}
                className="hover-btn"
              >
                Retour
              </button>
              {dataUser ? (
                <button
                  type="button"
                  onClick={(e) => redirectToURL("/profilpage/1")}
                  className="hover-btn"
                >
                  Page Profil
                </button>
              ) : (
                <button
                  type="button"
                  onClick={(e) => redirectToURL("/connexion")}
                  className="hover-btn"
                >
                  Connexion
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <FooterComposant />
    </div>
  );
};
