import React, { useEffect, useRef, useState } from "react";
import "../Styles/StoreFilter-card.css";
import { CarCard } from "./car-card.component";
import NotificationService from "../Services/notification.service";
import { get, post, remove } from "../Services/api.service";
import { useDispatch, useSelector } from "react-redux";
import { decryptData } from "../Services/encryption.service";
import { setDataFavoris } from "../../feature/autoplus.data";
import { checkUserData } from "../Services/user.check.service";

export const StoreFilterCard = ({
  setIsVisibleAllCard,
  filterParams,
  setCurrVehicules,
  setFirstBlock,
}) => {
  const targetRef = useRef(null);
  const targetLoadingNewData = useRef(null);

  const [tabVehicules, setTabVehicules] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [tablePage, setTableMaxPage] = useState([]);
  const [queryCode, setQueryCode] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [endResults, setEndResults] = useState(false);
  const [isObserving, setIsObserving] = useState(false);
  const [firstLoad, setfirstLoad] = useState(false);

  const fetchVehicules = async () => {
    return new Promise((resolve, reject) => {
      try {
        get("/cars/store", { ...queryCode, page: currentPage })
          .then((response) => {
            resolve(response.data);
            setFirstBlock(false);
          })
          .catch((error) => {
            setEndResults(true);
            NotificationService.addToQueue(
              "Une erreur est survenue, veuillez réessayer plus tard"
            );
            setFirstBlock(false);
            return error;
          });
      } catch (error) {
        console.error(error);
        resolve(null);
      }
    });
  };

  const addOnPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    setCurrVehicules(tabVehicules); // eslint-disable-next-line
  }, [tabVehicules]);
  const fetchData = async () => {
    setIsLoading(true);
    setEndResults(false);

    const allVehiculesResponse = await fetchVehicules();
    // if (currentPage != 1 && currentPage != maxPage) {

    // } else {
    //   window.scrollTo({
    //     top: window.innerWidth > 750 ? 360 : 0,
    //   });
    // }
    if (allVehiculesResponse !== null) {
      let newTab = [];
      newTab = allVehiculesResponse.results;
      // addOnPage();
      setMaxPage(allVehiculesResponse.maxPage);
      setTableMaxPage(new Array(allVehiculesResponse.maxPage).fill(null));
      let switchVehicules = tabVehicules;
      newTab.map((item, count) => {
        if (switchVehicules[count]) {
          switchVehicules[count] = item;
        } else {
          switchVehicules.push(item);
        }
      });
      switchVehicules = switchVehicules.slice(0, allVehiculesResponse.count);
      setTabVehicules(switchVehicules);
      setTimeout(() => {
        window.scrollTo({
          top: window.innerWidth > 750 ? 360 : 0,
          behavior: "smooth",
        });
      }, 300);
      if (allVehiculesResponse.results.length === 0) {
        setEndResults(true);
      }
      if (allVehiculesResponse.currentPage === allVehiculesResponse.maxPage) {
        setEndResults(true);
      }
    }

    // setfirstLoad(true);
    setIsLoading(false);
  };

  const checkqueryCode = () => {
    const code = Object.fromEntries(
      Object.entries(filterParams).filter(
        ([_key, value]) => value !== null && value !== undefined
      )
    );
    if (JSON.stringify(queryCode) !== JSON.stringify(code)) {
      setCurrentPage(1);
      setMaxPage(0);
      setEndResults(false);
      setTableMaxPage([]);
      setTabVehicules([]);
      setIsObserving(false);
      setQueryCode(() => code);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisibleAllCard(true);
          if (!isObserving) {
            setIsObserving(true);
          }
        } else {
          setIsVisibleAllCard(false);
        }
      });
    });

    if (targetRef.current && !isObserving) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
    // eslint-disable-next-line
  }, [isObserving, setIsVisibleAllCard]);

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  useEffect(() => {
    if (isEmptyObject(queryCode)) return;
    fetchData();
    // eslint-disable-next-line
  }, [queryCode]);

  const [timerId, setTimerId] = useState(null);
  useEffect(() => {
    if (filterParams === undefined || filterParams === null) return;
    // if (firstLoad === false) return;

    if (timerId) {
      clearTimeout(timerId);
    }

    const newTimerId = setTimeout(() => {
      window.scrollTo({
        top: window.innerWidth > 750 ? 360 : 0,
        behavior: "smooth",
      });
      checkqueryCode();
    }, 500);

    setTimerId(newTimerId);

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [filterParams]);

  useEffect(() => {
    const newTimerId = setTimeout(() => {
      fetchData();
    }, 1000);
    setTimerId(newTimerId);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (
            endResults === false &&
            tabVehicules.length > 0 &&
            isLoading === false
          ) {
            // fetchData();
          }
        }
      });
    });

    if (targetLoadingNewData.current) {
      observer.observe(targetLoadingNewData.current);
    }

    return () => {
      if (targetLoadingNewData.current) {
        observer.unobserve(targetLoadingNewData.current);
      }
    }; // eslint-disable-next-line
  }, [tabVehicules, isLoading]);

  const [filterHeight, setFilterHeight] = useState(0);

  useEffect(() => {
    const updateFilterHeight = () => {
      const element = document.getElementById("filterPanel");
      if (element) {
        if (element.clientHeight !== filterHeight)
          setFilterHeight(element.clientHeight);
      }
    };
    updateFilterHeight();
    const intervalId = setInterval(updateFilterHeight, 100);

    if (tabVehicules.length >= 4) {
      clearInterval(intervalId);
    }
    return () => {
      clearInterval(intervalId);
    }; // eslint-disable-next-line
  }, [tabVehicules]);

  const dataFavoris = useSelector((state) => state.dataConf.favoris);
  const dispatch = useDispatch();
  const [dataUser, setDataUser] = useState();
  const [currentFav, setCurrentFav] = useState([]);

  useEffect(() => {
    if (dataFavoris) {
      if (JSON.stringify(currentFav) !== JSON.stringify(dataFavoris)) {
        setCurrentFav(dataFavoris);
      }
    }
  }, [dataFavoris]);

  const fetchUserData = async () => {
    const valueUserData = await checkUserData();
    if (valueUserData !== null) {
      setDataUser(valueUserData);
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []);
  const updateFavoris = async (car) => {
    if (dataUser !== undefined || dataUser !== null) {
      let newTab = [];
      let suppressId = 0;
      try {
        currentFav.map((fav) => {
          if (fav.carId === car.id) {
            suppressId = fav.id;
          } else {
            newTab.push(fav);
          }
        });
        if (suppressId === 0) {
          const newFav = await post("/users/favs", {
            userId: dataUser.id,
            carId: car.id,
          });
          newTab.unshift(newFav.data);
          setCurrentFav(newTab);
          dispatch(setDataFavoris(newTab));
          return true;
        } else {
          const rest = await remove(`/users/favs/${suppressId}`);
          setCurrentFav(newTab);
          dispatch(setDataFavoris(newTab));
          return false;
        }
      } catch {
        NotificationService.addToQueue(
          "Vous devez etre connecté pour ajouter un vehicule en favoris"
        );
        return false;
      }
    } else {
      NotificationService.addToQueue(
        "Vous devez etre connecté pour ajouter un vehicule en favoris"
      );
      return false;
    }
  };
  const [pageClick, setPageClick] = useState(false);
  const goOnPage = async (page) => {
    setCurrentPage(page);
    setEndResults(false);
    setIsObserving(false);
    setPageClick(() => true);
    // Utilisation de setState avec une fonction de rappel
    // setTabVehicules([]);
  };

  useEffect(() => {
    if (isLoading === false && pageClick === true) {
      fetchData();
      setPageClick(false);
    }
  }, [pageClick]);

  const [pagination, setPagination] = useState([<></>]);

  useEffect(() => {
    const numbers = tablePage;
    let index =
      tablePage.length === currentPage ? currentPage - 1 : currentPage;

    if (index === 1 && tablePage.length > 2) {
      index = 2;
    }
    const elementsToShow = [];
    if (tablePage.length > 0) {
      elementsToShow.push(
        <>
          {tablePage.length > 1 && (
            <button
              onClick={() => goOnPage(currentPage - 1)}
              disabled={!(currentPage > 1)}
              className="hover-btn flex liner btn-next-prev"
            >
              <span>Précedent</span>
            </button>
          )}

          <button
            onClick={() => goOnPage(currentPage - 1)}
            disabled={!(currentPage > 1)}
            className="hover-btn flex liner btn-next-prev-mobile"
          >
            <span>Pre</span>
          </button>
          <button
            disabled={currentPage === 1}
            onClick={() => goOnPage(1)}
            className="hover-btn flex liner"
          >
            <span>{1}</span>
          </button>
        </>
      );
    }

    if (tablePage.length >= 4 && index >= 4) {
      elementsToShow.push(
        <button disabled className="hover-btn btn-disabled flex liner">
          <span>...</span>
        </button>
      );
    }
    for (let i = index - 1; i <= index + 1; i++) {
      if (i >= 0 && i < numbers.length && i > 1) {
        elementsToShow.push(
          <button
            disabled={i == currentPage}
            onClick={() => goOnPage(i)}
            className="hover-btn flex liner"
          >
            <span>{i}</span>
          </button>
        );
      }
    }
    if (tablePage.length >= 5 && index <= tablePage.length - 3) {
      elementsToShow.push(
        <button disabled className="hover-btn btn-disabled flex liner">
          <span>...</span>
        </button>
      );
    }
    if (tablePage.length > 1) {
      elementsToShow.push(
        <>
          <button
            disabled={tablePage.length == currentPage}
            onClick={() => goOnPage(tablePage.length)}
            className="hover-btn flex liner"
          >
            <span>{tablePage.length}</span>
          </button>
          <button
            onClick={() => goOnPage(currentPage + 1)}
            disabled={!(currentPage < tablePage.length)}
            className="hover-btn flex liner btn-next-prev"
          >
            <span>Suivant</span>
          </button>
          <button
            onClick={() => goOnPage(currentPage + 1)}
            disabled={!(currentPage < tablePage.length)}
            className="hover-btn flex liner btn-next-prev-mobile"
          >
            <span>Suiv</span>
          </button>
        </>
      );
    }
    setPagination(elementsToShow);
  }, [tabVehicules]);
  return (
    <div
      ref={targetRef}
      style={{
        minHeight: tabVehicules.length < 1 && filterHeight,
        paddingBottom:
          tabVehicules.length < 4 &&
          tabVehicules.length > 0 &&
          window.innerWidth > 650 &&
          150,
        transition: tabVehicules.length < 1 && "0.15s",
      }}
      className={
        tabVehicules.length === 0
          ? "store-card-no-one StoreFilter_AllCards"
          : tabVehicules.length <= 3
          ? "store-card-one-row StoreFilter_AllCards"
          : "StoreFilter_AllCards"
      }
    >
      {tabVehicules.map((car, count) => {
        return (
          <CarCard
            key={`cardbuy-${car.id}` + count}
            car={car}
            updateFavoris={updateFavoris}
            dataFav={currentFav}
          />
        );
      })}
      <div ref={targetLoadingNewData} className="loading-again flex colm liner">
        {endResults && (
          <div className="loading flex row liner addgap8">
            {tabVehicules.length > 0 ? (
              <span>Fin des résultats</span>
            ) : (
              <span>Aucun résultat</span>
            )}
          </div>
        )}
        <div
          style={{ pointerEvents: isLoading ? "none" : "all" }}
          className="pagination-buying flex row addgap8"
        >
          {pagination}
        </div>

        {!endResults && isLoading && (
          <div className="loading flex row liner addgap8">
            <div></div>
            <span>Chargement en cours</span>
          </div>
        )}
      </div>
    </div>
  );
};
