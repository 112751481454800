import React, { useEffect, useRef, useState } from "react";
import "../Shared/Styles/vehicule-purchase.css";
import { Header } from "../Shared/Components/header.component";
import { CarEncardBuy } from "../Shared/Components/car-encard-buy.component";
import arrowBack from "../Shared/Images/arrow-left.svg";
import arrowGo from "../Shared/Images/arrow-down.svg";
import closeIcon from "../Shared/Images/close-icon.svg";
import moneyIcon from "../Shared/Images/moneys.svg";
import errorIcon from "../Shared/Images/error.svg";
import clockIcon from "../Shared/Images/clock1.svg";
import clockIconW from "../Shared/Images/clockwhite.svg";
import imgVehicule from "../Shared/Images/VehiculePicture.svg";
import { FooterComposant } from "../Shared/Components/footer.composant";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import moment from "moment";
import "moment/locale/fr";
import { useNavigate, useParams } from "react-router-dom";
import { get, post, patch } from "../Shared/Services/api.service";
import { capitalizeFirstLetters } from "../Shared/Services/letters.manage";
import NotificationService from "../Shared/Services/notification.service";
import env from "react-dotenv";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../feature/loading.state";
import {
  redirectToHome,
  redirectToURL,
} from "../Shared/Services/redirect.service";
import { decryptData } from "../Shared/Services/encryption.service";
import { checkUserData } from "../Shared/Services/user.check.service";

export const VehiclePurchase = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoadingState(true));
  }, []);
  const [currentCar] = useState(parseInt(useParams().id, 10) || 0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [carData, setCarData] = useState();
  const [noCar, setNoCar] = useState(false);
  const [loadCarData, setLoadCarData] = useState(false);
  const [loadEnd, setLoadEnd] = useState(false);

  const [modalMontantIsOpen, setModalMontantIsOpen] = useState(false);
  const [modalDateVisiteIsOpen, setModalDateVisiteIsOpen] = useState(false);
  const [value, onChange] = useState(new Date());
  const [validateDate, setValidateDate] = useState(new Date());
  const [hoursVisit, setHoursVisit] = useState(0);
  const [minuteVisit, setMinuteVisit] = useState(0);
  const [currentPriceInput, setCurrentPriceInput] = useState(0);
  const refInputPrice = useRef(null);

  const [currentIdHours, setCurrentIdHours] = useState(0);

  const [pageMobileOffer, setPageMobileOffer] = useState(1);

  function searchDataCar(id) {
    return new Promise((resolve, reject) => {
      get(`/cars/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          // NotificationService.addToQueue(
          //   "Aucun véhicule disponible à cette adresse"
          // );
          resolve(null);
        });
    });
  }

  async function searchCarData() {
    const carData = await searchDataCar(currentCar);
    if (carData === null) {
      setNoCar(true);
    } else {
      setCarData(carData);
    }
    setLoadEnd(true);
    setLoadCarData(true);
  }

  useEffect(() => {
    if (currentCar === 0) {
      setLoadCarData(true);
      setNoCar(true);
    } else {
      searchCarData();
    }
  }, []);

  useEffect(() => {});

  const applyDateMoment = (id, hours, minutes) => {
    setCurrentIdHours(id);
    setHoursVisit(hours);
    setMinuteVisit(minutes);
    const newDate = moment(value)
      .endOf("day")
      .set({ hour: hours, minute: minutes, second: 0 });
    setValidateDate(newDate.toDate());
  };

  const handleOpenMontantModal = (value) => {
    setCurrentPriceInput(0);
    setModalMontantIsOpen(value);
    setModalDateVisiteIsOpen(false);
  };

  const handleOpenDateVisiteModel = (value) => {
    setModalMontantIsOpen(false);
    setCurrentIdHours(0);
    setHoursVisit(0);
    setMinuteVisit(0);
    setPageMobileOffer(1);
    onChange(new Date());
    setModalDateVisiteIsOpen(value);
  };

  const [requestLaunchVisit, setRequestLaunchVisit] = useState(false);

  const confirmVisit = async () => {
    if (currentIdHours > 0) {
      setRequestLaunchVisit(true);
      let data = {
        date: validateDate,
        hour: `${hoursVisit}H${minuteVisit}`,
        carId: carData.id,
        userId: dataUser.id,
      };
      await post("/cars/program_visit", data)
        .then((response) => {
          NotificationService.addToQueue(
            `Une demande de visite à été faite à la date du ${moment(
              value
            ).format(
              "dddd, D MMMM YYYY"
            )} à ${hoursVisit}H${minuteVisit} pour la ${nameCar}.`
          );
          NotificationService.addToQueue(
            `Vous receverez une notification dans votre messagerie autoplus et par email.`
          );
          handleOpenDateVisiteModel(false);
        })
        .catch((error) => {
          console.log(error);
          NotificationService.addToQueue(
            `Une erreur est survenu lors de la demande de visite pour la ${nameCar}.`
          );
        });
      setRequestLaunchVisit(false);
    }
  };

  const nextPageConfirmMobile = () => {
    if (pageMobileOffer === 2) {
      confirmVisit();
      return;
    }
    setPageMobileOffer(pageMobileOffer + 1);
  };

  const [imgLink, setImgLink] = useState("");
  const [nameCar, setNameCar] = useState("");
  const [priceCar, setPriceCar] = useState("");
  const [kilometerCar, setKilometerCar] = useState("");

  useEffect(() => {
    if (carData === null || carData === undefined) return;
    const envLocal = env.API_PRIVATE_URL + "/assets/uploads/cars/";
    setImgLink(envLocal + carData.thumb);
    let carN = capitalizeFirstLetters(carData.brandName + " " + carData.model);
    setNameCar(carN);

    let price = 0;
    price = carData.price;
    price = price.toLocaleString();
    price = price.toString().replace(" ", ".");
    price = price.toString().replace(" ", ".");
    setPriceCar("₣ " + price);

    let kilometers = 0;
    kilometers = carData.kilometers;
    setKilometerCar(kilometers.toLocaleString() + " km");
  }, [carData]);

  const [dataUser, setDataUser] = useState();

  const fetchUserData = async () => {
    const valueUserData = await checkUserData();
    if (valueUserData !== null) {
      setDataUser(valueUserData);
    } else {
      setDataUser(null);
      redirectToURL("/connexion");
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const [requestLaunchEnchere, setRequestLaunchEnchere] = useState(false);

  const sendOffer = async () => {
    if (currentPriceInput > 1500000) {
      setRequestLaunchEnchere(true);
      let data = {
        userId: dataUser.id,
        carId: carData.id,
        amount: currentPriceInput,
      };
      await post("/cars/bid", data)
        .then((response) => {
          let price = "";
          price = currentPriceInput.toString();
          price = price.toString().toLocaleString();
          price = price.toString().replace(" ", ".");
          price = price.toString().replace(" ", ".");
          NotificationService.addToQueue(
            `Une demande d'achat à été envoyé au prix de ${price} fcfa pour la ${nameCar}.`
          );
          NotificationService.addToQueue(
            `Vous receverez une notification dans votre messagerie autoplus et par email.`
          );
          handleOpenMontantModal(false);
        })
        .catch((error) => {
          console.log(error);
          NotificationService.addToQueue(
            `Une erreur est survenu lors de la demande d'achat pour la ${nameCar}.`
          );
        });
      setRequestLaunchEnchere(false);
    }
  };
  const handleChangeMontant = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    setCurrentPriceInput(inputValue);
  };
  return (
    <div className="purchase-page">
      <Header NoBackground={false} />

      <div className="container-page flex colm max-width center-margin">
        {loadEnd === false && (
          <>
            <div className="label-title-subtitle-btn flex row btw">
              <div className="flex colm left-side">
                <h4>Vous êtes intéressé par un véhicule ?</h4>
                <p>Chargement des données de visite et d'enchère</p>
              </div>
              <button onClick={() => navigate(-1)} className="hover-btn">
                Annuler l'achat
              </button>
            </div>
            <div className="no-find-car loader-coming max-width center-margin flex">
              <div class="lds-ripple">
                <div></div>
                <div></div>
              </div>
            </div>
          </>
        )}
        <div className="label-title-subtitle-btn flex row btw">
          {loadEnd === true ? (
            carData !== undefined && carData !== null ? (
              <>
                <div className="flex colm">
                  <h4>Vous êtes intéressé par ce véhicule ?</h4>
                  <p>
                    Choisissez l’option qui vous convient et nous vous
                    contacterons dans les plus brefs délais
                  </p>
                </div>
                <button onClick={() => navigate(-1)} className="hover-btn">
                  Annuler l'achat
                </button>
              </>
            ) : (
              <div className="flex colm">
                <h4>Erreur d'achat ?</h4>
                <p>Un problème est survenu, veuillez reesayer</p>
              </div>
            )
          ) : null}
        </div>

        <div className="container-offer flex colm">
          {loadEnd === true ? (
            carData !== undefined && carData !== null ? (
              <div className="vehicule flex row btw">
                <div className="left flex row">
                  <img src={imgLink} alt="image-vehicule" />
                  <div className="encard-buying">
                    <CarEncardBuy
                      name={nameCar}
                      oil={carData.motorType.name}
                      transmission={carData.transmission.name}
                      kilometer={kilometerCar}
                    />
                  </div>
                </div>
                <h4>{priceCar}</h4>
              </div>
            ) : (
              <button
                style={{ pointerEvents: "none" }}
                className="flex row btw "
              >
                <div className="left flex colm">
                  <p style={{ textAlign: "center", width: "100%" }}>
                    Aucun véhicule n'est disponible à cette adresse ou celui ci
                    a déjà été achété.
                  </p>
                </div>
              </button>
            )
          ) : null}
          {loadEnd === true ? (
            carData !== undefined && carData !== null ? (
              <>
                <button
                  onClick={() => handleOpenMontantModal(true)}
                  className="flex row btw hover-btn"
                >
                  <div className="left flex colm">
                    <h5>Soumettre une offre</h5>
                    <p>
                      Cliquez ici pour soumettre une offre pour ce véhicule.
                      Nous réservons le véhicule pour vous pendant 24 heures.
                    </p>
                  </div>
                  <img src={arrowGo} alt="arrow-go" />
                </button>
                <button
                  onClick={() => handleOpenDateVisiteModel(true)}
                  className="flex row btw hover-btn"
                >
                  <div className="left flex colm">
                    <h5>Programmer une visite</h5>
                    <p>
                      Cliquez ici pour programmer une visite. Attention, la
                      voiture peut être vendue entre-temps. Faites vite!
                    </p>
                  </div>
                  <img src={arrowGo} alt="arrow-go" />
                </button>
                <button
                  onClick={() => navigate(-1)}
                  className="flex row hover-btn"
                >
                  <img src={arrowBack} alt="arrow-back" />
                  <p>Retour à la description du véhicule</p>
                </button>
              </>
            ) : (
              <button
                onClick={() => redirectToHome()}
                className="flex row hover-btn"
              >
                <img src={arrowBack} alt="arrow-back" />
                <p>Retour à l'ecran d'accueil</p>
              </button>
            )
          ) : null}
          <div className="contain-btn-mobile">
            <button onClick={() => navigate(-1)} className="hover-btn">
              Annuler l'achat
            </button>
          </div>
        </div>
      </div>
      <FooterComposant />
      {modalMontantIsOpen === true ? (
        <div className="modal-offer">
          <div
            onClick={() => {
              if (!requestLaunchEnchere) handleOpenMontantModal(false);
            }}
            className="background"
          ></div>
          <div className="contains-modal flex colm">
            <div className="title-subtitle flex colm">
              <div className="title-close flex row btw">
                <h5>Vous êtes intéressé par ce véhicule ?</h5>
                <button
                  disabled={requestLaunchEnchere}
                  onClick={() => handleOpenMontantModal(false)}
                  className="hover-btn"
                >
                  <img src={closeIcon} alt="close-icon" />
                </button>
              </div>
              <p>
                Saisissez le montant de votre offre pour ce véhicule. Nous vous
                contacterons sous peu.
              </p>
            </div>
            <div className="input-mount-warning flex colm">
              <div className="input-mount flex">
                <img src={moneyIcon} alt="monney-icon" />
                <input
                  ref={refInputPrice}
                  onChange={(e) => handleChangeMontant(e)}
                  disabled={requestLaunchEnchere}
                  type="text"
                  placeholder="Entrez un montant"
                  value={currentPriceInput}
                />
              </div>
              <div className="warning-info flex row">
                <img src={errorIcon} alt="error-icon" />
                <p>
                  Attention le montant dois être supérieur à 1.500.000 fcfa.
                </p>
              </div>
            </div>
            <button
              disabled={
                !(currentPriceInput > 1500000 && requestLaunchEnchere === false)
              }
              onClick={sendOffer}
              className="hover-btn"
            >
              Valider
            </button>
          </div>
        </div>
      ) : null}
      {modalDateVisiteIsOpen === true ? (
        <div
          className={
            requestLaunchVisit
              ? "no-pointer no-active-modal modal-offer modal-visit desktop-visit-modal"
              : " modal-offer modal-visit desktop-visit-modal"
          }
        >
          <div
            onClick={() => handleOpenDateVisiteModel(false)}
            className="background"
          ></div>
          <div className="contains-modal offer-modal flex colm">
            <div className="flex row btw title-calendar">
              <p>Date & heure</p>
              <button
                onClick={() => handleOpenDateVisiteModel(false)}
                className="hover-btn"
              >
                <img src={closeIcon} alt="close-icon" />
              </button>
            </div>
            <div className="flex row">
              <Calendar
                showNeighboringMonth={true}
                maxDetail={"month"}
                onChange={onChange}
                value={value}
              />
              <hr className="separator" />
              <div className="hours-validate flex colm btw">
                <div className="flex colm top-side">
                  <span>{moment(value).format("dddd, D MMMM YYYY")}</span>
                  <div className="select-clock flex colm">
                    <button
                      onClick={() => applyDateMoment(1, 10, 30)}
                      className={
                        currentIdHours === 1
                          ? "hover-btn active-btn flex row"
                          : "hover-btn flex row"
                      }
                    >
                      {currentIdHours === 1 ? (
                        <img src={clockIconW} alt="clock-icon" />
                      ) : (
                        <img src={clockIcon} alt="clock-icon" />
                      )}
                      <span>10:30</span>
                    </button>
                    <button
                      onClick={() => applyDateMoment(2, 11, 30)}
                      className={
                        currentIdHours === 2
                          ? "hover-btn active-btn flex row"
                          : "hover-btn flex row"
                      }
                    >
                      {currentIdHours === 2 ? (
                        <img src={clockIconW} alt="clock-icon" />
                      ) : (
                        <img src={clockIcon} alt="clock-icon" />
                      )}
                      <span>11:30</span>
                    </button>
                    <button
                      onClick={() => applyDateMoment(3, 13, 30)}
                      className={
                        currentIdHours === 3
                          ? "hover-btn active-btn flex row"
                          : "hover-btn flex row"
                      }
                    >
                      {currentIdHours === 3 ? (
                        <img src={clockIconW} alt="clock-icon" />
                      ) : (
                        <img src={clockIcon} alt="clock-icon" />
                      )}
                      <span>13:30</span>
                    </button>
                    <button
                      onClick={() => applyDateMoment(4, 15, 30)}
                      className={
                        currentIdHours === 4
                          ? "hover-btn active-btn flex row"
                          : "hover-btn flex row"
                      }
                    >
                      {currentIdHours === 4 ? (
                        <img src={clockIconW} alt="clock-icon" />
                      ) : (
                        <img src={clockIcon} alt="clock-icon" />
                      )}
                      <span>15:30</span>
                    </button>
                    <button
                      onClick={() => applyDateMoment(5, 16, 30)}
                      className={
                        currentIdHours === 5
                          ? "hover-btn active-btn flex row"
                          : "hover-btn flex row"
                      }
                    >
                      {currentIdHours === 5 ? (
                        <img src={clockIconW} alt="clock-icon" />
                      ) : (
                        <img src={clockIcon} alt="clock-icon" />
                      )}
                      <span>16:30</span>
                    </button>
                  </div>
                </div>
                <button
                  disabled={
                    currentIdHours > 0 && !requestLaunchVisit ? false : true
                  }
                  onClick={confirmVisit}
                  className="confirm-btn-modal-visit hover-btn"
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {modalDateVisiteIsOpen === true ? (
        <div
          className={
            requestLaunchVisit
              ? "no-pointer no-active-modal modal-offer modal-visit mobile-visit-modal"
              : "modal-offer modal-visit mobile-visit-modal"
          }
        >
          <div
            onClick={() => handleOpenDateVisiteModel(false)}
            className="background"
          ></div>
          <div className="contains-modal offer-modal flex colm">
            <div className="flex row btw title-calendar">
              <p>Date & heure</p>
              <button
                onClick={() => handleOpenDateVisiteModel(false)}
                className="hover-btn"
              >
                <img src={closeIcon} alt="close-icon" />
              </button>
            </div>
            <div
              className={
                pageMobileOffer === 1
                  ? "rowline-validate flex row"
                  : "rowline-validate page2-rowline-validate flex row"
              }
            >
              <Calendar
                showNeighboringMonth={true}
                maxDetail={"month"}
                onChange={onChange}
                value={value}
              />
              <div className="hours-validate flex colm btw">
                <div className="flex colm top-side">
                  <span>{moment(value).format("dddd, D MMMM YYYY")}</span>
                  <div className="select-clock flex colm">
                    <button
                      onClick={() => applyDateMoment(1, 10, 30)}
                      className={
                        currentIdHours === 1
                          ? "hover-btn active-btn flex row"
                          : "hover-btn flex row"
                      }
                    >
                      {currentIdHours === 1 ? (
                        <img src={clockIconW} alt="clock-icon" />
                      ) : (
                        <img src={clockIcon} alt="clock-icon" />
                      )}

                      <span>10:30</span>
                    </button>
                    <button
                      onClick={() => applyDateMoment(2, 11, 30)}
                      className={
                        currentIdHours === 2
                          ? "hover-btn active-btn flex row"
                          : "hover-btn flex row"
                      }
                    >
                      {currentIdHours === 2 ? (
                        <img src={clockIconW} alt="clock-icon" />
                      ) : (
                        <img src={clockIcon} alt="clock-icon" />
                      )}
                      <span>11:30</span>
                    </button>
                    <button
                      onClick={() => applyDateMoment(3, 13, 30)}
                      className={
                        currentIdHours === 3
                          ? "hover-btn active-btn flex row"
                          : "hover-btn flex row"
                      }
                    >
                      {currentIdHours === 3 ? (
                        <img src={clockIconW} alt="clock-icon" />
                      ) : (
                        <img src={clockIcon} alt="clock-icon" />
                      )}
                      <span>13:30</span>
                    </button>
                    <button
                      onClick={() => applyDateMoment(4, 15, 30)}
                      className={
                        currentIdHours === 4
                          ? "hover-btn active-btn flex row"
                          : "hover-btn flex row"
                      }
                    >
                      {currentIdHours === 4 ? (
                        <img src={clockIconW} alt="clock-icon" />
                      ) : (
                        <img src={clockIcon} alt="clock-icon" />
                      )}
                      <span>15:30</span>
                    </button>
                    <button
                      onClick={() => applyDateMoment(5, 16, 30)}
                      className={
                        currentIdHours === 5
                          ? "hover-btn active-btn flex row"
                          : "hover-btn flex row"
                      }
                    >
                      {currentIdHours === 5 ? (
                        <img src={clockIconW} alt="clock-icon" />
                      ) : (
                        <img src={clockIcon} alt="clock-icon" />
                      )}
                      <span>16:30</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-confirm flex colm">
              <button
                disabled={
                  pageMobileOffer === 1
                    ? false
                    : currentIdHours > 0 && !requestLaunchVisit
                    ? false
                    : true
                }
                onClick={nextPageConfirmMobile}
                className="confirm-btn-modal-visit hover-btn"
              >
                Valider
              </button>
              <span>{pageMobileOffer} / 2</span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
