import React, { useEffect, useState } from "react";
import "../Styles/car-carousel.css";
import { CarCard } from "../Components/car-card.component";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import VehiculePicture from "../Images/VehiculePicture.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, Scrollbar, FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { useDispatch, useSelector } from "react-redux";
import { decryptData } from "../Services/encryption.service";
import { post, remove } from "../Services/api.service";
import NotificationService from "../Services/notification.service";
import { setDataFavoris } from "../../feature/autoplus.data";
import { checkUserData } from "../Services/user.check.service";

export const CarCarousel = ({ tabCar }) => {
  const [currentV, setCurrentV] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const dataFavoris = useSelector((state) => state.dataConf.favoris);
  const dispatch = useDispatch();
  const [dataUser, setDataUser] = useState();
  const [currentFav, setCurrentFav] = useState([]);
  useEffect(() => {
    if (dataFavoris) {
      if (JSON.stringify(currentFav) !== JSON.stringify(dataFavoris)) {
        setCurrentFav(dataFavoris);
      }
    }
  }, [dataFavoris]);
  const fetchUserData = async () => {
    const valueUserData = await checkUserData();
    if (valueUserData !== null) {
      setDataUser(valueUserData);
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  const updateFavoris = async (car) => {
    if (dataUser !== undefined || dataUser !== null) {
      let newTab = [];
      let suppressId = 0;
      currentFav.map((fav) => {
        if (fav.carId === car.id) {
          suppressId = fav.id;
        } else {
          newTab.push(fav);
        }
      });
      try {
        if (suppressId === 0) {
          const newFav = await post("/users/favs", {
            userId: dataUser.id,
            carId: car.id,
          });
          newTab.unshift(newFav.data);
          setCurrentFav(newTab);
          dispatch(setDataFavoris(newTab));
          return true;
        } else {
          const rest = await remove(`/users/favs/${suppressId}`);
          setCurrentFav(newTab);
          dispatch(setDataFavoris(newTab));
          return false;
        }
      } catch {
        NotificationService.addToQueue(
          "Vous devez etre connecté pour ajouter un vehicule en favoris"
        );
        return false;
      }
    } else {
      NotificationService.addToQueue(
        "Vous devez etre connecté pour ajouter un vehicule en favoris"
      );
      return false;
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 460) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);
  return (
    <div className="Swipper_container carrousel-countainer">
      <Swiper
        modules={[FreeMode, Autoplay, Pagination, Navigation, Scrollbar]}
        spaceBetween={isMobile === true ? 10 : 20}
        slidesPerView={"auto"}
        onSlideChange={(swiper) => setCurrentV(swiper.activeIndex)}
        loop={false}
        freeMode={true}
        navigation={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        className="swipper-test"
        scrollbar={{
          draggable: !isMobile,
          dragSize: isMobile === true ? 35 : 150,
        }}
      >
        {tabCar.map((car, count) => {
          return (
            <SwiperSlide key={car.id} className="swipper-carrousel test-test">
              <CarCard
                updateFavoris={updateFavoris}
                dataFav={currentFav}
                car={{ ...car, current: currentV, idswip: count }}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
