import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./Shared/Fonts/Gotham/GothamPro.ttf";
import { post } from "./Shared/Services/api.service";
import { RSA } from "hybrid-crypto-js";
import { Provider } from "react-redux";
import store from "./app/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import CheckerData from "./CheckerData";

window.sessionStorage.setItem("firstLoad", true);

if (!window.sessionStorage.getItem("visitorIP")) {
  let rsa = new RSA();
  let visitor = null;
  rsa.generateKeyPair(function (keyPair) {
    visitor = {
      public: keyPair.publicKey,
      private: keyPair.privateKey,
    };

    post("/visitor/create", visitor).then((res) => {
      window.sessionStorage.setItem("visitorIP", res.data.ip);
      window.sessionStorage.setItem("visitorKey", res.data.key);

      const root = ReactDOM.createRoot(document.getElementById("root"));
      root.render(
        <GoogleOAuthProvider clientId="94511604980-cfgm2mrsq8udr5149826gi8u9imbe8gd.apps.googleusercontent.com">
          <Provider store={store}>
            <CheckerData />
          </Provider>
        </GoogleOAuthProvider>
      );
      reportWebVitals();
    });
  });
} else {
  if (document.getElementsByClassName("CookieConsent").length > 0) {
    document.getElementsByClassName("CookieConsent")[0].style.display = "flex";
  }

  document.getElementsByTagName("body")[0].style.overflowY = "scroll";

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <GoogleOAuthProvider clientId="94511604980-cfgm2mrsq8udr5149826gi8u9imbe8gd.apps.googleusercontent.com">
      <Provider store={store}>
        <CheckerData />
      </Provider>
    </GoogleOAuthProvider>
  );

  reportWebVitals();
}
