import React from "react";
import "../Styles/section-header3-card.css";
import CardContent from "@mui/material/CardContent";
import { SectionHeader1 } from "./section-header1.component";

export const SectionHeader3CardContainer = ({ title, text, img }) => {
  return (
    <React.Fragment>
      <CardContent>
        <img src={img} alt="FinancementIcon" />
        <p className="Funding_title">{title}</p>
        <span className="Funding_text">{text}</span>
      </CardContent>
    </React.Fragment>
  );
};
