import "../Styles/cardBuySection.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";
export const CardSellerSection = () => {
  return (
    <div className="how-buy-section flex colm">
      <div className="label-title flex colm">
        <span>A VOS MARQUES, PRÊT, VENDEZ !</span>
        <h2>
          <p>Vendez facilement votre </p>
          <p>véhicule en quelques minutes</p>
        </h2>
      </div>
      <div className="card-info desktop-steps-card flex row btw">
        <div className="step flex colm">
          <div className="label-step flex colm">
            <span>Etape 1</span>
            <h3>Détails du véhicule</h3>
          </div>
          <span>
            Remplissez le formulaire de vente en ligne. fournissez des
            informations précises et détaillées sur votre voiture.
          </span>
        </div>
        <div className="step flex colm">
          <div className="label-step flex colm">
            <span>Etape 2</span>
            <h3>Evaluation du véhicule</h3>
          </div>
          <span>
            notre équipe d'évaluation examinera les informations que vous avez
            fournies et effectuera une évaluation de la valeur de votre
            véhicule.
          </span>
        </div>
        <div className="step flex colm">
          <div className="label-step flex colm">
            <span>Etape 3</span>
            <h3>Finalisez la vente</h3>
          </div>
          <span>
            Lorsque vous acceptez notre estimation de la valeur de votre
            véhicule, nous procéderons à la finalisation de la vente.
          </span>
        </div>
      </div>
      <div className="card-info mobile-card-info flex row btw">
        <Swiper
          loop={false}
          modules={[Pagination]}
          spaceBetween={8}
          // slidesPerView={1}
          grabCursor={true}
          pagination={{ clickable: false }}
        >
          <SwiperSlide>
            <div className="step flex colm">
              <div className="label-step flex colm">
                <span>Etape 1</span>
                <h3>Détails du véhicule</h3>
              </div>
              <span>
                Remplissez le formulaire de vente en ligne. fournissez des
                informations précises et détaillées sur votre voiture.
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="step flex colm">
              <div className="label-step flex colm">
                <span>Etape 2</span>
                <h3>Evaluation du véhicule</h3>
              </div>
              <span>
                notre équipe d'évaluation examinera les informations que vous
                avez fournies et effectuera une évaluation de la valeur de votre
                véhicule.
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="step flex colm">
              <div className="label-step flex colm">
                <span>Etape 3</span>
                <h3>Finalisez la vente</h3>
              </div>
              <span>
                Lorsque vous acceptez notre estimation de la valeur de votre
                véhicule, nous procéderons à la finalisation de la vente.
              </span>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
