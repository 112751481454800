import { useEffect, useRef, useState } from "react";
import calendarIconFilter from "../Images/imports/calendarIcon.svg";
import arrowsIconFilter from "../Images/arrow-down.svg";
import fillArrow from "../Images/imports/checkbox-fill-icon.svg";
import "../Styles/filterYear.css";

export const YearFilter = ({ setYear }) => {
  const [isOpen, setOpen] = useState(false);
  const year = useRef(null);
  const handleChangeYear = () => {
    setYear(year.current.value);
  };

  const handleOpen = () => {
    setOpen(!isOpen);
  };

  return (
    <div
      className={
        isOpen === true
          ? "btn-filter year-filter flex colm is-open-filter"
          : "btn-filter year-filter flex colm"
      }
    >
      <button onClick={handleOpen} className="btn-select flex row btw">
        <div className="left flex row">
          <img src={calendarIconFilter} />
          <span className="open-me-filter">Année du véhicule</span>
        </div>
        <img
          className={
            isOpen === true
              ? "img-arrow-turn is-open-filter-cars"
              : " img-arrow-turn"
          }
          src={arrowsIconFilter}
        />
      </button>
      <div className="input-year">
        <input
          ref={year}
          onChange={handleChangeYear}
          type="number"
          placeholder="2023"
        />
      </div>
    </div>
  );
};
