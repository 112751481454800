import React from "react";
import "../Styles/storefilter.css";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import WrenchScrewDriverIcon from "../Images/wrench-screwdriver-Icon.svg";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { grey, red, yellow } from "@mui/material/colors";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EssenceIcon from "../Images/EssenceIcon.svg";
import TransmissionIcon from "../Images/TransmissionIcon.svg";
import KilometerIcon from "../Images/kilometerIcon.svg";
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';





const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Mercedes',
    'Suzuki',
    'Changan',
    'Toyota',
    'Haval',
    'BMW',
    'Audi',
    'Peugeot',
    'Dacia',
    'Volkswagen',
];




function valuetext(value) {
    return `${value}`;
}

function getStyles(name, brandName, theme) {
    return {
        fontWeight:
            brandName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}



export const StoreFilter = () => {

    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    const [open2, setOpen2] = React.useState(true);

    const handleClick2 = () => {
        setOpen2(!open2);
    };

    const [open3, setOpen3] = React.useState(true);

    const handleClick3 = () => {
        setOpen3(!open3);
    };

    const [open4, setOpen4] = React.useState(true);

    const handleClick4 = () => {
        setOpen4(!open4);
    };

    const [open5, setOpen5] = React.useState(true);

    const handleClick5 = () => {
        setOpen5(!open5);
    };

    const [value, setValue] = React.useState([20, 37]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [gear, setGear] = React.useState('');

    const handleChange2 = (event) => {
        setGear(event.target.value);
    };

    const [fuel, setFuel] = React.useState('');

    const handleChange3 = (event) => {
        setFuel(event.target.value);
    };

    const theme = useTheme();
    const [brandName, setBrandName] = React.useState([]);

    const handleChange4 = (event) => {
        const {
            target: { value },
        } = event;
        setBrandName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (

        <>

            <div className="StoreFilter_input_container">
                <div>
                    <div className="StoreFilter_icon">
                        <SearchOutlinedIcon className="StoreFilter_card_icon" />
                    </div>
                    <div className="StoreFilter_input">
                        <input className="StoreFilter_card_input" placeholder="Chercher ici ..." />
                    </div>
                </div>
                <div className="StoreFilter_icon_right">
                    <CloseOutlinedIcon />
                </div>
            </div>

            <div className="StoreFilter_card">
                <Box sx={{ minWidth: 275, }}>
                    <Card variant="outlined">

                        <React.Fragment>
                            <CardContent>

                                <div className="StoreFilter_list_container">
                                    <List
                                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                    >
                                        <ListItemButton onClick={handleClick}>
                                            <ListItemIcon>
                                                <DirectionsCarFilledOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Marque du véhicule" />
                                            {open ? <RemoveOutlinedIcon /> : <ExpandMore />}
                                        </ListItemButton>
                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 1 }}>

                                                    <div>
                                                        <FormControl sx={{ m: 1, width: 240, mt: 3 }}>
                                                            <Select
                                                                multiple
                                                                displayEmpty
                                                                value={brandName}
                                                                onChange={handleChange4}
                                                                input={<OutlinedInput />}
                                                                renderValue={(selected) => {
                                                                    if (selected.length === 0) {
                                                                        return <em>Peu importe</em>;
                                                                    }

                                                                    return selected.join(', ');
                                                                }}
                                                                MenuProps={MenuProps}
                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                                {names.map((name) => (
                                                                    <MenuItem
                                                                        key={name}
                                                                        value={name}
                                                                        style={getStyles(name, brandName, theme)}
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>


                                                </ListItemButton>
                                            </List>
                                        </Collapse>
                                    </List >
                                </div>

                                <div className="StoreFilter_list_container">
                                    <List
                                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                    >
                                        <ListItemButton onClick={handleClick2}>
                                            <ListItemIcon>
                                                <CalendarMonthOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Année du véhicule" />
                                            {open2 ? <RemoveOutlinedIcon /> : <ExpandMore />}
                                        </ListItemButton>
                                        <Collapse in={open2} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 2 }}>

                                                    <div className="DatePicker_container">
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker views={['year']} />
                                                        </LocalizationProvider>
                                                    </div>

                                                </ListItemButton>
                                            </List>
                                        </Collapse>
                                    </List >
                                </div>

                                <div className="StoreFilter_list_container">
                                    <List
                                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', }}
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                    >
                                        <ListItemButton onClick={handleClick3}>
                                            <ListItemIcon>
                                                <PaymentsOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Prix du véhicule" />
                                            {open3 ? <RemoveOutlinedIcon /> : <ExpandMore />}
                                        </ListItemButton>
                                        <Collapse in={open3} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 1 }}>

                                                    <div>
                                                        <Box
                                                            component="form"
                                                            sx={{
                                                                '& > :not(style)': { m: 1, width: '15ch' },
                                                            }}
                                                            noValidate
                                                            autoComplete="off"
                                                        >


                                                            <div className="">

                                                                <div className="PriceRangeCard_left">
                                                                    <TextField id="outlined-basic" label="Min" variant="outlined" />
                                                                </div>

                                                                <div className="PriceRangeCard_right">
                                                                    <TextField id="outlined-basic" label="Max" variant="outlined" />
                                                                </div>

                                                                <div className="StoreFilter_RangeSlider">

                                                                    <Box sx={{ width: 250 }}>
                                                                        <Slider
                                                                            getAriaLabel={() => 'Temperature range'}
                                                                            value={value}
                                                                            onChange={handleChange}
            
                                                                            valueLabelDisplay="none"
                                                                            getAriaValueText={valuetext}
                                                                            sx={{
                                                                                color: yellow[100],
                                                                                '& .MuiSlider-thumb': {
                                                                                    color: grey[900],
                                                                                },
                                                                                '& .MuiSlider-rail': {
                                                                                    color: grey[400],
                                                                                },
                                                                            }}
                                                                        />
                                                                    </Box>

                                                                </div>

                                                            </div>
                                                        </Box>
                                                    </div>




                                                </ListItemButton>

                                            </List>
                                        </Collapse>
                                    </List >

                                </div>

                                <div className="StoreFilter_list_container">
                                    <List
                                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                    >
                                        <ListItemButton onClick={handleClick4}>
                                            <ListItemIcon>
                                                <SettingsOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Caractéristiques" />
                                            {open4 ? <RemoveOutlinedIcon /> : <ExpandMore />}
                                        </ListItemButton>
                                        <Collapse in={open4} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 1, }}>

                                                    <div className="Select_container">

                                                        <div>
                                                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                                                <Select
                                                                    value={gear}
                                                                    onChange={handleChange2}
                                                                    displayEmpty
                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>Peu importe</em>
                                                                    </MenuItem>
                                                                    <MenuItem value={10}><img src={TransmissionIcon} alt="EssenceIcon" /> &nbsp; Automatique</MenuItem>
                                                                    <MenuItem value={20}><img src={TransmissionIcon} alt="EssenceIcon" /> &nbsp; Manuel</MenuItem>
                                                                </Select>
                                                                <FormHelperText>Boite de vitesse</FormHelperText>
                                                            </FormControl>
                                                        </div>

                                                        <div>
                                                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                                                <Select
                                                                    value={fuel}
                                                                    onChange={handleChange3}
                                                                    displayEmpty
                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>Peu importe</em>
                                                                    </MenuItem>
                                                                    <MenuItem value={10}><img src={EssenceIcon} alt="TransmissionIcon" /> &nbsp; Essence</MenuItem>
                                                                    <MenuItem value={20}><img src={EssenceIcon} alt="TransmissionIcon" /> &nbsp; Gasoil</MenuItem>
                                                                </Select>
                                                                <FormHelperText>Type de carburant</FormHelperText>
                                                            </FormControl>
                                                        </div>

                                                        <div>

                                                            <div className="StoreFilter_km_container">
                                                                <div>
                                                                    <div className="StoreFilter_km_icon">
                                                                        <img src={KilometerIcon} alt="KilometerIcon" className="" />
                                                                    </div>
                                                                    <div className="StoreFilter_km_input">
                                                                        <input className="StoreFilter_card_km_input" placeholder="Ex: 20 000 km" />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <FormHelperText className="km_formhelpertext">Kilometrage</FormHelperText>
                                                    </div>

                                                </ListItemButton>
                                            </List>
                                        </Collapse>
                                    </List >
                                </div>


                                <List
                                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                    component="nav"
                                    aria-labelledby="nested-list-subheader"
                                >
                                    <ListItemButton onClick={handleClick5}>
                                        <ListItemIcon>
                                            <img src={WrenchScrewDriverIcon} alt="WrenchScrewDriverIcon" />
                                        </ListItemIcon>
                                        <ListItemText primary="Condition" />
                                        {open5 ? <RemoveOutlinedIcon /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Collapse in={open5} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 2 }}>
                                                <FormGroup>

                                                    <div className="checkbox_container">
                                                        <div>
                                                            <FormControlLabel control={<Checkbox defaultChecked
                                                                sx={{
                                                                    color: grey[400],
                                                                    '&.Mui-checked': {
                                                                        color: yellow[600],
                                                                    },
                                                                }} />} label="Neuf" />
                                                        </div>

                                                        <div className="checkbox_right">
                                                            <FormControlLabel control={<Checkbox
                                                                sx={{
                                                                    color: grey[400],
                                                                    '&.Mui-checked': {
                                                                        color: yellow[600],
                                                                    },
                                                                }} />} label="Casi neuf" />
                                                        </div>

                                                    </div>

                                                    <FormControlLabel control={<Checkbox
                                                        sx={{
                                                            color: grey[400],
                                                            '&.Mui-checked': {
                                                                color: yellow[600],
                                                            },
                                                        }} />} label="Usé" />

                                                </FormGroup>


                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                </List >





                            </CardContent>
                        </React.Fragment>

                    </Card>
                </Box>

            </div>




        </>

    )
}