import React, { useState } from "react";
import "../Styles/hero-faq.css";
import { Header } from "./header.component";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import searchIcon from "../Images/search-normal.svg"
import { useEffect } from "react";
export const HeroFaq = () => {
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrolledHeight = window.scrollY;
      if (scrolledHeight >= 100) {
        setScrolled(true);
      } else if (scrolledHeight < 100) {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div className="hero_faq_bg flex colm" style={{ position: "relative" }}>
        <Header NoBackground={!scrolled} />

        <div className="search-bar-faq flex colm">
          <p className="hero_faq_title">Comment pouvons nous vous aider ?</p>
          <div className="search-bar-input">
            <img src={searchIcon} />
            <input type="text" placeholder="Posez nous votre question" />
          </div>
        </div>
      </div>
    </>
  );
};
