import React from "react";
import "../Styles/sectionheader1.css";

export const SectionHeader1 = ({ title, subtitle }) => {
  return (
    <div className="header-title flex colm">
      <p className="recommanded_cars">{title}</p>
      <p className="recommanded_cars_subtitle">{subtitle}</p>
    </div>
  );
};

export const SectionHeader2 = ({ title, subtitle }) => {
  return (
    <div>
      <p className="recommanded_cars">{title}</p>
      <p className="recommanded_cars_subtitle">{subtitle}</p>
    </div>
  );
};
