import React, { useEffect, useRef, useState } from "react";
import "../Styles/herosection.css";
import { Header } from "./header.component";
import herosectionBG from "../Images/herosection_bg.png";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { SearchNormalIcon } from "../Images/jsxsvg/icons";
import { useDispatch } from "react-redux";
import { setCarBrand, setSearchTxt } from "../../feature/filter.data";
import { useNavigate } from "react-router";
import { get } from "../Services/api.service";

export const HeroSection = ({ scrolled }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchBarIsActive, setSearchBarIsActive] = useState(false);
  const [currentSearch, setCurrentSearch] = useState("");
  const [abortController, setAbortController] = useState(new AbortController());
  const [allResponse, setAllResponse] = useState([]);

  const handleInputSearch = (e) => {
    setCurrentSearch(e.target.value);
    // dispatch(setSearchTxt(e.target.value));
  };

  const launchSearching = async () => {
    abortController.abort();
    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    try {
      setIsLoading(true);
      const response = await fetchVehicules(newAbortController.signal);
      if (response !== null) {
        let newTab = [];
        newTab = response.results;
        const uniqueArray = newTab.filter((object, index, self) => {
          const key = object.brandName + object.model;
          return (
            index ===
            self.findIndex((item) => item.brandName + item.model === key)
          );
        });
        if (response.results.length === 0) {
          setAllResponse([]);
          setSearchBarIsActive(false);
        } else {
          setAllResponse(uniqueArray);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const fetchVehicules = async (signal) => {
    try {
      const response = await get(
        "/cars/store",
        { search: currentSearch, limit: 5, page: 1 },
        { signal }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    if (currentSearch.length > 2) {
      setSearchBarIsActive(true);
      launchSearching();
    } else {
      setIsLoading(false);
      setSearchBarIsActive(false);
    }

    return () => {
      abortController.abort(); // Clean up when component unmounts or re-renders
    };
  }, [currentSearch]);

  const clickForSearch = (search) => {
    dispatch(setSearchTxt(search));
    dispatch(setCarBrand([]));
    navigate("/acheter");
  };

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      if (currentSearch.length > 2) {
        dispatch(setCarBrand([]));
        navigate("/acheter");
      }
    }
  };
  const searchDivRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (searchDivRef.current && !searchDivRef.current.contains(event.target)) {
      setSearchBarIsActive(false);
    } else {
      if (currentSearch.length >= 3 && allResponse.length > 0) {
        setSearchBarIsActive(true);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <>
      <Header NoBackground={!scrolled} />
      <div className="landingpage_container landing-bg-top">
        <div className="bg" style={{ position: "relative" }}>
          <p className="herosection_title_1">
            Acheter et vendez votre vehicule facilement et cela en toute
            sécurité.
          </p>
          <p className="herosection_title_2">
            Profitez d'un service fiable pour tous vos achats/ventes de
            véhicules.
          </p>
        </div>

        <div ref={searchDivRef} className="herosection_card_container">
          <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined">
              <React.Fragment>
                <CardContent>
                  <div className="herosection_card">
                    <input
                      onChange={(e) => handleInputSearch(e)}
                      className="herosection_input"
                      placeholder="Chercher une voiture"
                      onKeyUp={handleEnterKey}
                    />
                    <button
                      onClick={() => {
                        dispatch(setCarBrand([]));
                        navigate("/acheter");
                      }}
                      className="herosection_button flex row liner addgap8 btw hover-btn"
                    >
                      <SearchNormalIcon color={"var(--white-500)"} /> Chercher
                    </button>
                  </div>
                </CardContent>
              </React.Fragment>
            </Card>
          </Box>
          {searchBarIsActive && (
            <div className="search-check">
              <div className="flex colm liner | search-l">
                {isLoading ? (
                  <span className="flex row liner addgap8 | loader-search">
                    <span></span>
                    <span>Chargement de suggestions</span>
                  </span>
                ) : (
                  allResponse.length > 0 &&
                  allResponse.map((item) => (
                    <button
                      className="flex row liner hover-btn | btn-for-search"
                      onClick={() =>
                        clickForSearch(`${item.brandName} ${item.model}`)
                      }
                    >
                      {`${item.brandName} ${item.model}`}
                    </button>
                  ))
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
