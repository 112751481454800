import { FooterComposant } from "../Shared/Components/footer.composant";
import { Header } from "../Shared/Components/header.component";
import "../Shared/Styles/profilPage.css";
import profilCircle from "../Shared/Images/profile-circle2.svg";
import carIcon from "../Shared/Images/carP.svg";
import heartIcon from "../Shared/Images/heartP.svg";
import clockIcon from "../Shared/Images/clockP.svg";
import smsIcon from "../Shared/Images/smsP.svg";
import carIconS1 from "../Shared/Images/car.svg";
import arrowIcon from "../Shared/Images/sendP.svg";
import arrowIconPMobile from "../Shared/Images/arrow-rightP.svg";
import moreIcon from "../Shared/Images/imports/add-circle-icon.svg";
import iconTrash from "../Shared/Images/imports/trash.svg";
import carTypeIcon from "../Shared/Images/imports/car.svg";
import logoutP from "../Shared/Images/logoutP.svg";
import lockP from "../Shared/Images/lockP.svg";
import imgIllustration from "../Shared/Images/illustrationCar.png";
import imgIllustration2 from "../Shared/Images/illustrationCar2.png";
import VehiculePicture from "../Shared/Images/VehiculePicture.svg";
import lockIcon from "../Shared/Images/lock.svg";
import trashIcon from "../Shared/Images/imports/trash-icon.svg";
import autoSimpleIcon from "../Shared/Images/imports/auto-simple.svg";
import arrowTil from "../Shared/Images/imports/send.svg";
import marqueIcon from "../Shared/Images/car2.svg";
import calendarIcon from "../Shared/Images/calendar.svg";
import gassIcon from "../Shared/Images/gassIcon.svg";
import gearShiftIcon from "../Shared/Images/gearshift.svg";
import kilometerIcon from "../Shared/Images/kilometer.svg";
import wingIcon from "../Shared/Images/wing.svg";
import wrenchIcon from "../Shared/Images/wrench.svg";
import arrowIconS from "../Shared/Images/arrow-down.svg";
import seatIcon from "../Shared/Images/imports/seat-icon.svg";
import coinIcon from "../Shared/Images/imports/coin-icon.svg";
import doorIcon from "../Shared/Images/imports/car-doors.svg";
import engineIcon from "../Shared/Images/imports/engine-icon.svg";

import pinIconModal from "../Shared/Images/pin.svg";
import smsIconModal from "../Shared/Images/sms.svg";
import callIconModal from "../Shared/Images/call.svg";
import lockIconModal from "../Shared/Images/lock.svg";
import eyeSlashIconModal from "../Shared/Images/eye-slash.svg";
import trashIconM from "../Shared/Images/imports/trash.svg";

import { useEffect, useState } from "react";
import { StoreFilterCardContainer } from "../Shared/Components/StoreFilter-card-container";
import { Link, useParams } from "react-router-dom";
import { useRef } from "react";
import { CarCard } from "../Shared/Components/car-card.component";
import {
  decryptData,
  encryptData,
} from "../Shared/Services/encryption.service";
import {
  redirectToHome,
  redirectToURL,
} from "../Shared/Services/redirect.service";
import { capitalizeFirstLetters } from "../Shared/Services/letters.manage";
import NotificationService from "../Shared/Services/notification.service";
import { returnStaticInfo } from "../Shared/Services/simplerequest.service";
import { QuickSelectedInput } from "../Shared/Components/quick-sell-input/QuickSelectedInput";
import { useDispatch, useSelector } from "react-redux";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import {
  get,
  patch,
  post,
  postFile,
  remove,
} from "../Shared/Services/api.service";
import env from "react-dotenv";
import axios from "axios";
import { setDataFavoris } from "../feature/autoplus.data";
import { async } from "q";
import { checkUserData } from "../Shared/Services/user.check.service";
import { getVisitorPassport } from "../Shared/Services/visitor.service";
import { getMyId } from "../Shared/Services/get-goodId-static";
import moment from "moment";
import { ReturnTop } from "../Shared/Components/ReturnTop";
import { SelectComplementary } from "../Shared/Components/quick-sell-input/QuickSelectComplementary";
import { setCarBrand } from "../feature/filter.data";

export const ProfilPageComposant = () => {
  const { id } = useParams();
  const [currentMenu, setCurrentMenu] = useState(0);

  const [dataUser, setDataUser] = useState();
  const [carUserCurrentEdit, setMyUserEditPage] = useState(null);

  const [currMarqueValue, setCurrMarqueValue] = useState("");
  const [currModelValue, setCurrModelValue] = useState("");
  const [currCarTypeValue, setCurrCartypeValue] = useState("");
  const [currentYearValue, setCurrentYearValue] = useState(0);
  const [currOilTypeValue, setCurrOilTypeValue] = useState("");
  const [currKilometerValue, setKilometerValue] = useState("");
  const [currTransmissionValue, setCurrTransmissionValue] = useState("");
  const [currConditionValue, setCurrConditionValue] = useState("");
  const [currPriceValue, setCurrPriceValue] = useState(0);
  const [currDoorValue, setCurrDoorValue] = useState(0);
  const [currPlaces, setCurrPlaces] = useState(0);
  const [currDescription, setCurrDescription] = useState("");

  const fetchUserData = async () => {
    const valueUserData = await checkUserData();
    if (valueUserData !== null) {
      setDataUser(valueUserData);
    } else {
      setDataUser(null);
      redirectToURL("/connexion");
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const dataFavoris = useSelector((state) => state.dataConf.favoris);
  const dispatch = useDispatch();
  const [currentFav, setCurrentFav] = useState([]);
  const [currentFavCar, setCurrentFavCar] = useState([]);
  const [firstLoadFav, setFirstLoadFav] = useState(false);

  useEffect(() => {
    if (dataFavoris && firstLoadFav === false) {
      if (dataFavoris.length > 0) {
        setCurrentFav(dataFavoris);
        setFirstLoadFav(true);
      }
    }
  }, [dataFavoris]);

  const updateFavoris = async (car) => {
    if (dataUser !== undefined || dataUser !== null) {
      let newTab = [];
      let suppressId = 0;
      currentFav.map((fav) => {
        if (fav.carId === car.id) {
          suppressId = fav.id;
        } else {
          newTab.push(fav);
        }
      });
      try {
        if (suppressId === 0) {
          const newFav = await post("/users/favs", {
            userId: dataUser.id,
            carId: car.id,
          });
          newTab.unshift(newFav.data);
          setCurrentFav(newTab);
          dispatch(setDataFavoris(newTab));
          return true;
        } else {
          const rest = await remove(`/users/favs/${suppressId}`);
          dispatch(setDataFavoris(newTab));
          setCurrentFav(newTab);
          return false;
        }
      } catch {
        NotificationService.addToQueue(
          "Vous devez etre connecté pour ajouter un vehicule en favoris"
        );
        return false;
      }
    } else {
      NotificationService.addToQueue(
        "Vous devez etre connecté pour ajouter un vehicule en favoris"
      );
      return false;
    }
  };
  const updatedCurrentFav = async () => {
    try {
      const favPromises = dataFavoris.map(async (fav) => {
        const responseGet = await get(`/cars/${fav.carId}`);
        return responseGet.data;
      });
      const tabCar = await Promise.all(favPromises);
      let tab = tabCar;
      tab = await tabCar.filter((item) => item !== null);
      setCurrentFavCar(tab);
    } catch (error) {
      console.error("Erreur lors de la mise à jour des favoris :", error);
    }
  };

  const [lastAddCars, setLastAddCars] = useState([]);
  const searchLastAdd = async () => {
    if (dataUser !== null && dataUser !== undefined) {
      try {
        get(`/users/latest_views/${dataUser.id}`)
          .then(async (response) => {
            let tab = response.data;
            tab = await tab.filter((item) => item.car !== null);
            setLastAddCars(tab);
          })
          .catch((error) => {
            return error;
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    searchLastAdd();
  }, [currentMenu]);

  useEffect(() => {
    updatedCurrentFav();
  }, [currentFav, id]);

  const refTextDescrypt = useRef(null);
  const resetInputSaved = () => {
    setCurrMarqueValue("");
    setCurrModelValue("");
    setCurrentYearValue(0);
    setCurrOilTypeValue("");
    setKilometerValue("");
    setCurrCartypeValue("");
    setCurrTransmissionValue("");
    setCurrConditionValue("");
    setCurrConditionValue("");
    setCurrPriceValue(0);
    setCurrDoorValue(0);
    setCurrPlaces(0);
    setCurrDescription("");
  };

  const handleSelectMenu = (id) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setMyUserEditPage(null);
    resetInputSaved();

    setCurrentMenu(id);
  };

  const [userName, setNameUser] = useState("");
  const [userEmail, setEmailUser] = useState("");
  const [userTel, setTelUser] = useState("");
  const [userHabit, setHabitUser] = useState("");
  const [userSocial, setUserSocial] = useState("");
  const [verifiedAccount, setVerifiedAccount] = useState("");
  const [cniUserImage, setCniUserImage] = useState();
  const [showCNILink, setShowCNI] = useState(<img src="" alt="cni-upload" />);

  useEffect(() => {
    if (!cniUserImage) return;
    if (typeof cniUserImage === "object") {
      setShowCNI(
        <img src={URL.createObjectURL(cniUserImage)} alt="cni-upload" />
      );
    }
    if (typeof cniUserImage === "string") {
      setShowCNI(<img src={cniUserImage} alt="cni-upload" />);
    }
  }, [cniUserImage]);
  useEffect(() => {
    if (dataUser === undefined || dataUser === null) return;
    if (dataUser.firstname != dataUser.lastname) {
      setNameUser(
        capitalizeFirstLetters(dataUser.firstname + " " + dataUser.lastname)
      );
    } else {
      setNameUser(capitalizeFirstLetters(dataUser.firstname));
    }
    if (dataUser.isSocial) {
      if (dataUser.isSocial.toString().toLowerCase() !== "no")
        setUserSocial(dataUser.isSocial);
    }
    if (dataUser.cni) {
      const envLocal = env.API_PRIVATE_URL + "/assets/uploads/cnis/";
      setCniUserImage(envLocal + dataUser.cni);
    }
    if (
      dataUser.email.startsWith("google.") ||
      dataUser.email.startsWith("facebook.")
    ) {
      if (dataUser.email.startsWith("google."))
        setEmailUser(dataUser.email.replace("google.", ""));
      if (dataUser.email.startsWith("facebook."))
        setEmailUser(dataUser.email.replace("facebook.", ""));
      setVerifiedAccount("social-account");
    } else {
      setEmailUser(dataUser.email);
    }
    setHabitUser(dataUser.location);

    if (
      dataUser.emailConfirmedAt !== null &&
      dataUser.emailConfirmedAt !== undefined
    ) {
      setVerifiedAccount(dataUser.emailConfirmedAt);
    }
    if (
      dataUser.phone.includes("google") ||
      dataUser.phone.includes("facebook")
    )
      return;
    setTelUser(dataUser.phone);
  }, [dataUser]);

  const [messagesNotif, setMessagesNotif] = useState([]);
  const [notifInLoading, setNotifLoading] = useState(false);

  async function checkMyNotificationsAgain() {
    try {
      let notifications = await get(`/users/notifs/${dataUser.id}`);
      setMessagesNotif(notifications.data);
      setNotifLoading(false);
    } catch (error) {
      console.log(error);
      setNotifLoading(false);
      NotificationService.addToQueue(
        "Erreur lors de la recupération d vos messages de notification"
      );
    }
  }

  useEffect(() => {
    if (dataUser === undefined || dataUser === undefined || currentMenu !== 5) {
      setMessagesNotif([]);
      return;
    }
    setNotifLoading(() => true);
    checkMyNotificationsAgain();
  }, [currentMenu, dataUser]);

  const targetRef = useRef(null);
  const [isVisibleAllCard, setIsVisibleAllCard] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisibleAllCard(true);
        } else {
          setIsVisibleAllCard(false);
        }
      });
    });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);
  const [sellerCars, setSellerCars] = useState([]);
  const fetchCarSeller = async () => {
    if (dataUser !== undefined && dataUser !== null) {
      const selCars = await Promise.resolve(
        returnStaticInfo(`/users/cars/${dataUser.id}`)
      );
      if (selCars === null) {
        setSellerCars([]);
      } else setSellerCars(selCars);
    }
  };
  const functionNonDispo = () => {
    NotificationService.addToQueue("Fonctionnalitée pas encore disponible");
  };

  useEffect(() => {
    if (dataUser === null || dataUser === undefined) return;
    fetchCarSeller();

    setMyUserEditPage(null);
    setCurrentMenu(parseInt(id, 10) || 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [id, dataUser]);

  const disconnectedMe = () => {
    window.localStorage.removeItem("userDataSave");
    window.sessionStorage.removeItem("userDataSave");
    redirectToHome();
  };
  const [canGoPatchedVehucule, setCanGoPatchedVehicule] = useState(false);
  const dataBrands = useSelector((state) => state.dataConf.brands);
  const dataCarsType = useSelector((state) => state.dataConf.carTypes);
  const dataOilsType = useSelector((state) => state.dataConf.motorTypes);
  const dataTransmissions = useSelector(
    (state) => state.dataConf.transmissions
  );
  const dataConditions = useSelector((state) => state.dataConf.conditions);
  const [allBrands, setBrands] = useState([]);
  const [allCarsType, setCarsType] = useState([]);
  const [currentOilType, setCurrentOilType] = useState([]);
  const [currentTransmission, setCurrentTransmission] = useState([]);
  const [currentCondition, setCurrentCondition] = useState([]);
  const [lastYears, setLastYears] = useState([]);
  const fetchFindYear = () => {
    const currentYear = new Date().getFullYear();
    let last200Years = [];
    for (let i = currentYear; i >= currentYear - 199; i--) {
      last200Years.push({ name: i, value: i });
    }
    setLastYears(last200Years);
  };
  useEffect(() => {
    fetchFindYear();
  }, []);

  useEffect(() => {
    if (dataBrands === undefined || dataBrands === null) return;
    if (dataCarsType === undefined || dataCarsType === null) return;
    if (dataOilsType === undefined || dataOilsType === null) return;
    if (dataTransmissions === undefined || dataTransmissions === null) return;
    if (dataConditions === undefined || dataConditions === null) return;
    setBrands(dataBrands);
    setCarsType(dataCarsType);
    setCurrentOilType(dataOilsType);
    setCurrentTransmission(dataTransmissions);
    setCurrentCondition(dataConditions);
  }, [
    dataBrands,
    dataCarsType,
    dataOilsType,
    dataTransmissions,
    dataConditions,
  ]);

  /*IMPORT IMAGES*/
  const [preloadImgCar, setPreloadImgCar] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    let currImgDownloaded = [];
    if (carUserCurrentEdit === null) {
      setSelectedImages([]);
      setPreloadImgCar([]);
    } else {
      const envLocal = env.API_PRIVATE_URL + "/assets/uploads/cars/";
      setSelectedImages([]);
      setPreloadImgCar([]);
      currImgDownloaded.push(envLocal + carUserCurrentEdit.thumb);
      carUserCurrentEdit.images.map((item) => {
        if (item.trim() !== "") currImgDownloaded.push(envLocal + item);
      });
      setPreloadImgCar(currImgDownloaded);
    }
  }, [carUserCurrentEdit]);

  const deletedOlderImage = (value) => {
    let newTab = [];
    newTab = preloadImgCar;
    newTab = newTab.filter((item) => item !== value);
    setPreloadImgCar(newTab);
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    const imageFiles = Array.from(files).filter((file) => {
      return file.type === "image/jpeg" || file.type === "image/png";
    });
    setSelectedImages([...selectedImages, ...imageFiles]);
  };
  const handleAddCni = (event) => {
    if (
      dataUser.profile.toLowerCase() === "seller" ||
      dataUser.checkVendor === true
    ) {
      return null;
    }
    const files = event.target.files;
    const imageFiles = Array.from(files).filter((file) => {
      return file.type === "image/jpeg" || file.type === "image/png";
    });
    setCniUserImage(imageFiles[0]);
  };
  const goUploadCni = () => {
    if (
      dataUser.profile.toLowerCase() === "seller" ||
      dataUser.checkVendor === true
    ) {
      return null;
    }
    setLoadPatchCar(true);
    NotificationService.addToQueue("Ajout de vôtre pièce en cours");
    uploadCni();
  };
  const uploadCni = async () => {
    let data = new FormData();
    let responCNIPlace = "";
    data.append("image", cniUserImage, "cnis");
    await axios
      .post(env.API_PRIVATE_URL + "/services/upload_image", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        responCNIPlace = response.data.filename;
      })
      .catch((error) => {
        console.log(error);
        setLoadPatchCar(false);
        NotificationService.addToQueue(
          "Erreur lors de l'ajout de la pièce, Réesayer plus tard"
        );
        return null;
      });
    let nData = {
      id: dataUser.id,
      email: userEmail,
      password: oldpass,
      isSocial: userSocial,
      cni: responCNIPlace,
    };
    if (userSocial === "") {
      await patch(`/users/${dataUser.id}`, nData)
        .then((response) => {
          setLoadPatchCar(false);
          setAddCniModal(false);
          let newData = { ...dataUser, cni: responCNIPlace };
          setDataUser(newData);
          NotificationService.addToQueue("Ajout de vôtre Pièce reussi");
        })
        .catch((error) => {
          setLoadPatchCar(false);
          console.log(error);
          if (error.response && error.response.status === 403) {
            NotificationService.addToQueue("Mot de passe incorrect");
          } else
            NotificationService.addToQueue(
              "Erreur lors de l'ajout de la pièce, Réesayer plus tard"
            );
        });
    } else {
      await patch(`/users/social/${dataUser.id}`, nData)
        .then((response) => {
          setLoadPatchCar(false);
          setAddCniModal(false);
          let newData = { ...dataUser, cni: responCNIPlace };
          setDataUser(newData);
          NotificationService.addToQueue("Ajout de vôtre Pièce reussi");
        })
        .catch((error) => {
          setLoadPatchCar(false);
          console.log(error);
          if (error.response && error.response.status === 403) {
            NotificationService.addToQueue("Mot de passe incorrect");
          } else
            NotificationService.addToQueue(
              "Erreur lors de l'ajout de la pièce, Réesayer plus tard"
            );
        });
    }
    setLoadPatchCar(false);
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };
  const [loadPatchCar, setLoadPatchCar] = useState(false);
  const patchCars = async (newImg) => {
    let newCarData = await {
      id: carUserCurrentEdit.id,
      ownerId: dataUser.id,
      brandName: currMarqueValue,
      carTypeId: getMyId(currCarTypeValue, allCarsType),
      conditionId: getMyId(currConditionValue, dataConditions),
      doors: currDoorValue,
      kilometers:
        (parseInt(currKilometerValue) < 0 ? 0 : currKilometerValue) || 0,
      model: currModelValue,
      motorTypeId: getMyId(currOilTypeValue, dataOilsType),
      places: currPlaces,
      price: currPriceValue,
      reportingNotes: currDescription,
      transmissionId: getMyId(currTransmissionValue, dataTransmissions),
      year: currentYearValue,
      thumb: newImg[0],
      images: newImg.slice(1),
      exteriorsTabObjectValue: encryptData(quickComplementaryExterieur),
      interiorsTabObjectValue: encryptData(quickComplementaryInterieur),
      securityTabObjectValue: encryptData(quickComplementarySecurity),
      othersTabObjectValue: encryptData(quickComplementaryOthers),
    };
    patch(`/cars/${carUserCurrentEdit.id}`, newCarData)
      .then((response) => {
        NotificationService.addToQueue("Modification reussi");
        fetchCarSeller();
        setMyUserEditPage(response.data);
        setLoadPatchCar(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadPatchCar(false);
        NotificationService.addToQueue("Erreur lors de la modification");
      });
  };
  const checkCanGoPatchCar = () => {
    if (currMarqueValue.trim().length < 1) return false;
    if (getMyId(currCarTypeValue, allCarsType) === 0) return false;
    if (getMyId(currConditionValue, dataConditions) === null) return false;
    if (currDoorValue.toString().trim().length === 0) return false;
    if (currKilometerValue.toString().trim().length === 0) return false;
    if (currModelValue.toString().trim().length < 1) return false;
    if (getMyId(currOilTypeValue, dataOilsType) === 0) return false;
    if (currPlaces.toString().trim().length === 0) return false;
    if (currPriceValue.toString().trim().length === 0) return false;
    if (currDescription.toString().trim().length < 5) return false;
    if (getMyId(currTransmissionValue, dataTransmissions) === 0) return false;
    if (currentYearValue.toString().trim().length < 4) return false;
    if ([...selectedImages, ...preloadImgCar].length < 5) return false;
    return true;
  };
  useEffect(() => {
    if (carUserCurrentEdit !== null) {
      setCanGoPatchedVehicule(checkCanGoPatchCar());
    }
  }, [
    currMarqueValue,
    currCarTypeValue,
    currConditionValue,
    currDoorValue,
    currKilometerValue,
    currModelValue,
    currOilTypeValue,
    currPlaces,
    currPriceValue,
    currDescription,
    currTransmissionValue,
    currentYearValue,
    selectedImages,
    preloadImgCar,
  ]);

  const validatePatchCars = async () => {
    if (checkCanGoPatchCar() === false) return;
    if ([...selectedImages, ...preloadImgCar].length <= 4) {
      NotificationService.addToQueue(
        "Il faut au minimun 5 images du véhicule pour continuer"
      );
      return;
    }
    NotificationService.addToQueue("Modification de votre voiture en cours");
    const imageNames = preloadImgCar.map((url) => {
      const parts = url.split("/");
      const fileName = parts[parts.length - 1];
      return fileName;
    });
    setLoadPatchCar(true);
    try {
      for await (const image of selectedImages) {
        const data = new FormData();
        data.append("image", image, "cars");
        await axios
          .post(env.API_PRIVATE_URL + "/services/upload_image", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            imageNames.push(response.data.filename);
          })
          .catch((error) => {
            return error;
          });
      }
      patchCars(imageNames);
    } catch (error) {
      console.error("Erreur lors de l'upload des images :", error);
    }
  };
  const [dateShow, setDateShow] = useState("");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (carUserCurrentEdit !== null) {
      const newDate = moment(carUserCurrentEdit.createdAt);
      setDateShow(newDate.format("dddd, D MMMM YYYY à HH:MM"));
      if (refTextDescrypt !== null)
        setCurrDescription(carUserCurrentEdit.reportingNotes);
      refTextDescrypt.current.value = carUserCurrentEdit.reportingNotes;
    }
    if (carUserCurrentEdit === null) resetInputSaved();
  }, [carUserCurrentEdit]);

  const cancelHandleEditCar = () => {
    setMyUserEditPage(null);
  };

  const [suppressCar, setSuppressCar] = useState(false);
  const deleteVehicule = () => {
    setSuppressCar(true);
  };
  const deleteVehiculeNow = () => {
    setSuppressCar(false);
    setLoadPatchCar(true);
    patch(`/cars/suppress/${carUserCurrentEdit.id}`, { deletedAt: new Date() })
      .then((response) => {
        NotificationService.addToQueue(
          `Suppression de ${carUserCurrentEdit.brandName} ${carUserCurrentEdit.model} reussi`
        );
        fetchCarSeller();
        setMyUserEditPage(null);
        setLoadPatchCar(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadPatchCar(false);
        NotificationService.addToQueue("Erreur lors de la suppression");
      });
  };

  const [amBottom, setAmBottom] = useState(false);
  const [amBottomTitle, setAmBottomTitle] = useState(false);
  const [distanceCalculTop, setDistanceCalculTop] = useState(0);
  const [distanceCalcul, setDistanceCalcul] = useState(0);
  const [timeoutId, setTimeoutId] = useState();

  useEffect(() => {
    const handleDistanceCheck = () => {
      const div1 = document.getElementById("selectProfilPannel");
      const div2 = document.getElementById("footerDiv");
      const title = document.getElementById("titlecurrent");

      if (title) {
        const titleRect = title.getBoundingClientRect();
        if (titleRect.bottom <= -70) {
          setAmBottomTitle(true);
        } else {
          setAmBottomTitle(false);
        }
      }

      if (div1 && div2) {
        const div1Rect = div1.getBoundingClientRect();
        const div2Rect = div2.getBoundingClientRect();

        const distanceToTop = div2Rect.top;
        setDistanceCalculTop(distanceToTop);
        setDistanceCalcul(window.innerHeight - distanceToTop + 40);
        const distance = div1Rect.bottom - div2Rect.top;

        if (distanceToTop >= 590) {
          if (amBottom === true) {
            clearTimeout(timeoutId);
            setAmBottom(false);
          }
        }
        if (distance >= -20 && distanceToTop < 590) {
          if (amBottom === false) {
            const newTimeoutId = setTimeout(() => setAmBottom(true), 1);
            setTimeoutId(newTimeoutId);
          }
        }
      }
    };

    window.addEventListener("scroll", handleDistanceCheck);

    return () => {
      window.removeEventListener("scroll", handleDistanceCheck);
      clearTimeout(timeoutId);
    };
  }, [amBottom, timeoutId, amBottomTitle]);

  const [currentModalOpen, setCurrentModalOpen] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [showMdp, setShowMdp] = useState(false);
  const handleChangeShowMdp = () => {
    setShowMdp(!showMdp);
  };
  const [showSecondMdp, setSecondShowMdp] = useState(false);
  const handleChangeSecondShowMdp = () => {
    setSecondShowMdp(!showSecondMdp);
  };

  const handleCloseModal = (e) => {
    setCurrentModalOpen(0);
    e.stopPropagation();
  };

  function GoPatch(data) {
    if (userSocial === "") {
      return new Promise((resolve, reject) => {
        patch(`/users/${dataUser.id}`, data)
          .then((response) => {
            console.log(response.status);
            resolve(response.data);
          })
          .catch((error) => {
            if (error.response.status === 403) {
              NotificationService.addToQueue(error.response.data.message);
            }
            console.log(error);
            resolve(null);
          });
      });
    }
    return GoPatchSocial(data);
  }
  function GoPatchSocial(data) {
    return new Promise((resolve, reject) => {
      patch(`/users/social/${dataUser.id}`, { ...data, email: userEmail })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 403) {
            NotificationService.addToQueue(error.response.data.message);
          }
          resolve(null);
        });
    });
  }

  const saveNewDataUser = async (newMail = null) => {
    if (newMail === null) return;
    const userDataSaveInSession = sessionStorage.getItem("userDataSave");
    const userDataSaveInLocalStorage = localStorage.getItem("userDataSave");
    let dataDecrypt = "";
    if (userDataSaveInSession) {
      dataDecrypt = await decryptData(userDataSaveInSession);
    }
    if (userDataSaveInLocalStorage) {
      dataDecrypt = await decryptData(userDataSaveInLocalStorage);
    }
    console.log({
      token: dataDecrypt.token,
      email: newMail === null ? dataDecrypt.token : newMail,
    });
    const encryptedNewUserDataSave = encryptData({
      token: dataDecrypt.token,
      email: newMail === null ? dataDecrypt.token : newMail,
    });
    if (userDataSaveInSession) {
      window.sessionStorage.setItem("userDataSave", encryptedNewUserDataSave);
    } else {
      window.localStorage.setItem("userDataSave", encryptedNewUserDataSave);
    }
  };
  const handleValidateInfo = async (e) => {
    setIsLoading(true);
    e.stopPropagation();
    if (currentModalOpen === 1) {
      NotificationService.addToQueue("Modification de votre email en cours");
      let mail = emailInput;
      const responseData = await GoPatch({
        email: emailInput,
        password: oldpass,
        isSocial: userSocial,
      });
      if (responseData === null) {
        setIsLoading(false);
        NotificationService.addToQueue(
          "Erreur lors de la modification de l'email, réesayer plus tard"
        );
      } else {
        setIsLoading(false);
        NotificationService.addToQueue(
          "Votre email a été modifié avec succès."
        );
        await setDataUser(() => ({ ...dataUser, email: emailInput }));
        setCurrentModalOpen(0);
        saveNewDataUser(mail);
      }
    }
    if (currentModalOpen === 2) {
      NotificationService.addToQueue(
        "Modification de votre numero de téléphone en cours"
      );
      const responseData = await GoPatch({
        phone: telinput,
        password: oldpass,
        isSocial: userSocial,
      });
      if (responseData === null) {
        setIsLoading(false);
        NotificationService.addToQueue(
          "Erreur lors de la modification de votre numero de téléphone, réesayer plus tard"
        );
      } else {
        setIsLoading(false);
        NotificationService.addToQueue(
          "Votre numero de téléphone a été modifié avec succès."
        );
        await setDataUser(() => ({ ...dataUser, phone: telinput }));
        setCurrentModalOpen(0);
        saveNewDataUser();
      }
    }
    if (currentModalOpen === 3) {
      NotificationService.addToQueue(
        "Modification de votre mot de passe en cours"
      );
      const responseData = await GoPatch({
        newpassword: newpass,
        password: oldpass,
        isSocial: userSocial,
      });
      if (responseData === null) {
        setIsLoading(false);
        NotificationService.addToQueue(
          "Erreur lors de la modification de votre mot de passe, réesayer plus tard"
        );
      } else {
        setIsLoading(false);
        NotificationService.addToQueue(
          "Votre mot de passe a été modifié avec succès."
        );
        setCurrentModalOpen(0);
      }
    }
    if (currentModalOpen === 4) {
      NotificationService.addToQueue(
        "Modification de votre lieu d'habitation en cours"
      );
      const responseData = await GoPatch({
        location: habitationInput,
        password: oldpass,
        isSocial: userSocial,
      });
      if (responseData === null) {
        setIsLoading(false);
        NotificationService.addToQueue(
          "Erreur lors de la modification de votre lieu d'habitation, réesayer plus tard"
        );
      } else {
        setIsLoading(false);
        NotificationService.addToQueue(
          "Votre lieu d'habitation a été modifié avec succès."
        );
        await setDataUser(() => ({ ...dataUser, location: habitationInput }));
        setCurrentModalOpen(0);
        saveNewDataUser();
      }
    }
  };

  const [emailInput, setEmailInput] = useState("");
  const [habitationInput, setHabitationInput] = useState("");
  const [telinput, setTelInput] = useState("");
  const [oldpass, setOldPass] = useState("");
  const [newpass, setNewPass] = useState("");

  const [addCniModal, setAddCniModal] = useState(false);
  const [validateModal, setValidateModal] = useState(false);
  useEffect(() => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (userSocial === "")
      if (oldpass.trim().length < 8) {
        setValidateModal(false);
        return;
      }
    if (currentModalOpen === 1) {
      if (emailInput.match(mailformat)) {
        setValidateModal(true);
      } else {
        setValidateModal(false);
      }
    }
    if (currentModalOpen === 2) {
      if (telinput.trim().length < 10) {
        setValidateModal(false);
      } else {
        setValidateModal(true);
      }
    }
    if (currentModalOpen === 3) {
      if (newpass.trim().length >= 8 && newpass.trim() != oldpass.trim()) {
        setValidateModal(true);
      } else {
        setValidateModal(false);
      }
    }
    if (currentModalOpen === 4) {
      if (habitationInput.trim().length < 4) {
        setValidateModal(false);
      } else {
        setValidateModal(true);
      }
    }
  }, [
    newpass,
    oldpass,
    telinput,
    habitationInput,
    emailInput,
    currentModalOpen,
  ]);

  const handleOpenModal = (val) => {
    setEmailInput("");
    setHabitationInput("");
    setTelInput("");
    setOldPass("");
    setNewPass("");
    setCurrentModalOpen(val);
  };

  const [openPanelNotif, setOpenPanelNotif] = useState(null);
  const [openDeleteNotif, setOpenDeletePanelNotif] = useState(null);
  const [openSendVendor, setOpenSendVendor] = useState(false);
  function viewNotif(notif) {
    setOpenPanelNotif(notif);
    patchNotif(notif);
  }
  function switchStateNotif(notif) {
    patchNotif(notif);
  }
  function patchNotif(notif) {
    const updatedMessagesNotif = messagesNotif.map((item) => {
      return notif.id === item.id ? { ...item, open: true } : item;
    });
    setMessagesNotif(updatedMessagesNotif);
    patch(`/users/notifs/${notif.id}`, {
      userId: dataUser.id,
      open: true,
    });
  }
  function deleteNotif(notif) {
    setOpenDeletePanelNotif(notif);
  }
  async function suppressNotif() {
    let tab = messagesNotif.filter((notif) => notif.id !== openDeleteNotif.id);
    setMessagesNotif(tab);
    let idNo = openDeleteNotif.id;

    setOpenDeletePanelNotif(null);
    let response = await patch(`/users/notifs/${idNo}`, {
      userId: dataUser.id,
      deletedAt: new Date(),
    });
  }
  async function sendGoVendor() {
    setLoadPatchCar(true);
    let data = {
      id: dataUser.id,
      email: userEmail,
      password: oldpass,
      isSocial: userSocial,
      checkVendor: true,
    };
    if (userSocial === "") {
      console.log(data);
      await patch(`/users/${dataUser.id}`, data)
        .then((response) => {
          setLoadPatchCar(false);
          setOpenSendVendor(false);
          let newData = { ...dataUser, checkVendor: true };
          setDataUser(newData);
          NotificationService.addToQueue(
            "Votre demande afin d'être vendeur sur AUTOPLUS à été effectuer"
          );
          NotificationService.addToQueue(
            "Vous receverez la reponse du service AUTOPLUS suite par email et par notification"
          );
        })
        .catch((error) => {
          setLoadPatchCar(false);
          console.log(error);
          if (error.response && error.response.status === 403) {
            NotificationService.addToQueue("Mot de passe incorrect");
          } else
            NotificationService.addToQueue(
              "Erreur lors de la demande, Réesayer plus tard"
            );
        });
    } else {
      await patch(`/users/social/${dataUser.id}`, data)
        .then((response) => {
          setLoadPatchCar(false);
          setOpenSendVendor(false);
          let newData = { ...dataUser, checkVendor: true };
          setDataUser(newData);
          NotificationService.addToQueue(
            "Votre demande afin d'être vendeur sur AUTOPLUS à été effectuer"
          );
          NotificationService.addToQueue(
            "Vous receverez la reponse du service AUTOPLUS suite par email et par notification"
          );
        })
        .catch((error) => {
          setLoadPatchCar(false);
          console.log(error);
          if (error.response && error.response.status === 403) {
            NotificationService.addToQueue("Mot de passe incorrect");
          } else
            NotificationService.addToQueue(
              "Erreur lors de la demande, Réesayer plus tard"
            );
        });
    }
  }
  const activeAskVendorPanel = () => {
    setOldPass("");
    setSecondShowMdp(false);
    if (
      cniUserImage &&
      !userTel.toLowerCase().includes("google") &&
      !userTel.toLowerCase().includes("facebook") &&
      userTel &&
      userHabit &&
      verifiedAccount.length > 1
    ) {
      setOpenSendVendor(true);
    } else {
      console.log(
        cniUserImage,
        !userTel.toLowerCase().includes("google"),
        !userTel.toLowerCase().includes("facebook"),
        userTel,
        userHabit,
        verifiedAccount.length > 1
      );
      NotificationService.addToQueue(
        "Verifier que vous avez renseigné tout les champs de vôtre profil afin de continuer"
      );
      NotificationService.addToQueue(
        "Aussi, votre pièce doit être téléverser et vôtre email doit être confirmer"
      );
    }
  };
  const formattedDate = (val) => {
    return moment(val).format("DD MMMM, YYYY");
  };

  const getNewTab = (itemId, table) => {
    let newTab = [];
    table.map((item) => {
      let objectI = item;
      if (objectI.id === itemId)
        newTab.push({ ...objectI, active: !objectI.active });
      else newTab.push(objectI);
    });
    return newTab;
  };

  const [quickComplementaryExterieur, setQuickComplementaryExterieur] =
    useState([]);
  const [quickComplementaryInterieur, setQuickComplementaryInterieur] =
    useState([]);
  const [quickComplementarySecurity, setQuickComplementarySecurity] = useState(
    []
  );
  const [quickComplementaryOthers, setQuickComplementaryOthers] = useState([]);

  function applyOnExterieurTab(e, itemId) {
    setQuickComplementaryExterieur(
      getNewTab(itemId, quickComplementaryExterieur)
    );
    e.stopPropagation();
  }
  function applyOnInterieurTab(e, itemId) {
    setQuickComplementaryInterieur(
      getNewTab(itemId, quickComplementaryInterieur)
    );
    e.stopPropagation();
  }
  function applyOnSecurityTab(e, itemId) {
    setQuickComplementarySecurity(
      getNewTab(itemId, quickComplementarySecurity)
    );
    e.stopPropagation();
  }
  function applyOnOthersTab(e, itemId) {
    setQuickComplementaryOthers(getNewTab(itemId, quickComplementaryOthers));
    e.stopPropagation();
  }

  async function getComplementaryValue() {
    const quickExterieur = await decryptData(
      carUserCurrentEdit.exteriorsTabObjectValue
    );
    const quickInterieur = await decryptData(
      carUserCurrentEdit.interiorsTabObjectValue
    );
    const quickSecurity = await decryptData(
      carUserCurrentEdit.securityTabObjectValue
    );
    const quickOthers = await decryptData(
      carUserCurrentEdit.othersTabObjectValue
    );
    setQuickComplementaryExterieur(quickExterieur);
    setQuickComplementaryInterieur(quickInterieur);
    setQuickComplementarySecurity(quickSecurity);
    setQuickComplementaryOthers(quickOthers);
  }
  useEffect(() => {
    if (carUserCurrentEdit === undefined || carUserCurrentEdit === null)
      return undefined;
    getComplementaryValue();
  }, [carUserCurrentEdit]);

  return (
    <div className="profil-page">
      <Header />
      <ReturnTop top={0} detect={600} />
      <div className="contains-profil-page flex row max-width center-margin">
        <div
          style={{
            bottom: amBottom && distanceCalcul,
            top: amBottom && "auto",
          }}
          id="selectProfilPannel"
          className="btn-contains-switch flex colm"
        >
          <button
            onClick={() => handleSelectMenu(1)}
            className={
              currentMenu === 1
                ? "is-active hover-btn flex row"
                : "hover-btn flex row"
            }
          >
            <img src={profilCircle} alt="account-icon" />
            <span>Mon compte</span>
          </button>
          <button
            onClick={() => handleSelectMenu(2)}
            id="titlecurrent"
            className={
              currentMenu === 2
                ? "is-active hover-btn flex row "
                : "hover-btn flex row "
            }
          >
            <img src={carIcon} alt="account-icon" />
            <span>Mon garage</span>
          </button>
          <button
            onClick={() => handleSelectMenu(3)}
            className={
              currentMenu === 3
                ? "is-active hover-btn flex row"
                : "hover-btn flex row"
            }
          >
            <img src={heartIcon} alt="account-icon" />
            <span>Favoris</span>
          </button>
          <button
            onClick={() => handleSelectMenu(4)}
            className={
              currentMenu === 4
                ? "is-active hover-btn flex row"
                : "hover-btn flex row"
            }
          >
            <img src={clockIcon} alt="account-icon" />
            <span>Récemment consultés</span>
          </button>
          <button
            onClick={() => handleSelectMenu(5)}
            className={
              currentMenu === 5
                ? "is-active hover-btn flex row"
                : "hover-btn flex row"
            }
          >
            <img src={smsIcon} alt="account-icon" />
            <span>Messages et notifications</span>
          </button>
        </div>
        <div
          ref={targetRef}
          className="contains-page-select flex colm max-width center-margin"
        >
          {currentMenu === 1 && carUserCurrentEdit === null ? (
            <>
              <h3
                className={
                  (isVisibleAllCard === true
                    ? "heading-title "
                    : "no-visible heading-title ") +
                  (amBottomTitle ? "am-bottom-title" : "")
                }
              >
                Paramètre du compte
              </h3>
              <div className="flex colm account-setting">
                <div className="general-info flex colm">
                  <div className="title-lead">
                    <p className="tit">Informations générales</p>
                  </div>
                  <div className="normal">
                    <div className="flex row line">
                      <p className="tit">Nom et prénoms:</p>
                      <span>{userName}</span>
                    </div>
                  </div>
                  <div className="actions">
                    <div className="flex row liner btw">
                      <div className="left flex row liner addgap2">
                        <div className="part1 flex row liner addgap">
                          <p className="tit">Adresse mail:</p>
                          <div
                            style={{
                              pointerEvents: userSocial === "" ? "" : "none",
                            }}
                            onClick={() => handleOpenModal(1)}
                            className="hover-btn flex row liner addgap8"
                          >
                            <span>{userEmail}</span>
                            {userSocial === "" && (
                              <img
                                className="arrowiconMobile"
                                src={arrowIconPMobile}
                                alt="arrowIconM"
                              />
                            )}
                          </div>
                        </div>
                        {userSocial !== "" && (
                          <button
                            style={{
                              background: "var(--secondary-500)",
                              color: "var(--white-500)",
                            }}
                            className="always-visible-profil checked-btn"
                          >
                            {userSocial === "Google" ? "google" : "fb"}
                          </button>
                        )}
                        <button
                          style={{
                            background:
                              userSocial !== "" ? "var(--secondary-500)" : "",
                            color: userSocial !== "" ? "var(--white-500)" : "",
                          }}
                          className={
                            verifiedAccount === ""
                              ? "no-checked-btn hover-btn"
                              : "checked-btn hover-btn"
                          }
                        >
                          {userSocial === ""
                            ? verifiedAccount === ""
                              ? "Non vérifier"
                              : "Vérifier"
                            : "Compte lié à " + userSocial}
                        </button>
                      </div>
                      {userSocial === "" && (
                        <button
                          onClick={() => handleOpenModal(1)}
                          className="modif-value hover-btn"
                        >
                          Modifier
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="actions">
                    <div className="flex row liner btw">
                      <div className="left flex row liner addgap2">
                        <div className="part1 flex row liner addgap">
                          <p className="tit">Numero de téléphone:</p>
                          <div
                            onClick={() => handleOpenModal(2)}
                            className="hover-btn flex row liner addgap8"
                          >
                            {userTel !== "" && <span>{userTel}</span>}
                            <img
                              className="arrowiconMobile"
                              src={arrowIconPMobile}
                              alt="arrowIconM"
                            />
                          </div>
                        </div>
                        {/* <button className="no-checked-btn hover-btn">
                          Non vérifier
                        </button> */}
                      </div>

                      <button
                        onClick={() => handleOpenModal(2)}
                        className="modif-value hover-btn"
                      >
                        {userTel === "" ? "Ajouter" : "Modifier"}
                      </button>
                    </div>
                  </div>
                  {userSocial === "" && (
                    <div className="actions password-general">
                      <div className="flex row liner btw">
                        <div className="left flex row liner addgap2">
                          <div className="part1 flex row liner addgap">
                            <p className="tit">Mot de passe:</p>
                            <span className="bolder">•••••••••••••••••••</span>
                          </div>
                        </div>
                        <button
                          onClick={() => handleOpenModal(3)}
                          className="modif-value hover-btn"
                        >
                          Modifier
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="vendor-info flex colm">
                  <div className="title-lead">
                    <p className="tit">Informations vendeurs</p>
                  </div>
                  <div className="piece">
                    <div className="flex row btw line">
                      <label
                        onClick={() => setAddCniModal(true)}
                        className="input-file hover-btn"
                      >
                        Téléverser votre pièce d'identité
                      </label>
                      <img src={arrowIcon} alt="arrow-icon" />
                    </div>
                  </div>
                  <div className="actions">
                    <div className="flex row liner btw">
                      <div className="left flex row liner addgap2">
                        <div className="part1 flex row liner addgap">
                          <p className="tit">Lieu d'habitation:</p>
                          <div
                            onClick={() => handleOpenModal(4)}
                            className="hover-btn flex row liner addgap8"
                          >
                            {userHabit !== "" && <span>{userHabit}</span>}
                            <img
                              className="arrowiconMobile"
                              src={arrowIconPMobile}
                              alt="arrowIconM"
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={() => handleOpenModal(4)}
                        className="modif-value hover-btn"
                      >
                        {userHabit === "" ? "Ajouter" : "Modifier"}
                      </button>
                    </div>
                  </div>
                  <div className="actions">
                    <div className="flex row liner btw">
                      <div className="left flex row liner addgap2">
                        <div className="part1 flex row liner addgap">
                          <p className="tit">Statut Vendeur:</p>
                          <div
                            onClick={() => handleOpenModal(4)}
                            className="hover-btn flex row liner btw addgap8"
                          >
                            {dataUser &&
                            dataUser.profile.toLowerCase() === "seller" ? (
                              <span>Actif</span>
                            ) : (
                              <>
                                <p style={{ width: "100px", margin: 0 }}>
                                  <span>Non actif</span>
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                        {dataUser &&
                        dataUser.profile.toLowerCase() === "seller" ? (
                          <span></span>
                        ) : (
                          <>
                            <p style={{ margin: 0 }}>
                              <span></span>
                            </p>
                            {dataUser.checkVendor ? (
                              <button
                                style={{ width: "180px", display: "block" }}
                                className="bolder do-ask-vendor hover-btn"
                                disabled
                              >
                                En cours de validation
                              </button>
                            ) : (
                              <button
                                onClick={activeAskVendorPanel}
                                style={{ marginLeft: "auto" }}
                                className="bolder do-ask-vendor hover-btn"
                              >
                                Effectuer une demande
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mobile-pass-info flex colm">
                  {dataUser &&
                    dataUser.checkVendor === false &&
                    dataUser.profile.toLowerCase() !== "seller" && (
                      <button
                        onClick={activeAskVendorPanel}
                        className="hover-btn actions"
                      >
                        <div className="part1 flex row liner addgap8">
                          <img src={carIconS1} alt="car-icon" />
                          Faire une demande et être vendeur
                        </div>
                      </button>
                    )}
                  {userSocial === "" && (
                    <button
                      onClick={() => handleOpenModal(3)}
                      className="hover-btn actions"
                    >
                      <div className="part1 flex row liner addgap8">
                        <img src={lockP} alt="lock-icon" />
                        Changer le mot de passe
                      </div>
                    </button>
                  )}

                  <button
                    onClick={disconnectedMe}
                    className="hover-btn actions"
                  >
                    <div className="part1 flex row liner addgap8">
                      <img src={logoutP} alt="logout-icon" />
                      Déconnexion
                    </div>
                  </button>
                </div>
                <div
                  style={{ display: "none" }}
                  className="check-numero flex colm"
                >
                  <p>
                    Rentrez le code OTP reçu par sms pour vérifier votre numéro
                    de téléphone.
                  </p>
                  <div className="flex colm input-checker">
                    <input type="text" placeholder="" />
                    <button className="bolder hover-btn">Verifier</button>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {currentMenu === 2 && carUserCurrentEdit === null ? (
            <>
              <div
                className={
                  (isVisibleAllCard === true
                    ? "flex row heading liner btw "
                    : "no-visible-header flex row heading liner btw ") +
                  (amBottomTitle ? "am-bottom-title" : "")
                }
              >
                <h3
                  className={
                    isVisibleAllCard === true
                      ? "heading-title "
                      : "no-visible heading-title "
                  }
                >
                  Mon garage
                </h3>
                <Link
                  className="hover-btn liner flex row"
                  onClick={() => redirectToURL("/vendre")}
                >
                  <img src={moreIcon} alt="more-icon" />
                  Ajouter un véhicule
                </Link>
              </div>
              <div
                className={
                  sellerCars.length === 0
                    ? "flex colm my-garage favoris-page "
                    : "flex colm favoris-page my-garage contains-some contains-some-cars"
                }
              >
                {sellerCars.length === 0 ? (
                  <div className="no-garage flex colm liner">
                    <img src={imgIllustration} alt="illustration" />
                    <div className="flex colm liner">
                      <h5 className="bolder">Votre garage est vide</h5>

                      <p>
                        Completez votre profil, ajoutez à votre garage les
                        voitures que vous possédez et suivez leur valeur
                        marchande au fil du temps.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="favoris-contains">
                    {sellerCars.map((cars) => {
                      return (
                        <CarCard
                          car={{ ...cars }}
                          updateFavoris={updateFavoris}
                          dataFav={currentFav}
                          setMyUserEditPage={setMyUserEditPage}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          ) : null}
          {currentMenu === 3 && carUserCurrentEdit === null ? (
            <>
              <h3
                className={
                  (isVisibleAllCard === true
                    ? "heading-title "
                    : "no-visible heading-title ") +
                  (amBottomTitle ? "am-bottom-title" : "")
                }
              >
                Mes favoris
              </h3>
              <div
                className={
                  currentFavCar.length === 0
                    ? "flex colm favoris-page"
                    : "flex colm favoris-page contains-some contains-some-favoris"
                }
              >
                {currentFavCar.length === 0 ? (
                  <div className="no-garage flex colm liner">
                    <img src={imgIllustration2} alt="illustration" />
                    <div className="flex colm liner">
                      <h5 className="bolder">Vous n’avez pas de favoris</h5>
                    </div>
                  </div>
                ) : (
                  <div className="favoris-contains">
                    {currentFavCar.map((car, count) => {
                      return (
                        <div key={car.id}>
                          <CarCard
                            updateFavoris={updateFavoris}
                            dataFav={currentFav}
                            car={{ ...car, idswip: count }}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          ) : null}
          {currentMenu === 4 && carUserCurrentEdit === null ? (
            <>
              <h3
                className={
                  (isVisibleAllCard === true
                    ? "heading-title "
                    : "no-visible heading-title ") +
                  (amBottomTitle ? "am-bottom-title" : "")
                }
              >
                Véhicules récemment consultés
              </h3>
              <div
                className={
                  lastAddCars.length === 0
                    ? "flex colm favoris-page"
                    : "flex colm favoris-page contains-some contains-some-favoris"
                }
              >
                {lastAddCars.length === 0 ? (
                  <div className="no-garage flex colm liner">
                    <img src={imgIllustration2} alt="illustration" />
                    <div className="flex colm liner">
                      <h5 className="bolder">
                        Aucune voiture n'a été consulté.
                      </h5>
                      <Link
                        onClick={() => {
                          dispatch(setCarBrand([]));
                          redirectToURL("/acheter");
                        }}
                      >
                        Lancez une nouvelle recherche maintenant.
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div className="favoris-contains">
                    {lastAddCars.map((car, count) => {
                      return (
                        <CarCard
                          updateFavoris={updateFavoris}
                          dataFav={currentFav}
                          car={{ ...car.car, idswip: count }}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          ) : null}
          {currentMenu === 5 && carUserCurrentEdit === null ? (
            <>
              <h3
                className={
                  (isVisibleAllCard === true
                    ? "heading-title "
                    : "no-visible heading-title ") +
                  (amBottomTitle ? "am-bottom-title" : "")
                }
              >
                Messages et notifications
              </h3>
              <div
                className={
                  messagesNotif.length === 0
                    ? "flex colm favoris-page message-contains-panel"
                    : "flex colm favoris-page contains-some message-contains-panel "
                }
              >
                {notifInLoading && (
                  <div className="no-garage flex colm liner">
                    <div className="flex colm liner">
                      <div className="no-find-car loader-coming max-width center-margin flex">
                        <div class="lds-ripple">
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!notifInLoading && messagesNotif.length === 0 ? (
                  <div className="no-garage flex colm liner">
                    <div className="flex colm liner">
                      <h5 className="bolder">
                        Aucun messages ou notifications
                      </h5>
                    </div>
                  </div>
                ) : (
                  <div className="favoris-contains messages-notif-contains">
                    {messagesNotif.map((notif, count) => {
                      return (
                        <div
                          key={"notif-" + count + ":" + notif.id}
                          className={
                            notif.open === false
                              ? "notif-row flex row addgap8 liner not-seen"
                              : "notif-row flex row addgap8 liner "
                          }
                        >
                          <img src={autoSimpleIcon} alt="auto-plusIcon" />
                          <div className="content-wrapper">
                            <button
                              onClick={() => viewNotif(notif)}
                              className="hover-btn btn-open-modal flex colm"
                            >
                              <h5>{notif.title}</h5>
                              <span className="first-info-txt">
                                {notif.text}
                              </span>
                              <span className="date">
                                {formattedDate(notif.createdAt)}
                              </span>
                            </button>
                          </div>
                          <div className="flex row addgap8 btn-action-direct">
                            <button
                              style={{
                                opacity: notif.open === true ? "0.3" : "1",
                              }}
                              disabled={notif.open}
                              onClick={() => switchStateNotif(notif)}
                              className="view hover-btn"
                            >
                              <img src={eyeSlashIconModal} alt="view-icon" />
                            </button>
                            <button
                              onClick={() => deleteNotif(notif)}
                              className="view hover-btn"
                            >
                              <img src={trashIconM} alt="delete-icon" />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          ) : null}
          {currentMenu === 2 && carUserCurrentEdit !== null && (
            <>
              <div
                className={
                  (isVisibleAllCard === true
                    ? "flex row heading liner btw"
                    : "no-visible-header flex row heading liner btw") +
                  (amBottomTitle ? "am-bottom-title" : "")
                }
              >
                <h3
                  className={
                    isVisibleAllCard === true
                      ? "heading-title "
                      : "no-visible heading-title "
                  }
                >
                  Informations du véhicule
                </h3>
                <Link
                  className="hover-btn delete-car liner flex row"
                  onClick={deleteVehicule}
                >
                  <img src={iconTrash} alt="trash-icon" />
                  Supprimer
                </Link>
              </div>
              <div className="flex colm my-garage favoris-page info-vehicule-profil">
                <QuickSelectedInput
                  title={"marquevehicule"}
                  inputPlaceholder={"Marque du véhicule"}
                  setInputVal={setCurrMarqueValue}
                  myAllPotentialData={allBrands}
                  iconImg={marqueIcon}
                  arrowImg={null}
                  maxheight="180px"
                  preloadValue={carUserCurrentEdit.brandName}
                />
                <QuickSelectedInput
                  title={"modelevehicule"}
                  inputPlaceholder={"Modèle du véhicule"}
                  setInputVal={setCurrModelValue}
                  myAllPotentialData={[]}
                  iconImg={wingIcon}
                  arrowImg={null}
                  preloadValue={carUserCurrentEdit.model}
                />
                <QuickSelectedInput
                  title={"calendar"}
                  inputPlaceholder={"Année du véhicule"}
                  setInputVal={setCurrentYearValue}
                  myAllPotentialData={lastYears}
                  iconImg={calendarIcon}
                  arrowImg={null}
                  inputType="number"
                  onlybinarysearch={true}
                  maxheight="230px"
                  preloadValue={carUserCurrentEdit.year || ""}
                />
                <QuickSelectedInput
                  title={"oiltype"}
                  inputPlaceholder={"Type de carburant"}
                  setInputVal={setCurrOilTypeValue}
                  myAllPotentialData={currentOilType}
                  iconImg={gassIcon}
                  arrowImg={arrowIconS}
                  maxheight="180px"
                  onlyselectInput={true}
                  preloadValue={carUserCurrentEdit.motorType.name || ""}
                />
                <QuickSelectedInput
                  title={"cartype"}
                  inputPlaceholder={"Type de véhicule"}
                  setInputVal={setCurrCartypeValue}
                  myAllPotentialData={allCarsType}
                  iconImg={carTypeIcon}
                  arrowImg={arrowIconS}
                  maxheight="180px"
                  onlyselectInput={true}
                  preloadValue={carUserCurrentEdit.carType.name || ""}
                />
                <QuickSelectedInput
                  title={"kilometer"}
                  inputPlaceholder={"Rendre le kilometrage"}
                  setInputVal={setKilometerValue}
                  myAllPotentialData={[]}
                  iconImg={kilometerIcon}
                  inputType="number"
                  arrowImg={null}
                  preloadValue={carUserCurrentEdit.kilometers || "0"}
                />
                <QuickSelectedInput
                  title={"transmission"}
                  inputPlaceholder={"Type de transmission"}
                  setInputVal={setCurrTransmissionValue}
                  myAllPotentialData={currentTransmission}
                  iconImg={gearShiftIcon}
                  arrowImg={arrowIconS}
                  onlyselectInput={true}
                  preloadValue={carUserCurrentEdit.transmission.name || ""}
                />
                <QuickSelectedInput
                  title={"condition"}
                  inputPlaceholder={"Condition du véhicule"}
                  setInputVal={setCurrConditionValue}
                  myAllPotentialData={currentCondition}
                  iconImg={wrenchIcon}
                  arrowImg={arrowIconS}
                  maxheight="180px"
                  onlyselectInput={true}
                  preloadValue={
                    (carUserCurrentEdit.carCondition &&
                      carUserCurrentEdit.carCondition.name) ||
                    ""
                  }
                />
                <QuickSelectedInput
                  title={"price"}
                  inputPlaceholder={"Votre prix de vente"}
                  setInputVal={setCurrPriceValue}
                  myAllPotentialData={[]}
                  iconImg={coinIcon}
                  arrowImg={null}
                  inputType="number"
                  preloadValue={carUserCurrentEdit.price || ""}
                />
                <QuickSelectedInput
                  title={"doors"}
                  inputPlaceholder={"Nombre de portes"}
                  setInputVal={setCurrDoorValue}
                  myAllPotentialData={[]}
                  iconImg={doorIcon}
                  arrowImg={null}
                  inputType="number"
                  preloadValue={carUserCurrentEdit.doors || ""}
                />
                <QuickSelectedInput
                  title={"places"}
                  inputPlaceholder={"Nombre de places"}
                  setInputVal={setCurrPlaces}
                  myAllPotentialData={[]}
                  iconImg={seatIcon}
                  arrowImg={null}
                  inputType="number"
                  preloadValue={carUserCurrentEdit.places || ""}
                />
                <div className="date-added flex liner">
                  {" "}
                  <p> Ajouté le : {dateShow}</p>
                </div>
                <textarea
                  className="description-vehicule"
                  ref={refTextDescrypt}
                  onChange={(e) => setCurrDescription(e.target.value)}
                  // value={carUserCurrentEdit.slug || ""}
                  placeholder="description du vehicule"
                />
                <div className="quick-sell import-quick-sell flex colm photos-vehicule">
                  <div className="label-title-sale flex colm">
                    <h3>Photos du véhicule</h3>
                    <span>Importer une ou plusieurs images du véhicule.</span>
                  </div>
                  <form className="form-send flex row addgap8">
                    <div className="clipped-swipper-scroll">
                      <Swiper
                        modules={[FreeMode, Navigation]}
                        spaceBetween={10}
                        slidesPerView={"auto"}
                        freeMode={true}
                        navigation={true}
                        className="swipper-import-img"
                      >
                        {preloadImgCar.map((image, index) => (
                          <SwiperSlide
                            key={`import-preload-img${index}`}
                            className="one-img"
                          >
                            <div key={index} className="image-button">
                              <img src={image} alt={`Image-preload ${index}`} />
                              <button
                                type="button"
                                className="flex liner"
                                onClick={() => deletedOlderImage(image)}
                              >
                                <img src={trashIcon} alt="trash-icon" />
                              </button>
                            </div>
                          </SwiperSlide>
                        ))}
                        {selectedImages.map((image, index) => (
                          <SwiperSlide
                            key={`import-img${index}`}
                            className="one-img"
                          >
                            <div key={index} className="image-button">
                              <img
                                src={URL.createObjectURL(image)}
                                alt={`Image ${index}`}
                              />
                              <button
                                type="button"
                                className="flex liner"
                                onClick={() => handleRemoveImage(index)}
                              >
                                <img src={trashIcon} alt="trash-icon" />
                              </button>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>

                    <div className="import-image flex row liner">
                      <input
                        className="input-file-photo"
                        type="file"
                        multiple
                        onChange={handleImageChange}
                        accept=".jpg, .png"
                      />
                      <img src={arrowTil} alt="arrow-t" />
                    </div>
                  </form>
                </div>

                <div className="flex row complementary-form form-in-profil">
                  {quickComplementaryExterieur.length > 0 && (
                    <>
                      <h4 style={{ width: "100%" }}>Extérieur et Chassis</h4>
                      {quickComplementaryExterieur.map((item, count) => (
                        <div
                          onClick={(e) => applyOnExterieurTab(e, item.id)}
                          key={`${count + "exte" + item.id}`}
                          className="flex row liner btw addgap8 | quick-selector-complementary"
                        >
                          <SelectComplementary
                            text={item.text}
                            mineId={item.id}
                            value={item.active}
                            allValue={quickComplementaryExterieur}
                            setValueTab={setQuickComplementaryExterieur}
                          />
                        </div>
                      ))}
                    </>
                  )}

                  {quickComplementaryInterieur.length > 0 && (
                    <>
                      <h4 style={{ width: "100%" }}>Intérieur</h4>
                      {quickComplementaryInterieur.map((item, count) => (
                        <div
                          onClick={(e) => applyOnInterieurTab(e, item.id)}
                          key={`${count + "exte" + item.id}`}
                          className="flex row liner btw addgap8 | quick-selector-complementary"
                        >
                          <SelectComplementary
                            text={item.text}
                            mineId={item.id}
                            value={item.active}
                            allValue={quickComplementaryInterieur}
                            setValueTab={setQuickComplementaryInterieur}
                          />
                        </div>
                      ))}
                    </>
                  )}

                  {quickComplementarySecurity.length > 0 && (
                    <>
                      <h4 style={{ width: "100%" }}>Sécurité </h4>
                      {quickComplementarySecurity.map((item, count) => (
                        <div
                          onClick={(e) => applyOnSecurityTab(e, item.id)}
                          key={`${count + "exte" + item.id}`}
                          className="flex row liner btw addgap8 | quick-selector-complementary"
                        >
                          <SelectComplementary
                            text={item.text}
                            mineId={item.id}
                            value={item.active}
                            allValue={quickComplementarySecurity}
                            setValueTab={setQuickComplementarySecurity}
                          />
                        </div>
                      ))}
                    </>
                  )}

                  {quickComplementaryOthers.length > 0 && (
                    <>
                      <h4 style={{ width: "100%" }}>Autre</h4>
                      {quickComplementaryOthers.map((item, count) => (
                        <div
                          onClick={(e) => applyOnOthersTab(e, item.id)}
                          key={`${count + "exte" + item.id}`}
                          className="flex row liner btw addgap8 | quick-selector-complementary"
                        >
                          <SelectComplementary
                            text={item.text}
                            mineId={item.id}
                            value={item.active}
                            allValue={quickComplementaryOthers}
                            setValueTab={setQuickComplementaryOthers}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <div className="flex row btn-actions ">
                  <button
                    className="hover-btn return-btn"
                    disabled={!canGoPatchedVehucule}
                    onClick={validatePatchCars}
                    type="button"
                  >
                    Valider
                  </button>
                  <button
                    onClick={cancelHandleEditCar}
                    className="confirm-btn hover-btn"
                    type="button"
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        {loadPatchCar && (
          <div className="load-patch-data flex">
            <div className="no-find-car loader-coming max-width center-margin flex">
              <div class="lds-ripple">
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        )}
        {currentMenu === 1 && currentModalOpen === 3 && (
          <div
            className={
              isLoading ? "modal-patch-user loading-modal" : "modal-patch-user"
            }
          >
            <div className="background-patch flex">
              <form className="flex colm">
                <div className="title-header flex-colm">
                  <h5>Modifier votre mot de passe</h5>
                </div>
                <div className="input-btn-md flex row addgap8 liner">
                  <img src={lockIconModal} alt="icon-pass" />
                  <input
                    disabled={isLoading}
                    onChange={(e) => setOldPass(e.target.value)}
                    type={showSecondMdp ? "text" : "password"}
                    placeholder="Entrez votre mot de passe actuel"
                  />
                  <button
                    type="button"
                    onClick={handleChangeSecondShowMdp}
                    className={
                      showSecondMdp === true
                        ? "slashed flex liner hover-btn"
                        : "see-me flex liner hover-btn"
                    }
                  >
                    <img src={eyeSlashIconModal} alt="eye-slage-icon" />
                  </button>
                </div>
                <div className="input-btn-md flex row addgap8 liner">
                  <img src={lockIconModal} alt="icon-pass" />
                  <input
                    disabled={isLoading}
                    onChange={(e) => setNewPass(e.target.value)}
                    type={showMdp ? "text" : "password"}
                    placeholder="Entrez votre nouveau mot de passe"
                  />
                  <button
                    type="button"
                    onClick={handleChangeShowMdp}
                    className={
                      showMdp === true
                        ? "slashed flex liner hover-btn"
                        : "see-me flex liner hover-btn"
                    }
                  >
                    <img src={eyeSlashIconModal} alt="eye-slage-icon" />
                  </button>
                </div>
                <div className="btn-actions-modal flex row addgap8">
                  <button
                    disabled={isLoading}
                    type="button"
                    onClick={(e) => handleCloseModal(e)}
                    className="hover-btn"
                  >
                    Annuler
                  </button>
                  <button
                    disabled={!(!isLoading && validateModal)}
                    type="button"
                    onClick={(e) => handleValidateInfo(e)}
                    className="hover-btn"
                  >
                    Valider
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {currentMenu === 1 && currentModalOpen === 2 && (
          <div
            className={
              isLoading ? "modal-patch-user loading-modal" : "modal-patch-user"
            }
          >
            <div className="background-patch flex">
              <form className="flex colm">
                <div className="title-header flex-colm">
                  <h5>Modifier votre numéro de téléphone</h5>
                </div>
                <div className="input-btn-md flex row addgap8 liner">
                  <img src={callIconModal} alt="icon-tel" />
                  <input
                    disabled={isLoading}
                    onChange={(e) => setTelInput(e.target.value)}
                    type="tel"
                    placeholder="Entrez votre nouveau numero"
                  />
                </div>
                {userSocial === "" && (
                  <div className="input-btn-md flex row addgap8 liner">
                    <img src={lockIconModal} alt="icon-pass" />
                    <input
                      disabled={isLoading}
                      onChange={(e) => setOldPass(e.target.value)}
                      type={showMdp ? "text" : "password"}
                      placeholder="Entrez votre mot de passe"
                    />
                    <button
                      type="button"
                      onClick={handleChangeShowMdp}
                      className={
                        showMdp === true
                          ? "slashed flex liner hover-btn"
                          : "see-me flex liner hover-btn"
                      }
                    >
                      <img src={eyeSlashIconModal} alt="eye-slage-icon" />
                    </button>
                  </div>
                )}
                <div className="btn-actions-modal flex row addgap8">
                  <button
                    disabled={isLoading}
                    type="button"
                    onClick={(e) => handleCloseModal(e)}
                    className="hover-btn"
                  >
                    Annuler
                  </button>
                  <button
                    disabled={!(!isLoading && validateModal)}
                    type="button"
                    onClick={(e) => handleValidateInfo(e)}
                    className="hover-btn"
                  >
                    Valider
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {currentMenu === 1 && currentModalOpen === 1 && (
          <div
            className={
              isLoading ? "modal-patch-user loading-modal" : "modal-patch-user"
            }
          >
            <div className="background-patch flex">
              <form className="flex colm">
                <div className="title-header flex-colm">
                  <h5>Modifier votre email</h5>
                </div>
                <div className="input-btn-md flex row addgap8 liner">
                  <img src={smsIconModal} alt="icon-tel" />
                  <input
                    disabled={isLoading}
                    onChange={(e) => setEmailInput(e.target.value)}
                    type="email"
                    placeholder="Entrez votre nouvel email"
                  />
                </div>
                <div className="input-btn-md flex row addgap8 liner">
                  <img src={lockIconModal} alt="icon-pass" />
                  <input
                    disabled={isLoading}
                    onChange={(e) => setOldPass(e.target.value)}
                    type={showMdp ? "text" : "password"}
                    placeholder="Entrez votre mot de passe"
                  />
                  <button
                    type="button"
                    onClick={handleChangeShowMdp}
                    className={
                      showMdp === true
                        ? "slashed flex liner hover-btn"
                        : "see-me flex liner hover-btn"
                    }
                  >
                    <img src={eyeSlashIconModal} alt="eye-slage-icon" />
                  </button>
                </div>
                <div className="btn-actions-modal flex row addgap8">
                  <button
                    disabled={isLoading}
                    type="button"
                    onClick={(e) => handleCloseModal(e)}
                    className="hover-btn"
                  >
                    Annuler
                  </button>
                  <button
                    disabled={!(!isLoading && validateModal)}
                    type="button"
                    onClick={(e) => handleValidateInfo(e)}
                    className="hover-btn"
                  >
                    Valider
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {currentMenu === 1 && currentModalOpen === 4 && (
          <div
            className={
              isLoading ? "modal-patch-user loading-modal" : "modal-patch-user"
            }
          >
            <div className="background-patch flex">
              <form className="flex colm">
                <div className="title-header flex-colm">
                  <h5>Modifier votre lieu d'habitation</h5>
                </div>
                <div className="input-btn-md flex row addgap8 liner">
                  <img src={pinIconModal} alt="ping-tel" />
                  <input
                    disabled={isLoading}
                    onChange={(e) => setHabitationInput(e.target.value)}
                    type="text"
                    placeholder="Entrez votre nouveau lieu d'habitation"
                  />
                </div>
                {userSocial === "" && (
                  <div className="input-btn-md flex row addgap8 liner">
                    <img src={lockIconModal} alt="icon-pass" />
                    <input
                      disabled={isLoading}
                      onChange={(e) => setOldPass(e.target.value)}
                      type={showMdp ? "text" : "password"}
                      placeholder="Entrez votre mot de passe"
                    />
                    <button
                      type="button"
                      onClick={handleChangeShowMdp}
                      className={
                        showMdp === true
                          ? "slashed flex liner hover-btn"
                          : "see-me flex liner hover-btn"
                      }
                    >
                      <img src={eyeSlashIconModal} alt="eye-slage-icon" />
                    </button>
                  </div>
                )}

                <div className="btn-actions-modal flex row addgap8">
                  <button
                    disabled={isLoading}
                    type="button"
                    onClick={(e) => handleCloseModal(e)}
                    className="hover-btn"
                  >
                    Annuler
                  </button>
                  <button
                    disabled={!(!isLoading && validateModal)}
                    type="button"
                    onClick={(e) => handleValidateInfo(e)}
                    className="hover-btn"
                  >
                    Valider
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {carUserCurrentEdit !== null && suppressCar === true && (
          <div
            className={
              isLoading ? "modal-patch-user loading-modal" : "modal-patch-user"
            }
          >
            <div className="background-patch flex">
              <form className="flex colm">
                <div className="title-header flex-colm">
                  <h5 style={{ lineHeight: "22px" }}>
                    Voulez-vous vraiment supprimer la{" "}
                    {" " +
                      carUserCurrentEdit.brandName +
                      " " +
                      carUserCurrentEdit.model}
                  </h5>
                </div>

                <div className="btn-actions-modal flex row addgap8">
                  <button
                    style={{ minWidth: "70px" }}
                    type="button"
                    onClick={() => setSuppressCar(false)}
                    className="hover-btn"
                  >
                    Non
                  </button>
                  <button
                    style={{ minWidth: "70px" }}
                    type="button"
                    onClick={deleteVehiculeNow}
                    className="hover-btn"
                  >
                    Oui
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {openDeleteNotif !== null && (
          <div
            className={
              isLoading ? "modal-patch-user loading-modal" : "modal-patch-user"
            }
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                setOpenDeletePanelNotif(null);
              }}
              className="background-patch flex"
            >
              <form className="flex colm">
                <div className="title-header flex-colm">
                  <h5 style={{ lineHeight: "22px" }}>
                    Voulez-vous vraiment supprimer cette notification
                  </h5>
                </div>

                <div className="btn-actions-modal flex row addgap8">
                  <button
                    style={{ minWidth: "70px" }}
                    type="button"
                    onClick={() => setOpenDeletePanelNotif(null)}
                    className="hover-btn"
                  >
                    Non
                  </button>
                  <button
                    style={{ minWidth: "70px" }}
                    type="button"
                    onClick={suppressNotif}
                    className="hover-btn"
                  >
                    Oui
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {openSendVendor && (
          <div
            className={
              isLoading ? "modal-patch-user loading-modal" : "modal-patch-user"
            }
          >
            <div className="background-patch flex">
              <form className="flex colm">
                <div className="title-header flex-colm">
                  <h5 style={{ lineHeight: "22px" }}>
                    Voulez-vous effectuer vôtre demande afin d'être un vendeur ?
                  </h5>
                </div>
                {userSocial === "" && (
                  <div className="input-btn-md flex row addgap8 liner">
                    <img src={lockIconModal} alt="icon-pass" />
                    <input
                      onChange={(e) => setOldPass(e.target.value)}
                      type={showSecondMdp ? "text" : "password"}
                      placeholder="Entrez votre mot de passe actuel"
                    />
                    <button
                      type="button"
                      onClick={handleChangeSecondShowMdp}
                      className={
                        showSecondMdp === true
                          ? "slashed flex liner hover-btn"
                          : "see-me flex liner hover-btn"
                      }
                    >
                      <img src={eyeSlashIconModal} alt="eye-slage-icon" />
                    </button>
                  </div>
                )}

                <div className="btn-actions-modal flex row addgap8">
                  <button
                    style={{ minWidth: "70px" }}
                    type="button"
                    onClick={() => setOpenSendVendor(false)}
                    className="hover-btn"
                  >
                    Non
                  </button>
                  <button
                    disabled={
                      userSocial === ""
                        ? oldpass.length < 6
                          ? true
                          : false
                        : false
                    }
                    style={{ minWidth: "70px" }}
                    type="button"
                    onClick={sendGoVendor}
                    className="hover-btn"
                  >
                    Oui
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {addCniModal && (
          <div
            className={
              isLoading ? "modal-patch-user loading-modal" : "modal-patch-user"
            }
          >
            <div className="background-patch flex">
              <form className="flex colm">
                {dataUser.checkVendor === true ||
                dataUser.profile.toLowerCase() === "seller" ? (
                  <div className="title-header flex-colm">
                    {dataUser.profile.toLowerCase() === "seller" ? (
                      <h5 style={{ lineHeight: "22px" }}>
                        Vous êtes un vendeur !
                      </h5>
                    ) : (
                      <h5 style={{ lineHeight: "22px" }}>
                        Une demande est en cours avec cette pièce !
                      </h5>
                    )}

                    <span>
                      Impossible de modifier la pièce utiliser lors de la
                      demande
                    </span>
                  </div>
                ) : (
                  <div className="title-header flex-colm">
                    <h5 style={{ lineHeight: "22px" }}>
                      Téléverser le recto de vôtre pièce d'identité ?
                    </h5>
                    <span>Soyez dans un endroit eclairé</span>
                  </div>
                )}

                <div className="flex cni-upload">
                  {showCNILink}
                  <label className="input-file hover-btn">
                    {cniUserImage &&
                    dataUser.profile.toLowerCase() === "buyer" ? (
                      dataUser.checkVendor === false && <span>Modifier</span>
                    ) : (
                      <span>Téléverser</span>
                    )}
                    <input
                      style={{ visibility: "hidden", display: "none" }}
                      type="file"
                      disabled={
                        dataUser.profile.toLowerCase() === "seller" ||
                        dataUser.checkVendor === true
                      }
                      onChange={handleAddCni}
                      accept=".jpg, .png"
                    />
                  </label>
                </div>

                {userSocial === "" &&
                  dataUser.profile.toLowerCase() !== "seller" &&
                  dataUser.checkVendor === false && (
                    <div className="input-btn-md flex row addgap8 liner">
                      <img src={lockIconModal} alt="icon-pass" />
                      <input
                        onChange={(e) => setOldPass(e.target.value)}
                        type={showSecondMdp ? "text" : "password"}
                        placeholder="Entrez votre mot de passe actuel"
                      />
                      <button
                        type="button"
                        onClick={handleChangeSecondShowMdp}
                        className={
                          showSecondMdp === true
                            ? "slashed flex liner hover-btn"
                            : "see-me flex liner hover-btn"
                        }
                      >
                        <img src={eyeSlashIconModal} alt="eye-slage-icon" />
                      </button>
                    </div>
                  )}

                <div className="btn-actions-modal flex row addgap8">
                  <button
                    style={{ minWidth: "70px" }}
                    type="button"
                    onClick={() => setAddCniModal(false)}
                    className="hover-btn"
                  >
                    Retour
                  </button>
                  {dataUser.profile.toLowerCase() !== "seller" &&
                  dataUser.checkVendor === false ? (
                    <button
                      disabled={
                        userSocial === ""
                          ? oldpass.length < 6
                            ? true
                            : cniUserImage
                            ? false
                            : true
                          : cniUserImage
                          ? false
                          : true
                      }
                      style={{ minWidth: "70px" }}
                      type="button"
                      onClick={goUploadCni}
                      className="hover-btn"
                    >
                      Confirmer
                    </button>
                  ) : (
                    <button disabled type="button" className="hover-btn">
                      Confirmer
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        )}
        {openPanelNotif !== null && (
          <div
            className={
              isLoading ? "modal-patch-user loading-modal" : "modal-patch-user"
            }
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                setOpenDeletePanelNotif(null);
              }}
              className="background-patch flex"
            >
              <form className="flex colm">
                <div className="title-header flex-colm">
                  <h5 style={{ lineHeight: "22px" }}>{openPanelNotif.title}</h5>
                  <p className="notif-text">{openPanelNotif.text}</p>
                  <span className="notif-date">
                    {formattedDate(openPanelNotif.createdAt)}
                  </span>
                </div>

                <div className="btn-actions-modal flex row addgap8">
                  <button
                    style={{ minWidth: "70px" }}
                    type="button"
                    onClick={() => setOpenPanelNotif(null)}
                    className="hover-btn"
                  >
                    Quitter
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <FooterComposant />
    </div>
  );
};
