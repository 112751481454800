export function compareMyInputInDatas(userInput, dataList, obinary) {
  const threshold = 2;
  function levenshteinDistance(a, b) {
    const m = a.length;
    const n = b.length;
    const dp = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0));
    for (let i = 0; i <= m; i++) {
      for (let j = 0; j <= n; j++) {
        if (i === 0) {
          dp[i][j] = j;
        } else if (j === 0) {
          dp[i][j] = i;
        } else if (a[i - 1] === b[j - 1]) {
          dp[i][j] = dp[i - 1][j - 1];
        } else {
          dp[i][j] = 1 + Math.min(dp[i - 1][j], dp[i][j - 1], dp[i - 1][j - 1]);
        }
      }
    }
    return dp[m][n];
  }
  function binarySearch(input, list) {
    return list.filter((item) => {
      return item.name
        .toString()
        .toLowerCase()
        .includes(input.toString().toLowerCase());
    });
  }
  function levenshteinResults(list) {
    return list.filter((item) => {
      const lowerCaseItem = item.name.toString().toLowerCase();
      const lowerCaseUserInput = userInput.toString().toLowerCase();

      const distance = levenshteinDistance(lowerCaseItem, lowerCaseUserInput);

      item = { ...item, distance };
      return threshold - distance >= -1;
    });
  }
  const proximityResults = binarySearch(userInput, dataList.slice());

  if (obinary === true) {
    return proximityResults;
  }

  const combinedResults = [
    ...levenshteinResults(dataList.slice()),
    ...proximityResults,
  ];

  const uniqueResults = Array.from(new Set(combinedResults));

  return uniqueResults;
}

export function checkIfCorrespond(input, list) {
  const replacements = [
    { from: "é", to: "e" },
    { from: "è", to: "e" },
    { from: "ê", to: "e" },
    { from: "ë", to: "e" },
    { from: "à", to: "a" },
    { from: "â", to: "a" },
    { from: "ä", to: "a" },
    { from: "î", to: "i" },
    { from: "ï", to: "i" },
    { from: "ô", to: "o" },
    { from: "ö", to: "o" },
    { from: "û", to: "u" },
    { from: "ü", to: "u" },
    { from: "ç", to: "c" },
  ];
  return list.filter((item) => {
    let currentTabValue = item.name.toString().toLowerCase();
    let currentTestInput = input.toString().toLowerCase();

    replacements.forEach(({ from, to }) => {
      currentTabValue = currentTabValue.replace(new RegExp(from, "ig"), to);
      currentTestInput = currentTestInput.replace(new RegExp(from, "ig"), to);
    });

    return (
      currentTabValue.length === currentTestInput.length &&
      currentTabValue.includes(currentTestInput)
    );
  });
}
