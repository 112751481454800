import { useEffect, useRef, useState } from "react";
import orderIconFilter from "../Images/imports/order.svg";
import arrowsIconFilter from "../Images/arrow-down.svg";
import fillArrow from "../Images/imports/checkbox-fill-icon.svg";
import "../Styles/filterMarque.css";
export const OrderFilter = ({ orders, setOrder }) => {
  const [currentorders, setCurrentorders] = useState([]);
  const [orderAll, setorderAll] = useState([]);
  const [isOpen, setOpen] = useState(false);

  const divRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (currentorders.length > 0) return;
    setCurrentorders(orders);
  }, [orders]);

  const handleOpen = () => {
    setOpen(!isOpen);
  };
  const handleSelectFilterorderAll = (nameorderAll) => {
    let newM = [];
    currentorders.map((ord) => {
      if (ord.value === nameorderAll) {
        ord.active = !ord.active;
      } else {
        ord.active = false;
      }
      newM.push(ord);
    });
    handleOpen();
    setCurrentorders(newM);
  };
  useEffect(() => {
    let newTab = [];
    currentorders.map((ord) => {
      if (ord.active === true) {
        newTab.push(ord);
      }
    });
    setorderAll(newTab);
    setOrder(newTab);
  }, [currentorders]);
  return (
    <div ref={divRef} className="btn-filter flex filters-f">
      <button onClick={handleOpen} className="btn-select flex row">
        <img src={orderIconFilter} />
        <span className="flex row">
          <p className="flex row">
            {orderAll.length > 0
              ? orderAll.map((ord, count) => {
                  return count < orderAll.length
                    ? "Ordonne par " + ord.value
                    : ord;
                })
              : "Ordonner par"}
          </p>
          <img
            className={isOpen && "is-open-filter-cars"}
            src={arrowsIconFilter}
          />
        </span>
      </button>
      {isOpen === true ? (
        <div className="selection-marque">
          {currentorders.map((orderAll) => {
            return (
              <button
                className="flex"
                onClick={() => handleSelectFilterorderAll(orderAll.value)}
              >
                <div
                  className={orderAll.active ? "flex active-select" : "flex"}
                >
                  <img src={fillArrow} alt="arrow-fill" />
                </div>
                <span style={{ textAlign: "left" }}>{orderAll.value}</span>
              </button>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
