import React from "react";
import "../Shared/Styles/Faq.css";
import { HeroFaq } from "../Shared/Components/hero-faq.component";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Footer } from "../Shared/Components/footer.component";
import { FooterComposant } from "../Shared/Components/footer.composant";
import { useEffect } from "react";

export const Faq = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <div className="Faq_container">
        <HeroFaq />
        <div className="faq-question flex colm max-width center-margin">
          <div className="label-title flex colm">
            <p className="FAQ_page_title">Foire aux questions</p>
            <p className="FAQ_page_subtitle">
              Les questions fréquentes de nos clients.
            </p>
          </div>
          <div className="FAQ_page_accordion_container flex row">
            <div className="FAQ_page_accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<AddOutlinedIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="title-faq"
                >
                  <Typography>
                    Comment puis-je mettre à jour mon profil sur AutoPlus ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="interne-faq-show">
                  <Typography>
                    Pour mettre à jour votre profil, connectez-vous à votre
                    compte, allez dans la section "Mon profil" et modifiez les
                    informations que vous souhaitez mettre à jour.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="FAQ_page_accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<AddOutlinedIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="title-faq"
                >
                  <Typography>
                    Puis-je annuler ma visite après l'avoir passée ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="interne-faq-show">
                  <Typography>
                    Oui, vous pouvez annuler votre commande sous certaines
                    conditions. Veuillez consulter notre politique d'annulation
                    pour plus d'informations ou contactez notre service
                    clientèle pour obtenir de l'aide.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="FAQ_page_accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<AddOutlinedIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="title-faq"
                >
                  <Typography>
                    Comment puis-je contacter le service clientèle d'AutoPlus ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="interne-faq-show">
                  <Typography>
                    Pour contacter notre service clientèle, vous pouvez nous
                    envoyer un e-mail à [adresse e-mail du service clientèle] ou
                    nous appeler au [numéro de téléphone du service clientèle].
                    Nous sommes disponibles du lundi au vendredi, de 9h00 à
                    18h00.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="FAQ_page_accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<AddOutlinedIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="title-faq"
                >
                  <Typography>
                    Comment puis-je effectuer une recherche de véhicule sur
                    AutoPlus ?{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="interne-faq-show">
                  <Typography>
                    Pour effectuer une recherche de véhicule, rendez-vous sur
                    notre page d'accueil et utilisez la barre de recherche en
                    haut de la page pour entrer les critères de votre recherche,
                    tels que la marque, le modèle, l'année, etc. Vous pouvez
                    également utiliser les filtres pour affiner vos résultats.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="FAQ_page_accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<AddOutlinedIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="title-faq"
                >
                  <Typography>
                    Est-ce que je peux recevoir des alertes par e-mail pour les
                    nouvelles annonces correspondant à ma recherche ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="interne-faq-show">
                  <Typography>
                    Vous pourrez recevoir nos derniers bon plans en ajoutant
                    vôtrre email à nôtre newsletter.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="FAQ_page_accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<AddOutlinedIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="title-faq"
                >
                  <Typography>
                    Est-ce que AutoPlus propose des garanties sur les véhicules
                    vendus ?{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="interne-faq-show">
                  <Typography>
                    AutoPlus est une plateforme de mise en relation entre
                    vendeurs et acheteurs. Nous ne proposons pas de garanties
                    directement, mais certains vendeurs peuvent offrir des
                    garanties sur leurs véhicules. Veuillez vérifier les détails
                    de l'annonce pour plus d'informations.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="FAQ_page_accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<AddOutlinedIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="title-faq"
                >
                  <Typography>
                    Comment puis-je sauvegarder une recherche de véhicule pour
                    la consulter plus tard ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="interne-faq-show">
                  <Typography>
                    Une fois connecté, chaque véhicule consulté est enrégistré
                    dans la liste de vos véhicules récément vu. Vous pourrez
                    ensuite y accéder depuis votre profil.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="FAQ_page_accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<AddOutlinedIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="title-faq"
                >
                  <Typography>
                    Est-ce que je peux rechercher des véhicules d'occasion sur
                    AutoPlus ?{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="interne-faq-show">
                  <Typography>
                    Oui, vous pouvez rechercher des véhicules neufs et
                    d'occasion sur AutoPlus. Utilisez les filtres de recherche
                    pour spécifier si vous souhaitez voir uniquement des
                    véhicules neufs, d'occasion ou les deux.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <FooterComposant />
    </>
  );
};
