import { get } from "./api.service";

export const returnStaticInfo = async (endurl) => {
  try {
    const response = await get(endurl);
    return response.data;
  } catch (error) {
    console.error(endurl, error.response);
    return error.response;
  }
};
