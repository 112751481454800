import { decryptData } from "./encryption.service";
import store from '../../app/store'; 
import { get } from "./api.service";

export async function checkUserData() {
  const state = store.getState();
  const isFetch = state.userData.valueUserData;
  if (isFetch === false) return null;

  const getData = state.userData.data;
  let returnVal = null;

  try {
    const decrypt = await decryptData(getData);
    if (decrypt.id !== null && decrypt.id !== undefined) {
      returnVal = decrypt;
    }
  } catch (error) {}

  return returnVal;
}
