import { createSlice } from "@reduxjs/toolkit";

export const changeLoadingState = createSlice({
  name: "loading-state",
  initialState: {
    active: true,
  },
  reducers: {
    setLoadingState: (state, { payload }) => {
      state.active = payload;
    },
  },
});

export const { setLoadingState } = changeLoadingState.actions;
export default changeLoadingState.reducer;
