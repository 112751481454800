import { useEffect, useRef, useState } from "react";
import "../Styles/filterCars.css";
import { CaracteristiquesFilter } from "./car-filter-car-caracteristiques";
import { ConditionFilter } from "./car-filter-car-condition";
import { PriceFilter } from "./car-filter-car-price";
import { YearFilter } from "./car-filter-car-year";
import { CarTypeFilter } from "./car-filter-type";
import searchIcon from "../Images/search-normal.svg";
import filterIcon from "../Images/filter.svg";
import closeIcon from "../Images/close-icon.svg";
import { useSelector } from "react-redux";
import { OrderFilter } from "./car-filter-order";

export const FilterCar = ({
  fixedMe,
  currCars,
  isVisibleAllCard,
  setCurrentSearch,
  currentSearch,
  setMinPrice,
  setMaxPrice,
  setCarYear,
  setCurrentCarTypeSelected,
  setCurrentTransmission,
  setCurrentOilType,
  setCurrentKilometerLimit,
  setOrderFilters,
  setCondition,
  conditions,
  brands,
  oilsType,
  transmissions,
  ordersFilters,
  carTypes,
}) => {
  const [amBottom, setAmBottom] = useState(false);
  const [distanceCalculTop, setDistanceCalculTop] = useState(0);
  const [distanceCalcul, setDistanceCalcul] = useState(0);
  const [timeoutId, setTimeoutId] = useState();

  useEffect(() => {
    const handleDistanceCheck = () => {
      const div1 = document.getElementById("filterPanel");
      const div2 = document.getElementById("footerDiv");

      if (div1 && div2) {
        const div1Rect = div1.getBoundingClientRect();
        const div2Rect = div2.getBoundingClientRect();

        const distanceToTop = div2Rect.top;
        setDistanceCalculTop(distanceToTop);
        setDistanceCalcul(window.innerHeight - distanceToTop + 60);
        const distance = div1Rect.bottom - div2Rect.top;
        if (distanceToTop >= 710) {
          if (amBottom === true) {
            clearTimeout(timeoutId);
            setAmBottom(false);
          }
        }
        if (distance >= -20 && distanceToTop < 710) {
          if (amBottom === false) {
            const newTimeoutId = setTimeout(() => setAmBottom(true), 1);
            setTimeoutId(newTimeoutId);
          }
        }
      }
    };

    window.addEventListener("scroll", handleDistanceCheck);

    return () => {
      window.removeEventListener("scroll", handleDistanceCheck);
      clearTimeout(timeoutId);
    };
  }, [amBottom, timeoutId]);

  function updateCarTypesObjetList() {
    const updatedCarTypes = carTypes.map((item) => ({
      ...item,
      value: item.name,
      active: false,
    }));
    setForCarType(updatedCarTypes);
  }

  const [forCarType, setForCarType] = useState([]);
  function updateOrderObjetList() {
    const updatedOrder = ordersFilters.map((item) => ({
      ...item,
      value: item.name,
      active: false,
    }));
    setForOrder(updatedOrder);
  }

  const [forOrder, setForOrder] = useState([]);

  function updateTransmissionObjetList() {
    const updatedTransmission = transmissions.map((item) => ({
      ...item,
      value: item.name,
      active: false,
    }));
    updatedTransmission.unshift({ id: 0, value: "Peu importe" });
    setForTransmission(updatedTransmission);
  }

  const [forTransmission, setForTransmission] = useState([]);

  function updateOilsTypeObjetList() {
    const updatedOilsType = oilsType.map((item) => ({
      ...item,
      value: item.name,
      active: false,
    }));
    updatedOilsType.unshift({ id: 0, value: "Peu importe" });
    setForOilsType(updatedOilsType);
  }

  const [forOilsType, setForOilsType] = useState([]);

  let killometer = [
    { value: "Peu importe" },
    { value: "10000" },
    { value: "20000" },
    { value: "40000" },
    { value: "60000" },
    { value: "80000" },
    { value: "100000" },
    { value: "120000" },
    { value: "150000" },
    { value: "200000" },
    { value: "250000" },
    { value: "500000" },
    { value: "1000000" },
  ];
  const searchMobile = useRef(null);
  const searchDesktop = useRef(null);
  useEffect(() => {
    if (searchDesktop.current === null || searchMobile.current === null) {
      return;
    }
    searchMobile.current.value = currentSearch;
    searchDesktop.current.value = currentSearch;
  }, [currentSearch]);

  const [isFixedToTop] = useState(false);
  const [isOpenInMobile, setIsOpenInMobile] = useState(false);

  const handleInputFindCar = (event) => {
    setCurrentSearch(event.target.value);
  };
  const handleOpenInMobile = () => {
    setIsOpenInMobile(!isOpenInMobile);
  };

  useEffect(() => {
    updateCarTypesObjetList();
  }, [brands]);
  useEffect(() => {
    updateOrderObjetList();
  }, [ordersFilters]);
  useEffect(() => {
    updateTransmissionObjetList();
  }, [transmissions]);
  useEffect(() => {
    updateOilsTypeObjetList();
  }, [oilsType]);

  const refInputSearch = useRef(null);
  const reftInputDest = useRef(null);
  const dataSearch = useSelector((state) => state.filterDataConf.searchTxt);

  useEffect(() => {
    if (dataSearch)
      if (dataSearch.length > 0)
        if (refInputSearch && reftInputDest) {
          refInputSearch.current.value = dataSearch;
          reftInputDest.current.value = dataSearch;
          setCurrentSearch(dataSearch);
        }
  }, [dataSearch, refInputSearch, reftInputDest]);
  return (
    <>
      <div
        className={
          isVisibleAllCard
            ? "search-car-filter mobile-filter-search-car flex row"
            : "search-car-filter mobile-filter-search-car flex row no-opacity"
        }
      >
        <img src={searchIcon} alt="search-icon" />
        <input
          ref={refInputSearch}
          onChange={(e) => handleInputFindCar(e)}
          type="text"
          placeholder="Trouver un véhicule"
        />
        <button onClick={handleOpenInMobile} className="hover-btn">
          <img src={filterIcon} alt="filter-icon" />
        </button>
      </div>
      <div
        id="filterPanel"
        style={{ bottom: amBottom && currCars.length >= 4 && distanceCalcul }}
        className={
          (isFixedToTop
            ? isOpenInMobile === true
              ? "is-open-filter filter-cars fixed-filter-cars flex colm"
              : "filter-cars fixed-filter-cars flex colm"
            : isOpenInMobile === true
            ? "is-open-filter filter-cars flex colm"
            : "filter-cars flex colm") +
          (fixedMe && currCars.length >= 4 ? " fixed-me " : "") +
          (amBottom && currCars.length >= 4 ? " fixed-me-bottom " : "")
        }
      >
        <div className=" filter-title flex row btw">
          <span>Filtres</span>
          <button onClick={handleOpenInMobile} className="hover-btn flex">
            <img src={closeIcon} alt="cross-icon" />
          </button>
        </div>
        <div className="search-car-filter flex row">
          <img src={searchIcon} alt="search-icon" />
          <input
            onChange={(e) => handleInputFindCar(e)}
            ref={reftInputDest}
            type="text"
            placeholder="Trouver un véhicule"
          />
          <button onClick={handleOpenInMobile} className="hover-btn">
            <img src={filterIcon} alt="filter-icon" />
          </button>
        </div>
        <div className="filtre-panel-contains flex colm">
          <OrderFilter orders={ordersFilters} setOrder={setOrderFilters} />
          <CarTypeFilter
            carType={forCarType}
            setCarType={setCurrentCarTypeSelected}
          />
          <YearFilter setYear={setCarYear} />
          <PriceFilter setMin={setMinPrice} setMax={setMaxPrice} />
          <CaracteristiquesFilter
            transmission={forTransmission}
            oilType={forOilsType}
            killometer={killometer}
            setCurrentKilometerLimitV={setCurrentKilometerLimit}
            setCurrentOilTypeV={setCurrentOilType}
            setCurrentTransmissionV={setCurrentTransmission}
          />
          <ConditionFilter
            conditions={conditions}
            setCondition={setCondition}
          />
        </div>
      </div>
    </>
  );
};
