import React, { useEffect, useState } from "react";
import "../Shared/Styles/acheter.css";
import { Header } from "../Shared/Components/header.component";
import { SectionHeader4 } from "../Shared/Components/section-header4.component";
import { CarCategoryCard } from "../Shared/Components/car-category-card";
import { StoreFilter } from "../Shared/Components/storefilter";
import { StoreFilterCard } from "../Shared/Components/StoreFilter-card";
import { Footer } from "../Shared/Components/footer.component";
import store from "../app/store";
import Monospace from "../Shared/Images/Monospace.svg";
import Suv from "../Shared/Images/Suv.svg";
import Berline from "../Shared/Images/Berline.svg";
import MiniSuv from "../Shared/Images/Mini suv.svg";
import Coupé from "../Shared/Images/Coupé.svg";
import Pickup from "../Shared/Images/Pickup.svg";
import { FilterCar } from "../Shared/Components/car-filter-panel";
import { FooterComposant } from "../Shared/Components/footer.composant";
import { useDispatch, useSelector } from "react-redux";
import { getMyId } from "../Shared/Services/get-goodId-static";
import { setSearchTxt } from "../feature/filter.data";
import { ReturnTop } from "../Shared/Components/ReturnTop";
import { get } from "../Shared/Services/api.service";

export const Acheter = () => {
  const [scrolled, setScrolled] = useState(false);
  const dataBrands = useSelector((state) => state.dataConf.brands);
  const dataCarTypeT = useSelector((state) => state.dataConf.carTypes);
  const dataCarType = [
    { id: 1, name: "Crossover", img: Monospace },
    { id: 2, name: "Suv", img: Suv },
    { id: 3, name: "Berline", img: Berline },
    { id: 4, name: "Limousine", img: MiniSuv },
    { id: 5, name: "Coupé", img: Coupé },
    { id: 6, name: "Pick-Up", img: Pickup },
  ];
  // const dataModels = useSelector((state) => state.dataConf.carTypes);
  const dataOilsType = useSelector((state) => state.dataConf.motorTypes);
  const dataTransmissions = useSelector(
    (state) => state.dataConf.transmissions
  );
  const dataConditions = useSelector((state) => state.dataConf.conditions);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      const scrolledHeight = window.scrollY;
      if (scrolledHeight >= 330) {
        setScrolled(true);
      } else if (scrolledHeight < 330) {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let carsCatego = [
    { name: "Crossover", img: Monospace },
    { name: "Suv", img: Suv },
    { name: "Berline", img: Berline },
    { name: "Limousine", img: MiniSuv },
    { name: "Coupé", img: Coupé },
    { name: "Pick-Up", img: Pickup },
  ];

  let ordersList = [
    { value: "Marque asc↑", key: "brand-asc" },
    { value: "Marque desc↓", key: "brand-desc" },
    { value: "Prix asc↑", key: "price-asc" },
    { value: "Prix desc↓", key: "price-desc" },
    { value: "Killometre asc↑", key: "kilometer-asc" },
    { value: "Killometre desc↓", key: "kilometer-desc" },
  ];

  const [isVisibleAllCard, setIsVisibleAllCard] = useState(true);

  const [currentSearch, setCurrentSearch] = useState("");
  const [carYear, setCarYear] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(10000000);
  const [currentCarTypeSelected, setCurrentCarTypeSelected] = useState([]);
  const [currentTransmission, setCurrentTransmission] = useState("");
  const [currentOilType, setCurrentOilType] = useState("");
  const [currentKilometerLimit, setCurrentKilometerLimit] = useState("");
  const [conditions, setCondition] = useState([]);
  const [currentOrderSelect, setCurrentOrder] = useState([]);

  function updateConditionsObjetList() {
    const updatedConditions = dataConditions.map((item) => ({
      ...item,
      value: item.name,
      active: false,
    }));
    setCondition(updatedConditions);
  }
  useEffect(() => {
    if (dataConditions === null || dataConditions === undefined) return;
    updateConditionsObjetList();
  }, [dataConditions]);

  const returnTableOfGoodId = (tablist) => {
    let tab = [];
    let tabderoge = tablist.slice();
    tabderoge.map((item) => {
      if (item.active === true) {
        tab.push(item.id);
      }
    });
    return tab;
  };
  const [firstSearch, setCurrentFirstSearch] = useState("");
  const [filterParams, setFilterParams] = useState();
  const [currCars, setCurrVehicules] = useState([]);

  const dispatch = useDispatch();
  const constructDataQuery = () => {
    const state = store.getState();
    const fetchText = state.filterDataConf.searchTxt;
    let searchT =
      currentSearch.toString().trim().length >= 2 ? currentSearch : null;
    if (fetchText !== "") {
      searchT = fetchText;
      setFirstBlock(true);
      setCurrentSearch(searchT);
      setCurrentFirstSearch(searchT);
      dispatch(setSearchTxt(""));
      setFilterParams({ search: searchT });
      return;
    }
    let newDataCarType = [];
    currentCarTypeSelected.map((item) => {
      let dataId = getMyId(item.name, dataCarTypeT);
      if (dataId) {
        newDataCarType.push(dataId);
      }
    });
    let dataQuery = {
      search: searchT,
      year: carYear.toString().trim().length > 3 ? parseInt(carYear, 10) : null,
      minPrice,
      maxPrice,
      transmissionId: getMyId(currentTransmission, dataTransmissions),
      // carType: newDataCarType,
      motorTypeId: getMyId(currentOilType, dataOilsType),
      carType: null,
      maxKilometers: parseInt(currentKilometerLimit, 10) || null,
      carCondition:
        returnTableOfGoodId(conditions).length > 0
          ? returnTableOfGoodId(conditions)
          : null,
      order: currentOrderSelect.length > 0 ? currentOrderSelect[0].key : null,
    };
    setFilterParams(dataQuery);
  };
  const [firstRequestBlock, setFirstBlock] = useState(false);
  useEffect(() => {
    if (dataBrands === undefined || dataBrands === null) return;
    // if (dataModels === undefined || dataModels === null) return;
    if (dataOilsType === undefined || dataOilsType === null) return;
    if (dataTransmissions === undefined || dataTransmissions === null) return;
    if (dataConditions === undefined || dataConditions === null) return;

    if (
      firstRequestBlock === true &&
      firstSearch !== "" &&
      currentSearch === ""
    ) {
      setCurrentSearch(firstSearch);
    } else if (firstRequestBlock === true && currentSearch !== "") {
      setFirstBlock(false);
      constructDataQuery();
    }
    if (firstRequestBlock === true) return;
    constructDataQuery();
  }, [
    currentSearch,
    minPrice,
    maxPrice,
    carYear,
    currentCarTypeSelected,
    currentTransmission,
    currentOilType,
    currentKilometerLimit,
    conditions,
    currentOrderSelect,
  ]);
  return (
    <div className="buying-page">
      <Header NoBackground={false} />
      <ReturnTop top={360} detect={600} />
      <div className="contains-buying max-width center-margin">
        <div className="search-catego flex colm">
          <SectionHeader4
            title={"Rechercher par catégorie"}
            subtitle={"Choisissez votre catégorie de véhicule"}
          />
          <CarCategoryCard catego={carsCatego} buyingMenu={true} />
        </div>
        <div className="StoreFilter_container flex row btw">
          {/* <StoreFilter /> */}
          <FilterCar
            fixedMe={scrolled}
            currCars={currCars}
            isVisibleAllCard={isVisibleAllCard}
            setCurrentSearch={setCurrentSearch}
            currentSearch={currentSearch}
            setMinPrice={setMinPrice}
            setMaxPrice={setMaxPrice}
            setCarYear={setCarYear}
            setCurrentCarTypeSelected={setCurrentCarTypeSelected}
            setCurrentTransmission={setCurrentTransmission}
            setCurrentOilType={setCurrentOilType}
            setCurrentKilometerLimit={setCurrentKilometerLimit}
            setCondition={setCondition}
            conditions={conditions}
            brands={dataBrands}
            oilsType={dataOilsType}
            transmissions={dataTransmissions}
            ordersFilters={ordersList}
            setOrderFilters={setCurrentOrder}
            carTypes={dataCarType}
          />
          <StoreFilterCard
            setCurrVehicules={setCurrVehicules}
            setIsVisibleAllCard={setIsVisibleAllCard}
            setFirstBlock={setFirstBlock}
            filterParams={filterParams}
          />
        </div>
      </div>
      <FooterComposant />
    </div>
  );
};
