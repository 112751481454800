import { CarEncardBuy } from "../Shared/Components/car-encard-buy.component";
import { Header } from "../Shared/Components/header.component";
import heartIcon from "../Shared/Images/heart.svg";
import "../Shared/Styles/product-buy-page.css";
import suzuki from "../Shared/Images/suzuki.svg";
import suzuki2 from "../Shared/Images/suzuki2.svg";
import suzuki3 from "../Shared/Images/suzuki3.svg";

import VehiculePicture from "../Shared/Images/VehiculePicture.svg";

import { useEffect, useState } from "react";
import { CardBuySection } from "../Shared/Components/card-how-buy";
import { Footer } from "../Shared/Components/footer.component";
import { SectionHeader1 } from "../Shared/Components/section-header1.component";
import { CarCarousel } from "../Shared/Components/car-carousel.component";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import transmissionIcon from "../Shared/Images/imports/gear-icon.svg";
import vitesseIcon from "../Shared/Images/vitesse.svg";
import carburantIcon from "../Shared/Images/imports/oil-icon.svg";
import moteurIcon from "../Shared/Images/imports/motor-icon.svg";
import kilometerIcon from "../Shared/Images/kilometerProductBuy.svg";
import siegeIcon from "../Shared/Images/imports/siege-icon.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode, Navigation, Pagination, Thumbs } from "swiper";
import { FooterComposant } from "../Shared/Components/footer.composant";
import axios from "axios";
import env from "react-dotenv";
import NotificationService from "../Shared/Services/notification.service";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingState } from "../feature/loading.state";
import { returnStaticInfo } from "../Shared/Services/simplerequest.service";
import { HeartIcon } from "../Shared/Images/jsxsvg/icons";
import goodIcon from "../Shared/Images/good.svg";
import greatIcon from "../Shared/Images/great.svg";
import calendarIcon from "../Shared/Images/calendar2.svg";
import doorsIcon from "../Shared/Images/imports/car-doors2.svg";
import excellentIcon from "../Shared/Images/excellent.svg";
import wrenchIcon from "../Shared/Images/imports/wrengIcon2.svg";
import carTypeIcon from "../Shared/Images/imports/car2.svg";
import {
  capitalizeFirstLetters,
  truncateString,
} from "../Shared/Services/letters.manage";
import { redirectToURL } from "../Shared/Services/redirect.service";
import { get, post, remove } from "../Shared/Services/api.service";
import { setDataFavoris } from "../feature/autoplus.data";
import { decryptData } from "../Shared/Services/encryption.service";
import { checkUserData } from "../Shared/Services/user.check.service";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

export const ProductBuy = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    dispatch(setLoadingState(true));
  }, []);

  const [currentCar] = useState(parseInt(useParams().id, 10) || 0);
  const [carData, setCarData] = useState();
  const [noCar, setNoCar] = useState(false);
  const [loadCarData, setLoadCarData] = useState(false);
  const [isFav, setIsFav] = useState(false);
  const [loadEnd, setLoadEnd] = useState(false);

  const [imgLink, setImgLink] = useState("");
  const [nameCar, setNameCar] = useState("");
  const [priceCar, setPriceCar] = useState("");
  const [kilometerCar, setKilometerCar] = useState("");
  const [halfPriceData, setHalfPriceData] = useState(null);
  const [halfPrice, setHalfPrice] = useState("");
  const [description, setDescription] = useState("");

  const dataFavoris = useSelector((state) => state.dataConf.favoris);
  const [dataUser, setDataUser] = useState();

  const fetchUserData = async () => {
    const valueUserData = await checkUserData();
    if (valueUserData !== null) {
      setDataUser(valueUserData);
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []);
  const [currentFav, setCurrentFav] = useState([]);
  useEffect(() => {
    if (dataFavoris) {
      if (JSON.stringify(currentFav) !== JSON.stringify(dataFavoris)) {
        setCurrentFav(dataFavoris);
      }
    }
  }, [dataFavoris]);

  const [favInLoad, setFavInLoad] = useState(false);
  function searchDataCar(id) {
    return new Promise((resolve, reject) => {
      get(`/cars/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          NotificationService.addToQueue(
            "Aucun véhicule disponible à cette adresse"
          );
          resolve(null);
        });
    });
  }
  function searchHalfPrice(id) {
    return new Promise((resolve, reject) => {
      get(`/cars/eval/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          resolve(null);
        });
    });
  }

  async function setInlastView() {
    if (dataUser !== undefined && dataUser !== null && carData !== null) {
      try {
        const lastadd = await get(`/users/latest_views/${dataUser.id}`);
        let tab = lastadd.data;
        let findId = 0;

        tab.map((view) => {
          if (view.car) if (view.car.id === carData.id) findId = view.id;
          if (view.id) if (view.id === carData.id) findId = view.id;
        });

        if (findId !== 0) {
          await remove(`/users/latest_views/${findId}`);
        }
        post("/users/latest_views", {
          carId: carData.id,
          userId: dataUser.id,
        })
          .then((response) => {
            // console.log(response);
          })
          .catch((error) => {
            return error;
          });
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function searchCarData() {
    const carData = await searchDataCar(currentCar);
    if (carData === null) {
      setNoCar(true);
    } else {
      const carHalfData = await searchHalfPrice(currentCar);
      setHalfPriceData(carHalfData);
      setCarData(carData);
    }
    setLoadCarData(true);
  }

  useEffect(() => {
    if (currentCar === 0) {
      setLoadCarData(true);
      setNoCar(true);
    } else {
      searchCarData();
    }
  }, []);

  useEffect(() => {
    if (carData !== null && carData !== undefined) {
      setInlastView();
    }
  }, [carData]);

  const [recommandedCars, setRecommandedCars] = useState([]);
  const [similarCars, setSimilarCars] = useState([]);

  async function loadRecommandedAndSimilarsCar() {
    const recomCars = await Promise.resolve(
      returnStaticInfo("/cars/recommended")
    );
    setRecommandedCars(recomCars);

    if (noCar !== null && currentCar !== 0) {
      const simiCars = await get(`/cars/similars/${currentCar}`);
      setSimilarCars(simiCars.data);
    }

    setLoadEnd(true);
  }
  useEffect(() => {
    if (loadCarData === false) return;
    loadRecommandedAndSimilarsCar();
  }, [loadCarData]);

  useEffect(() => {
    if (loadCarData === false || loadEnd === false) return;
    if (recommandedCars.length === 0) {
      NotificationService.addToQueue(
        "Erreur lors du chargement des vehicules récommandés"
      );
    } else {
      dispatch(setLoadingState(false));
    }
  }, [loadEnd]);

  const [activeThumb, setActiveThumb] = useState(null);

  const updateFavoris = async (car) => {
    if (dataUser !== undefined || dataUser !== null) {
      let newTab = [];
      let suppressId = 0;
      try {
        currentFav.map((fav) => {
          if (fav.carId === car.id) {
            suppressId = fav.id;
          } else {
            newTab.push(fav);
          }
        });

        if (suppressId === 0) {
          const newFav = await post("/users/favs", {
            userId: dataUser.id,
            carId: car.id,
          });
          newTab.unshift(newFav.data);
          setCurrentFav(newTab);
          dispatch(setDataFavoris(newTab));
          return true;
        } else {
          await remove(`/users/favs/${suppressId}`);
          setCurrentFav(newTab);
          dispatch(setDataFavoris(newTab));
          return false;
        }
      } catch {
        NotificationService.addToQueue(
          "Vous devez etre connecté pour ajouter un vehicule en favoris"
        );
        return false;
      }
    } else {
      NotificationService.addToQueue(
        "Vous devez etre connecté pour ajouter un vehicule en favoris"
      );
      return false;
    }
  };

  function updateFavorisState(val) {
    setIsFav(val);
    setFavInLoad(false);
  }

  const changeFavState = async () => {
    setFavInLoad(true);
    const result = await updateFavoris(carData);
    if (result === true)
      NotificationService.addToQueue(
        `${carData.brandName} ${carData.model} àjoutée à vos favoris`
      );
    updateFavorisState(result);
  };

  const getGoodStateIcon = (val) => {
    const state = val;
    let imgState = goodIcon;
    let colorId = "#3083A3";
    let nameing = "Bon prix";

    if (state === 2) {
      imgState = greatIcon;
      colorId = "#22C55E";
      nameing = "Super prix";
    } else if (state === 3) {
      imgState = excellentIcon;
      colorId = "#16833E";
      nameing = "Excellent prix";
    }

    return { icon: imgState, color: colorId, value: nameing };
  };
  const [stateDiv, setStateDiv] = useState(<></>);
  const [stateSave, setStateSave] = useState();
  useEffect(() => {
    // if (carData.isRecommanded === undefined) return;
    // if (carData.isRecommanded === false) return;
    if (carData === null || carData === undefined) return;
    const goodState = getGoodStateIcon(carData.isGoodPrice);
    setStateSave(goodState);
    setStateDiv(
      <div className="state-price addgap8 liner flex row">
        <div
          style={{ backgroundColor: goodState.color }}
          className="flex liner"
        >
          <img src={goodState.icon} alt="state-icon" />
        </div>
        <span style={{ color: goodState.color }}>{goodState.value}</span>
      </div>
    );
  }, [carData]);

  const [preloadImgCar, setPreloadImgCar] = useState([]);

  useEffect(() => {
    if (carData === null || carData === undefined) return;
    const envLocal = env.API_PRIVATE_URL + "/assets/uploads/cars/";
    setImgLink(envLocal + carData.thumb);

    let currImgDownloaded = [];
    currImgDownloaded.push(envLocal + carData.thumb);
    carData.images.map((item) => {
      if (item.trim() !== "") currImgDownloaded.push(envLocal + item);
    });
    setPreloadImgCar(currImgDownloaded);

    let carN = capitalizeFirstLetters(carData.brandName + " " + carData.model);
    setNameCar(carN);
    setDescription(carData.reportingNotes);
    let price = 0;
    price = carData.price;
    price = price.toLocaleString();
    price = price.toString().replace(" ", ".");
    price = price.toString().replace(" ", ".");
    setPriceCar("₣ " + price);

    let kilometers = 0;
    kilometers = carData.kilometers;
    setKilometerCar(kilometers.toLocaleString() + " km");
  }, [carData]);

  const [goodclassname, setGoodClassname] = useState("");
  const [bellowMarket, setBellowMarket] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [marketStatus, setMarketStatus] = useState("");
  const [averageTab, setAverageTab] = useState({});
  useEffect(() => {
    if (halfPriceData === null) return;

    const priceHalf = halfPriceData.avgPrice;
    const difference = carData.price - priceHalf;
    const priceDiff =
      Math.abs(difference).toLocaleString().replace(/\s/g, ".") + " Fcfa";

    setBellowMarket(Math.abs(difference));

    const percentDifference = (difference / priceHalf) * 100;
    setPercentage(percentDifference);
    let resultPercentClass = "";
    if (percentDifference === 0) {
      setMarketStatus(` égal à `);
      resultPercentClass = "normal-average";
    } else if (percentDifference < -25) {
      setMarketStatus(
        ` de ${priceDiff} soit (${-percentDifference.toFixed(
          2
        )}%) en dessous de `
      );
      resultPercentClass = "too-low-average";
    } else if (percentDifference < 0) {
      setMarketStatus(
        ` de ${priceDiff} soit (${percentDifference.toFixed(
          2
        )}%) en dessous de `
      );
      resultPercentClass = "low-average";
    } else if (percentDifference > 25) {
      setMarketStatus(
        ` de ${priceDiff} soit (${percentDifference.toFixed(2)}%) au dessus de `
      );
      resultPercentClass = "too-high-average";
    } else {
      setMarketStatus(
        ` de ${priceDiff} soit (${-percentDifference.toFixed(
          2
        )}%) au dessus de `
      );
      resultPercentClass = "high-average";
    }
    setGoodClassname(resultPercentClass);

    let colorId = "#3083A3";
    let nameing = "Juste prix";

    if (percentDifference < 0) {
      colorId = "#22c55e";
      nameing = "Bon deal";
    } else if (percentDifference > 0) {
      colorId = "#d89090";
      nameing = "Mauvais deal";
    }
    setAverageTab({ colorId, nameing });
    const formattedPriceHalf = priceHalf.toLocaleString().replace(/\s/g, ".");
    setHalfPrice("₣ " + formattedPriceHalf);
  }, [halfPriceData]);

  useEffect(() => {
    if (currentFav.length > 0 && carData !== undefined && carData !== null) {
      let amIn = false;
      currentFav.map((fav) => {
        if (fav.carId === carData.id) amIn = true;
      });
      setIsFav(amIn);
    }
  }, [carData, currentFav]);

  const goToBuy = () => {
    if (dataUser) {
      if (dataUser.id === carData.ownerId) {
        NotificationService.addToQueue(
          "Vous ne pouvez pas proposer une offre pour l'un de vos véhicules"
        );
        return;
      }
    }
    navigate(`/vehiclepurchase/${carData.id}`);
  };

  const [quickComplementaryExterieur, setQuickComplementaryExterieur] =
    useState([]);
  const [quickComplementaryInterieur, setQuickComplementaryInterieur] =
    useState([]);
  const [quickComplementarySecurity, setQuickComplementarySecurity] = useState(
    []
  );
  const [quickComplementaryOthers, setQuickComplementaryOthers] = useState([]);

  async function getComplementaryValue() {
    if (
      !carData.exteriorsTabObjectValue &&
      !carData.interiorsTabObjectValue &&
      !carData.securityTabObjectValue &&
      !carData.othersTabObjectValue
    )
      return undefined;
    const quickExterieur = await decryptData(carData.exteriorsTabObjectValue);
    let newQuickExterieur = quickExterieur.filter(
      (item) => item.active === true
    );
    const quickInterieur = await decryptData(carData.interiorsTabObjectValue);
    let newQuickInterieur = quickInterieur.filter(
      (item) => item.active === true
    );
    const quickSecurity = await decryptData(carData.securityTabObjectValue);
    let newQuickSecurity = quickSecurity.filter((item) => item.active === true);
    const quickOthers = await decryptData(carData.othersTabObjectValue);
    let newQuickOthers = quickOthers.filter((item) => item.active === true);
    setQuickComplementaryExterieur(newQuickExterieur);
    setQuickComplementaryInterieur(newQuickInterieur);
    setQuickComplementarySecurity(newQuickSecurity);
    setQuickComplementaryOthers(newQuickOthers);
  }
  useEffect(() => {
    if (carData === undefined || carData === null) return undefined;
    getComplementaryValue();
  }, [carData]);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (carData === undefined || carData === null) return undefined;
    let observer;
    const checkElementExists = setInterval(() => {
      const targetElement = document.getElementById("search-average");
      if (targetElement) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setIsVisible(true);
              }
            });
          },
          { threshold: 0.72 }
        );

        observer.observe(targetElement);
        clearInterval(checkElementExists);
      }
    }, 100);

    return () => {
      clearInterval(checkElementExists);
      if (observer) {
        observer.disconnect();
      }
    };
  }, [carData]);

  return (
    <div className="product-buy-page ">
      <Header />
      {loadEnd === true ? (
        carData !== undefined && carData !== null ? (
          <>
            <div className="top-buying-car flex row btw max-width center-margin">
              <div className="left-carousel flex colm">
                <Swiper
                  loop={true}
                  spaceBetween={10}
                  navigation={true}
                  autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  grabCursor={true}
                  thumbs={{ swiper: activeThumb }}
                  modules={[Pagination, Autoplay, FreeMode, Navigation, Thumbs]}
                  className="swipper-carrousel"
                >
                  {preloadImgCar.map((image, count) => {
                    return (
                      <SwiperSlide key={count + "swip"}>
                        <img src={image} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <Swiper
                  onSwiper={setActiveThumb}
                  spaceBetween={19}
                  slidesPerView={4}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="thumbs-show"
                >
                  {preloadImgCar.map((image, count) => {
                    return (
                      <SwiperSlide key={count + "swipThumb"}>
                        <img src={image} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
              <div className="right-carousel flex colm">
                <div className="top-side flex colm">
                  <CarEncardBuy
                    name={nameCar}
                    oil={carData.motorType.name}
                    transmission={carData.transmission.name}
                    kilometer={kilometerCar}
                  />
                  <div className="flex colm info-vehicule">
                    <div className="info-price flex row btw">
                      <span>{priceCar}</span>
                      <button
                        style={{
                          pointerEvents: favInLoad && "none",
                          opacity: favInLoad && "0.7",
                        }}
                        className="hover-btn flex liner fav-btn"
                        onClick={changeFavState}
                      >
                        {isFav === true ? (
                          <HeartIcon color={"#3083a3"} />
                        ) : (
                          <img src={heartIcon} />
                        )}
                      </button>
                    </div>
                    <div className="flex row info-moyenne liner">
                      {halfPriceData !== null && (
                        <>
                          <span>Prix moyen pour ce véhicule : </span>
                          <span>{halfPrice}</span>
                        </>
                      )}
                    </div>
                  </div>
                  <span>Description: {" " + description}</span>
                  <div className="flex row btn-validate">
                    <button onClick={goToBuy}>Je suis interessé</button>
                    <button
                      style={{ cursor: "not-allowed" }}
                      disabled={true}
                      className="hover-btn"
                    >
                      Contacter
                    </button>
                  </div>
                </div>
                <div className="bot-side flex row">{stateDiv}</div>
              </div>
            </div>
            <div className="info-vehicule flex row max-width center-margin">
              <div className="left-title flex colm">
                <div className="label-principale">
                  <p>Informations générales</p>
                  <p>du vehicule</p>
                </div>
                <p>Les différentes caractéristiques du véhicule.</p>
              </div>
              <div className="right-info flex row">
                <div className="card-spec flex row">
                  <div className="img-info flex">
                    <img src={moteurIcon} />
                  </div>
                  <div className="information flex colm">
                    <p>
                      {carData.motorPowerId ? carData.motorPower.name : "N/A"}
                    </p>
                    <p>Moteur</p>
                  </div>
                </div>
                <div className="card-spec flex row">
                  <div className="img-info flex">
                    <img src={transmissionIcon} />
                  </div>
                  <div className="information flex colm">
                    <p>{carData.transmission.name}</p>
                    <p>Boite de vitesse</p>
                  </div>
                </div>
                <div className="card-spec flex row">
                  <div className="img-info flex">
                    <img src={calendarIcon} />
                  </div>
                  <div className="information flex colm">
                    <p>{carData.year}</p>
                    <p>Année</p>
                  </div>
                </div>
                <div className="card-spec flex row">
                  <div className="img-info flex">
                    <img src={doorsIcon} />
                  </div>
                  <div className="information flex colm">
                    <p>
                      {carData.doors > 9 ? carData.doors : `0${carData.doors}`}
                    </p>
                    <p>Portes</p>
                  </div>
                </div>
                <div className="card-spec flex row">
                  <div className="img-info flex">
                    <img src={kilometerIcon} />
                  </div>
                  <div className="information flex colm">
                    <p>{kilometerCar.replace("km", "KM")}</p>
                    <p>Kilométrage</p>
                  </div>
                </div>
                <div className="card-spec flex row">
                  <div className="img-info flex">
                    <img src={carburantIcon} />
                  </div>
                  <div className="information flex colm">
                    <p>{truncateString(carData.motorType.name)}</p>
                    <p>Carburant</p>
                  </div>
                </div>
                <div className="card-spec flex row">
                  <div className="img-info flex">
                    <img src={siegeIcon} />
                  </div>
                  <div className="information flex colm">
                    <p>
                      {carData.places < 10
                        ? "0" + carData.places
                        : carData.places}
                    </p>
                    <p>Sièges</p>
                  </div>
                </div>
                <div className="card-spec flex row">
                  <div className="img-info flex">
                    <img src={carTypeIcon} />
                  </div>
                  <div className="information flex colm">
                    <p>{carData.carType.name}</p>
                    <p>Type du véhicule</p>
                  </div>
                </div>
                <div className="card-spec flex row">
                  <div className="img-info flex">
                    <img src={wrenchIcon} />
                  </div>
                  <div className="information flex colm">
                    <p>{carData.carCondition.name}</p>
                    <p>Condition</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="complementary-info-section flex row max-width center-margin">
              <div className="flex colm complementary-info-contains">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<AddOutlinedIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="title-faq"
                  >
                    <Typography>Options Extérieur et Chassis</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="interne-faq-show">
                    {quickComplementaryExterieur.length > 0 ? (
                      quickComplementaryExterieur.map((item) => (
                        <Typography>{item.text}</Typography>
                      ))
                    ) : (
                      <Typography>Aucune option</Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<AddOutlinedIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="title-faq"
                  >
                    <Typography>Options Interieur</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="interne-faq-show">
                    {quickComplementaryInterieur.length > 0 ? (
                      quickComplementaryInterieur.map((item) => (
                        <Typography>{item.text}</Typography>
                      ))
                    ) : (
                      <Typography>Aucune option</Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<AddOutlinedIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="title-faq"
                  >
                    <Typography>Options Sécurité</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="interne-faq-show">
                    {quickComplementarySecurity.length > 0 ? (
                      quickComplementarySecurity.map((item) => (
                        <Typography>{item.text}</Typography>
                      ))
                    ) : (
                      <Typography>Aucune option</Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<AddOutlinedIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="title-faq"
                  >
                    <Typography>Autres options</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="interne-faq-show">
                    {quickComplementaryOthers.length > 0 ? (
                      quickComplementaryOthers.map((item) => (
                        <Typography>{item.text}</Typography>
                      ))
                    ) : (
                      <Typography>Aucune option</Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="left-title flex colm">
                <div className="label-principale">
                  <p>Informations complementaires</p>
                  <p>du vehicule</p>
                </div>
                <p>
                  Les différentes caractéristiques supplementaires du véhicule.
                </p>
              </div>
            </div>
            <div className="flex colm liner max-width center-margin | section-price-average">
              <p className="title-price-comparaison">Prix moyen</p>
              <div className="lineview | flex colm liner">
                <div className="line-v">
                  <div
                    id="search-average"
                    style={{ "--target-left": "15%" }}
                    className={
                      isVisible
                        ? `flex colm | ${goodclassname} marker-info market-indicator`
                        : `flex colm | marker-info market-indicator`
                    }
                  >
                    <div className="flex colm">
                      <span className="pricer-show">
                        {Math.abs(carData.price)
                          .toLocaleString()
                          .replace(/\s/g, ".") + " Fcfa"}
                      </span>
                      <div className="state-price addgap8 liner flex row">
                        <span style={{ color: averageTab.colorId }}>
                          {averageTab.nameing}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex colm | average-info">
                    <div className="flex colm">
                      <span className="pricer-show bolder">
                        {halfPriceData &&
                          Math.abs(halfPriceData.avgPrice)
                            .toLocaleString()
                            .replace(/\s/g, ".") + " Fcfa"}
                      </span>
                      <div className="state-price second-state-price addgap8 liner flex row">
                        <span style={{ color: "var(--black-300)" }}>
                          Prix moyen
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="bolder">
                  {`Cette voiture à un prix ${marketStatus} la moyenne
                  du marché`}
                </p>
                <p>
                  Le calcul s'effectue en comparant le prix de cette voiture
                  avec les autres ayant le même nom de marque et de modèle
                  poster sur <span className="bolder">AUTOPLUS</span>.
                </p>
              </div>
            </div>
          </>
        ) : (
          <div className="no-find-car max-width center-margin flex">
            <div>
              <h2>
                Aucun véhicule n'est disponible à cette adresse ou celui ci a
                déjà été achété.
              </h2>
            </div>
          </div>
        )
      ) : (
        <div className="no-find-car loader-coming max-width center-margin flex">
          <div class="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      )}

      <div className="max-width center-margin">
        <CardBuySection />
      </div>

      {similarCars.length > 0 && (
        <div className="similar-cars flex colm max-width center-margin">
          <SectionHeader1
            title={"Véhicules similaires"}
            subtitle={"Ces véhicules pourraient vous plaire."}
          />
          <div className="cliped-contains">
            <CarCarousel tabCar={similarCars} />
          </div>
        </div>
      )}

      {recommandedCars.length > 0 && (
        <div className="similar-cars flex colm max-width center-margin">
          <SectionHeader1
            title={"Véhicules recommandé"}
            subtitle={"Une sélection de nos véhicules les plus vendu."}
          />
          <div className="cliped-contains">
            <CarCarousel tabCar={recommandedCars} />
          </div>
        </div>
      )}

      <FooterComposant />
    </div>
  );
};
