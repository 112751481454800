import React, { useEffect, useRef } from "react";
import "../Shared/Styles/login.css";
import { Header } from "../Shared/Components/header.component";
import AutoPlusLogo from "../Shared/Images/imports/logo-primary-auto-plus-hide.svg";
import carImage from "../Shared/Images/pngwing11.png";
import vectorImage from "../Shared/Images/vectorConnexion.svg";
import smsIcon from "../Shared/Images/sms.svg";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FooterComposant } from "../Shared/Components/footer.composant";
import {
  redirectToHome,
  redirectToURL,
} from "../Shared/Services/redirect.service";
import { post } from "../Shared/Services/api.service";
import NotificationService from "../Shared/Services/notification.service";

export const NewAccountConfirmComplete = () => {
  const token = useParams().token || 0;
  async function confirmCompte() {
    try {
      const response = await post("/users/mail_confirmation", {
        mailConfirmToken: token,
      });
      NotificationService.addToQueue("Votre mail à été confirmé");
      setTimeout(() => {
        redirectToURL("/connexion");
      }, 2000);
    } catch (error) {
      NotificationService.addToQueue("Votre mail à déjà été confirmé");
      setTimeout(() => {
        redirectToURL("/connexion");
      }, 2000);
    }
  }

  useEffect(() => {
    confirmCompte();
  }, [token]);

  return (
    <div className="login-connect-page connexion-page">
      <Header NoBackground={false} />
      <div
        style={{ boxSizing: "border-box" }}
        className="login-page flex center-margin max-width"
      >
        <img className="back-car" src={carImage} alt="cars-image" />
        <img className="back-vector" src={vectorImage} alt="vector-image" />
        <div
          style={{ boxSizing: "border-box", marginBottom: "auto" }}
          className="contains flex colm"
        >
          <div className="top-img-label flex colm">
            <img src={AutoPlusLogo} alt="autoPlusLogo" />
            <h4>Confirmation</h4>
            <span>
              Votre compte à été confirmer, nous vous redirigerons sur la page
              d'accueil dans quelques secondes.
            </span>
          </div>
        </div>
      </div>
      <FooterComposant />
    </div>
  );
};
