import React from "react";
import "../Styles/Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { TestimonialCard } from "../Components/testimonial-card";
import { Autoplay } from "swiper";

export const Testimonial = () => {
  let commentaire1 = [
    {
      img: "",
      name: "Patrick Eloïme",
      title: "Client",
      message:
        "Félicitations pour votre site internet ! La conception est superbe et captivante, avec des visuels attrayants qui attirent immédiatement l'attention.",
    },
    {
      img: "",
      name: "Victoire koffi",
      title: "Cliente",
      message:
        "Les fonctionnalités interactives ajoutent une dimension ludique à l'expérience utilisateur. Merci pour votre travail bien fait",
    },
    {
      img: "",
      name: "Karine Koutoua",
      title: "Cliente",
      message:
        "J'apprécie la clarté et la concision de vos informations, ainsi que la manière dont vous présentez les détails de manière accessible.",
    },
    {
      img: "",
      name: "Christelle Amon",
      title: "Cliente",
      message:
        "Votre site est une véritable mine d'or d'informations pertinentes et intéressantes. Continuez ainsi, c'est un réel plaisir de parcourir votre site !",
    },
    {
      img: "",
      name: "Trésor Vanga",
      title: "Client",
      message:
        "Je le recommande vivement à tous ceux qui recherchent des ressources de qualité dans ce domaine. Bravo à toute l'équipe pour leur excellent travail !",
    },
  ];
  return (
    <div className="Testimonial_carousel">
      <div className="marquee flex">
        <div className="marquee-group flex row">
          {commentaire1.map((el, count) => {
            return (
              <div key={count + "1com"} className="image-group">
                <TestimonialCard
                  img={el.img}
                  name={el.name}
                  title={el.title}
                  info={el.message}
                />
              </div>
            );
          })}
        </div>
        <div className="marquee-group flex row">
          {commentaire1.map((el, count) => {
            return (
              <div key={count + "1com"} className="image-group">
                <TestimonialCard
                  img={el.img}
                  name={el.name}
                  title={el.title}
                  info={el.message}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
