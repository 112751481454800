export function getMyId(value, tablist) {
  let id = 0;
  tablist.map((item) => {
    if (item.name.toString().toLowerCase() === value.toString().toLowerCase()) {
      id = item.id;
    }
  });
  if (id > 0) return id;
  else return null;
}

export function getMyName(value, tablist) {
  let name = "";
  tablist.map((item) => {
    if (item.id.toString().toLowerCase() === value.toString().toLowerCase()) {
      name = item.name;
    }
  });
  return name;
}
