import React from "react";
import "../Shared/Styles/politique.css";
import { FooterComposant } from "../Shared/Components/footer.composant";
import { useEffect } from "react";
import autoSimpleIcon from "../Shared/Images/imports/auto-simple.svg";
import { Header } from "../Shared/Components/header.component";
import { ReturnTop } from "../Shared/Components/ReturnTop";
export const Politique = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="politique-container">
      <Header NoBackground={false} />
      <ReturnTop top={0}/>
      <div className="politique-contain flex colm max-width center-margin">
        <div className="title flex row liner addgap8">
          <h2>Politique de Confidentialité d'AutoPlus</h2>
          <img
            style={{ width: "30px" }}
            src={autoSimpleIcon}
            alt="auto-plus-icon"
          />
        </div>
        <span>Date effective : 30 Juillet 2023</span>
        <p className="justify">
          Chez AutoPlus, nous accordons une grande importance à la protection de
          vos informations personnelles. Cette Politique de confidentialité
          explique quelles informations nous collectons, comment nous les
          utilisons, les partageons et les stockons lorsque vous utilisez notre
          site web, AutoPlus. Veuillez lire attentivement cette politique pour
          comprendre nos pratiques concernant vos informations personnelles.
        </p>
        <h5>I/ Informations que nous collectons</h5>
        <p className="justify">
          Les informations que nous recueillons dépendent de la manière dont
          vous utilisez notre site web AutoPlus. Voici les informations que nous
          pouvons collecter :
        </p>
        <p>
          <span className="bolder">a-</span> Informations d'inscription :
          Lorsque vous créez un compte sur AutoPlus, nous pouvons collecter
          votre nom, adresse e-mail, mot de passe et d'autres informations
          nécessaires pour créer et gérer votre compte.
        </p>
        <p>
          <span className="bolder">b-</span> Informations de profil : Vous
          pouvez ajouter des informations supplémentaires à votre profil, telles
          que votre numéro de téléphone, votre nom, votre lieu d'habitation,
          etc.
        </p>

        <p>
          <span className="bolder">c-</span> Informations de connexion : Lorsque
          vous utilisez notre site web, nous recueillons automatiquement des
          informations sur votre appareil, votre adresse IP, votre navigateur,
          et les pages que vous visitez.
        </p>

        <p>
          <span className="bolder">d-</span> Informations de réseaux sociaux :
          Si vous choisissez de vous connecter à votre compte AutoPlus via votre
          compte Google ou Facebook, nous pouvons collecter certaines
          informations de votre profil social.
        </p>

        <p>
          <span className="bolder">e-</span> Informations liées aux transactions
          : Si vous effectuez des transactions sur notre site, telles que
          l'achat ou le financement d'un véhicule, nous collectons les
          informations nécessaires pour traiter la transaction.
        </p>

        <h5>II/ Utilisation des informations</h5>
        <p className="justify">
          Nous utilisons les informations collectées pour les finalités
          suivantes :
        </p>

        <p>
          <span className="bolder">a-</span> Fourniture des services : Nous
          utilisons vos informations pour gérer votre compte, traiter vos
          transactions, personnaliser votre expérience sur notre site et vous
          fournir les services que vous demandez.
        </p>

        <p>
          <span className="bolder">b-</span> Communication : Nous utilisons
          votre adresse e-mail et vos coordonnées pour vous envoyer des
          notifications, des mises à jour et des informations importantes
          concernant votre compte et nos services.
        </p>

        <p>
          <span className="bolder">c-</span> Personnalisation : Nous pouvons
          utiliser vos informations pour personnaliser le contenu, les
          publicités et les offres en fonction de vos préférences et de vos
          intérêts.
        </p>

        <p>
          <span className="bolder">d-</span> Sécurité et prévention des fraudes
          : Nous utilisons vos informations pour assurer la sécurité de notre
          site web et de nos utilisateurs, détecter et prévenir les activités
          frauduleuses.
        </p>

        <p>
          <span className="bolder">e-</span> Amélioration de nos services : Nous
          utilisons les données collectées pour analyser l'utilisation de notre
          site web, comprendre les tendances des utilisateurs et améliorer nos
          services.
        </p>

        <h5>III/ Partage d'informations</h5>
        <p className="justify">
          Nous ne vendons pas vos informations personnelles à des tiers.
          Cependant, nous pouvons partager vos informations dans les cas
          suivants :
        </p>

        <p>
          <span className="bolder">a-</span> Partenaires intégrés : Si vous
          choisissez d'utiliser des services de partenaires intégrés sur
          AutoPlus, nous pouvons partager certaines informations nécessaires
          pour faciliter l'intégration et la prestation des services.
        </p>

        <p>
          <span className="bolder">b-</span> Fournisseurs de services : Nous
          pouvons partager vos informations avec des prestataires de services
          tiers qui nous aident à exploiter notre site web, à traiter des
          paiements, à fournir des services de support, etc.
        </p>

        <p>
          <span className="bolder">c-</span> Conformité légale : Nous pouvons
          divulguer vos informations si la loi l'exige, en réponse à une demande
          légale, une assignation à comparaître ou pour protéger nos droits et
          ceux de nos utilisateurs.
        </p>

        <p>
          <span className="bolder">d-</span> Changement de propriété : En cas de
          fusion, d'acquisition ou de cession d'actifs, vos informations peuvent
          être transférées à la nouvelle entité conformément à la loi.
        </p>

        <h5>IV/ Sécurité des informations</h5>
        <p className="justify">
          Nous prenons des mesures raisonnables pour protéger vos informations
          contre l'accès non autorisé, la divulgation, l'altération ou la
          destruction. Cependant, aucune méthode de transmission sur Internet ou
          de stockage électronique n'est totalement sécurisée. Nous ne pouvons
          donc garantir une sécurité absolue.
        </p>
        <h5>V/ Vos droits</h5>
        <p className="justify">
          Vous avez le droit d'accéder, de corriger, de mettre à jour ou de
          supprimer vos informations personnelles. Vous pouvez également vous
          désinscrire de nos communications marketing à tout moment. Pour
          exercer ces droits ou pour toute question concernant vos informations
          personnelles, veuillez nous contacter à l'adresse{" "}
          <span>[Insérer l'adresse e-mail de contact]</span>.
        </p>

        <h5>VI/ Modifications de la politique</h5>
        <p className="justify">
          Nous pouvons mettre à jour cette Politique de confidentialité
          périodiquement pour refléter les changements dans nos pratiques de
          confidentialité. Nous vous informerons des modifications importantes
          par le biais de notifications sur notre site web. Nous vous
          encourageons à consulter cette politique régulièrement pour rester
          informé des pratiques de confidentialité d'AutoPlus.
        </p>

        <h5>VII/ Contact</h5>
        <p className="justify">
          Si vous avez des questions, des préoccupations ou des demandes
          concernant cette Politique de confidentialité ou vos informations
          personnelles, veuillez nous contacter à l'adresse{" "}
          <span>[Insérer l'adresse e-mail de contact]</span> ou par courrier à
          l'adresse suivante :
        </p>

        <p>
          AutoPlus
          <br />
          <span>[Insérer l'adresse postale de l'entreprise]</span>
        </p>

        <p className="justify">
          Nous vous remercions de votre confiance en choisissant AutoPlus. Votre
          confidentialité est importante pour nous, et nous nous engageons à
          protéger vos informations personnelles.
        </p>
      </div>
      <FooterComposant />
    </div>
  );
};
