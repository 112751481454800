import React from "react";
import "../Styles/TestimonialCard.css";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import ProfilPicture from "../Images/profilpicture.svg";

export const TestimonialCard = () => {
  return (
    <div className="testimonial-card-container">
      <div className="comment_container f">
        <div className="comment_profil_picture">
          <Avatar alt="profilIMG" src={ProfilPicture} />
        </div>

        <div className="info flex colm btw">
          <p className="comment_text">
            Figma ipsum component variant main layer. Team vertical community
            editor layout. Polygon export content inspect stroke. Scale.Figma
            ipsum component variant main.
          </p>

          <p className="comment_author">Mr Koné, Marcory</p>
        </div>
      </div>
    </div>
  );
};
