import { configureStore } from "@reduxjs/toolkit";
import dataConfig from "../feature/autoplus.data";
import loadingStateConfig from "../feature/loading.state";
import filterDataConfig from "../feature/filter.data";
import loadDataUser from "../feature/user.data";
export default configureStore({
  reducer: {
    userData: loadDataUser,
    dataConf: dataConfig,
    loadingStateConf: loadingStateConfig,
    filterDataConf: filterDataConfig,
  },
});
