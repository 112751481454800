import React, { useEffect, useRef } from "react";
import "../Shared/Styles/login.css";
import { Header } from "../Shared/Components/header.component";
import AutoPlusLogo from "../Shared/Images/imports/logo-primary-auto-plus-hide.svg";
import { Footer } from "../Shared/Components/footer.component";
import carImage from "../Shared/Images/pngwing11.png";
import vectorImage from "../Shared/Images/vectorConnexion.svg";
import eyeSlashIcon from "../Shared/Images/eye-slash.svg";
import userIcon from "../Shared/Images/user.svg";
import smsIcon from "../Shared/Images/sms.svg";
import callIcon from "../Shared/Images/call.svg";
import lockIcon from "../Shared/Images/lock.svg";
import checkArrow from "../Shared/Images/imports/checkbox-fill-icon.svg";
import { get, post, patch } from "../Shared/Services/api.service";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FooterComposant } from "../Shared/Components/footer.composant";
import axios from "axios";
import env from "react-dotenv";
import NotificationService from "../Shared/Services/notification.service";
import {
  redirectToHome,
  redirectToURL,
} from "../Shared/Services/redirect.service";
import { checkUserData } from "../Shared/Services/user.check.service";

export const NewPassword = () => {
  async function checkUserStatus() {
    const valueUserData = await checkUserData();
    if (valueUserData !== null) {
      redirectToHome();
    }
  }
  useEffect(() => {
    checkUserStatus();
    return () => {};
  }, []);
  const [currentId] = useState(parseInt(useParams().id, 10) || 1);

  const [showMdp, setShowMdp] = useState(false);
  const [showSecondMdp, setShowSecondMdp] = useState(false);

  const handleChangeShowMdp = () => {
    setShowMdp(!showMdp);
  };
  const handleChangeShowSecondMdp = () => {
    setShowSecondMdp(!showSecondMdp);
  };

  const [mdpInput, setMdpInput] = useState("");
  const [secondMdpInput, setSecondMdpInput] = useState("");

  const [canLogin, setCanLogin] = useState(false);

  const [changeInLoading, setChangeInLoading] = useState(false);

  useEffect(() => {
    if (
      mdpInput.length >= 8 &&
      secondMdpInput.length >= 8 &&
      mdpInput === secondMdpInput
    ) {
      setCanLogin(true);
    } else {
      setCanLogin(false);
    }
  }, [mdpInput, secondMdpInput]);

  const newPasswordConfirm = () => {
    let data = {
      password: mdpInput,
    };
    setChangeInLoading(true);
    patch("/users/newpass/" + currentId, data)
      .then((response) => {
        console.log(response.data);
        NotificationService.addToQueue(
          "Vôtre mot de passe à été modifié avec succès"
        );
        setTimeout(() => {
          redirectToURL("/connexion");
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
        NotificationService.addToQueue(
          "Erreur serveur, le mot de passe ne peut être renitialiser"
        );
        setChangeInLoading(false);
      });
  };
  return (
    <div className="login-connect-page">
      <Header NoBackground={false} />
      <div className="login-page flex center-margin max-width">
        <img className="back-car" src={carImage} alt="cars-image" />
        <img className="back-vector" src={vectorImage} alt="vector-image" />
        <div className="contains flex colm">
          <div className="top-img-label flex colm">
            <img src={AutoPlusLogo} alt="autoPlusLogo" />
            <h4>Renitialiser le mot de passe</h4>
          </div>
          <form className="input-contains flex colm">
            <div className="input-me-login">
              <img src={lockIcon} alt="lock-icon" />
              <input
                onChange={(e) => setMdpInput(e.target.value)}
                type={showMdp === true ? "text" : "password"}
                placeholder="Nouveau mot de passe"
              />
              <button
                type="button"
                onClick={handleChangeShowMdp}
                className={showMdp === true ? "eye-slage o-back" : "hover-btn"}
              >
                <img
                  className={
                    showMdp === true ? "eye-slage o-back" : "eye-slage"
                  }
                  src={eyeSlashIcon}
                  alt="eye-slage-icon"
                />
              </button>
            </div>
            <div className="input-me-login">
              <img src={lockIcon} alt="lock-icon" />
              <input
                onChange={(e) => setSecondMdpInput(e.target.value)}
                type={showSecondMdp === true ? "text" : "password"}
                placeholder="Confirmer le mot de passe"
              />
              <button
                type="button"
                onClick={handleChangeShowSecondMdp}
                className={
                  showSecondMdp === true ? "eye-slage o-back" : "hover-btn"
                }
              >
                <img
                  className="eye-slage"
                  src={eyeSlashIcon}
                  alt="eye-slage-icon"
                />
              </button>
            </div>
          </form>
          <button
            disabled={!(canLogin && !changeInLoading)}
            onClick={newPasswordConfirm}
            className="hover-btn action-btn"
          >
            Renitialiser le mot de passe
          </button>
        </div>
      </div>
      <FooterComposant />
    </div>
  );
};
