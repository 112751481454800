import React, { useEffect, useState } from "react";
import "../Shared/Styles/acceuil.css";
import { HeroSection } from "../Shared/Components/hero.component";
import { SectionHeader1 } from "../Shared/Components/section-header1.component";
import { CarCarousel } from "../Shared/Components/car-carousel.component";
import { SectionHeader2 } from "../Shared/Components/section-header2.component";
import { Testimonial } from "../Shared/Components/testimonial";
import { SectionHeader3 } from "../Shared/Components/section-header3.component";
import { SectionHeader3Card } from "../Shared/Components/section-header3-card";
import { SectionContact } from "../Shared/Components/section-contact";
import { SectionHeader4 } from "../Shared/Components/section-header4.component";
import { CarCategoryCard } from "../Shared/Components/car-category-card";
import Monospace from "../Shared/Images/Monospace.svg";
import Suv from "../Shared/Images/Suv.svg";
import Berline from "../Shared/Images/Berline.svg";
import MiniSuv from "../Shared/Images/Mini suv.svg";
import Coupé from "../Shared/Images/Coupé.svg";
import Pickup from "../Shared/Images/Pickup.svg";
import { InfoQuickSell } from "../Shared/Components/info-quick-sell";
import { FooterComposant } from "../Shared/Components/footer.composant";
import { returnStaticInfo } from "../Shared/Services/simplerequest.service";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../feature/loading.state";
import NotificationService from "../Shared/Services/notification.service";
import { checkUserData } from "../Shared/Services/user.check.service";
import { get } from "../Shared/Services/api.service";

export const Acceuil = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoadingState(true));
  }, []);
  const [loadEnd, setLoadEnd] = useState(false);

  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrolledHeight = window.scrollY;
      if (scrolledHeight >= 100) {
        setScrolled(true);
      } else if (scrolledHeight < 100) {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }; // eslint-disable-next-line
  }, []);

  const searchLastSeenCars = async () => {
    const dataUser = await checkUserData();
    if (dataUser !== null && dataUser !== undefined) {
      try {
        const response = await get(`/users/latest_views/${dataUser.id}`);
        return response.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    } else return null;
  };

  const [recommandedCars, setRecommandedCars] = useState([]);
  const [lastAddCars, setLastAddCars] = useState([]);
  const [lastSeenCars, setLastSeenCars] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const promises = [
        returnStaticInfo("/cars/recommended"),
        returnStaticInfo("/cars/latest"),
        searchLastSeenCars(),
      ];
      const [recomCars, lastACars, lastSeenC] = await Promise.all(promises);
      setRecommandedCars(recomCars);
      setLastAddCars(lastACars);
      if (lastSeenC !== null) {
        let tabLastSeen = lastSeenC;
        tabLastSeen = await tabLastSeen.filter((item) => item.car !== null);
        let finalTabSeen = [];
        tabLastSeen.map((item) => {
          finalTabSeen.push(item.car);
        });
        finalTabSeen = finalTabSeen.slice(0, 11);
        setLastSeenCars(finalTabSeen);
      }

      setLoadEnd(true);
    }
    fetchData();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loadEnd === false) return;
    if (recommandedCars.length === 0 || lastAddCars.length === 0) {
      NotificationService.addToQueue("Erreur lors du chargement des vehicules");
    } else {
      dispatch(setLoadingState(false));
    } // eslint-disable-next-line
  }, [loadEnd]);

  let carsCatego = [
    { name: "Crossover", img: Monospace },
    { name: "Suv", img: Suv },
    { name: "Berline", img: Berline },
    { name: "Limousine", img: MiniSuv },
    { name: "Coupé", img: Coupé },
    { name: "Pick-Up", img: Pickup },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="landing-page">
      <div className="landingpage_container max-width center-margin">
        <HeroSection scrolled={scrolled} />

        {lastSeenCars.length >= 5 && (
          <>
            <SectionHeader1
              title={"Véhicules recemment consultés"}
              subtitle={"La liste des véhicules récemment consultés."}
            />
            <div className="cliped-contains">
              <CarCarousel tabCar={lastSeenCars} />
            </div>
          </>
        )}

        {recommandedCars.length > 0 ? (
          <>
            <SectionHeader1
              title={"Véhicules recommandés"}
              subtitle={"Une sélection de nos véhicules les plus vendus."}
            />
            <div className="cliped-contains">
              <CarCarousel tabCar={recommandedCars} />
            </div>
          </>
        ) : (
          <div className="no-find-car loader-coming max-width center-margin flex">
            <div class="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        )}

        <div className="search-catego flex colm">
          <SectionHeader4
            title={"Rechercher par catégorie"}
            subtitle={"Choisissez votre catégorie de véhicule"}
          />
          <CarCategoryCard catego={carsCatego} buyingMenu={false} />
        </div>

        {lastAddCars.length > 0 ? (
          <>
            <div className="LastAdd_container flex colm">
              <SectionHeader1
                title={"Derniers ajouts"}
                subtitle={
                  "Retrouvez les dernières voiture ajouté à notre catalogue."
                }
              />
              <div className="cliped-contains">
                <CarCarousel tabCar={lastAddCars} />
              </div>
            </div>
          </>
        ) : (
          <div className="no-find-car loader-coming max-width center-margin flex">
            <div class="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        )}
      </div>
      <div className="SectionHeader2_container testimonial-container center-margin max-width flex colm">
        <SectionHeader2
          title={"Ce qu'ils pensent de nous"}
          subtitle={
            "Découvrez ce que nos clients ont à dire sur leur expérience avec Auto plus."
          }
        />
        <Testimonial />
      </div>
      <div className="why-us center-margin max-width flex colm">
        <SectionHeader3
          title={"POURQUOI AUTO PLUS"}
          subtitle={"Des offres avantageuses et facile d'accès."}
        />
        <SectionHeader3Card />
      </div>
      <InfoQuickSell />
      <SectionContact />
      <FooterComposant />
    </div>
  );
};
