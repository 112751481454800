import autoPlusImageLogo from "../Shared/Images/imports/logo-primary-auto-plus.svg";
import { redirectToHome } from "../Shared/Services/redirect.service";
import "../Shared/Styles/notFoundPage.css";

export const NotFound = () => {
  return (
    <div className="not-found-panel flex colm liner max-width center-margin">
      <button onClick={redirectToHome} className="flex liner">
        <img src={autoPlusImageLogo} alt="auto-plus-logo" />
      </button>
      <div className="flex colm liner hover-btn">
        <h4>Erreur 404</h4>
        <span>Page non trouvée</span>
      </div>
    </div>
  );
};
