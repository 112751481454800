import env from "react-dotenv";
import CryptoJS from "crypto-js";
import { Crypt, RSA } from "hybrid-crypto-js";
const key = env.SECRET_KEY;
const encryptionIV = env.SECRET_IV;

export function encryptData(data, publicKey = null) {
  let cipher = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    iv: encryptionIV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  let crypt = new Crypt({
    rsaStandard: "RSA-OAEP",
  });

  let result = cipher.toString();

  if (publicKey !== null) {
    result = crypt.encrypt(publicKey, result);
  }

  return result;
}

export function decryptData(data) {
  let cipher = CryptoJS.AES.decrypt(data, key, {
    iv: encryptionIV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return cipher.toString(CryptoJS.enc.Utf8) !== ""
    ? JSON.parse(cipher.toString(CryptoJS.enc.Utf8))
    : cipher.toString(CryptoJS.enc.Utf8);
}
