import AutoPlusLogo from "../Images/imports/logo-primary-auto-plus-hide.svg";

const NotificationService = (() => {
  let notificationQueue = [];
  let isNotifying = false;

  const addToQueue = (message) => {
    if (notificationQueue.length > 0) {
      if (notificationQueue[notificationQueue.length - 1] !== message) {
        notificationQueue.push(message);
      }
    } else {
      notificationQueue.push(message);
    }

    if (!isNotifying) {
      showNextNotification();
    }
  };

  const showNextNotification = () => {
    if (notificationQueue.length > 0) {
      const message = notificationQueue.shift();
      isNotifying = true;

      const notificationDiv = document.createElement("div");
      notificationDiv.className = "notification-system flex colm";
      const innerDiv = document.createElement("div");
      innerDiv.className = "flex row liner addgap8";
      const img = document.createElement("img");
      img.alt = "autoPlusLogo";
      img.src = AutoPlusLogo;
      const h5 = document.createElement("h5");
      h5.textContent = "AutoPlus Notification";
      const span = document.createElement("span");
      span.textContent = message;
      innerDiv.appendChild(img);
      innerDiv.appendChild(h5);
      notificationDiv.appendChild(innerDiv);
      notificationDiv.appendChild(span);
      document.body.appendChild(notificationDiv);

      setTimeout(() => {
        document.body.removeChild(notificationDiv);
        isNotifying = false;
        showNextNotification();
      }, 4000);
    }
  };

  return {
    addToQueue,
  };
})();

export default NotificationService;
