import { BrowserRouter, Routes, Route } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import { Connexion } from "./Pages/Connexion";
import { Inscription } from "./Pages/Inscription";
import { Acceuil } from "./Pages/Acceuil";
import { Faq } from "./Pages/Faq";
import { Acheter } from "./Pages/Acheter";
import { Vendre } from "./Pages/Vendre";
import { VehiclePurchase } from "./Pages/VehiclePurchase.page";
import { ProductBuy } from "./Pages/ProductBuy";
import { PasswordForget } from "./Pages/PasswordForget";
import { NewPassword } from "./Pages/NewPassword";
import { ProfilPageComposant } from "./Pages/ProfilPage";
import { useEffect, useState } from "react";
import { decryptData } from "./Shared/Services/encryption.service";
import { NewAccountConfirm } from "./Pages/NewAccountConfirm";
import { NewAccountConfirmComplete } from "./Pages/NewAccountConfirmComplete";
import { useDispatch, useSelector } from "react-redux";
import { setDataAutoplusBMTC, setDataFavoris } from "./feature/autoplus.data";
import NotificationService from "./Shared/Services/notification.service";
import { returnStaticInfo } from "./Shared/Services/simplerequest.service";

import axios from "axios";
import env from "react-dotenv";
import { get } from "./Shared/Services/api.service";
import { NotFound } from "./Pages/NotFound";
import { redirectToHome } from "./Shared/Services/redirect.service";
import { Politique } from "./Pages/Politique";
import { Condition } from "./Pages/Condition";

async function loadBaseAutoPlusData(dispatch) {
  const promises = [
    returnStaticInfo("/brands"),
    returnStaticInfo("/motor_types"),
    returnStaticInfo("/car_types"),
    returnStaticInfo("/transmissions"),
    returnStaticInfo("/conditions"),
    returnStaticInfo("/motor_powers"),
  ];
  const [brands, motorTypes, carTypes, transmissions, conditions, motorPowers] =
    await Promise.all(promises);
  try {
    await Promise.resolve(
      dispatch(
        setDataAutoplusBMTC({
          brands,
          motorTypes,
          carTypes,
          transmissions,
          conditions,
          motorPowers,
        })
      )
    );
    if (
      brands.length === 0 ||
      motorTypes.length === 0 ||
      carTypes.length === 0 ||
      transmissions.length === 0 ||
      conditions.length === 0 ||
      brands.length === undefined ||
      motorTypes.length === undefined ||
      carTypes.length === undefined ||
      transmissions.length === undefined ||
      conditions.length === undefined
    ) {
      return false;
    }
    return true;
  } catch {
    return false;
  }
}

function App() {
  useEffect(() => {
    return null;
    axios.get(env.API_PRIVATE_URL + "/services/seeding");
  }, []);
  const [baseDataSet, setBaseData] = useState();
  const [dataUser, setDataUser] = useState();
  const dispatch = useDispatch();
  async function searchStaticInfos() {
    let datasLoadReturn = false;
    datasLoadReturn = await Promise.resolve(loadBaseAutoPlusData(dispatch));
    setBaseData(datasLoadReturn);
    if (datasLoadReturn === false) {
      NotificationService.addToQueue(
        "Erreur lors du chargement des données du site, Réeesayer plus tard"
      );
      NotificationService.addToQueue("Vôtre session à été interrompue");
      setTimeout(() => {
        window.sessionStorage.removeItem("firstLoad");
        window.sessionStorage.removeItem("visitorIP");
        window.sessionStorage.removeItem("visitorKey");
        window.sessionStorage.removeItem("userdata");
        window.sessionStorage.removeItem("userDataSave");
        window.localStorage.removeItem("userdata");
        window.localStorage.removeItem("userDataSave");
        redirectToHome();
      }, 6000);
    }
  }

  const valueUserData = useSelector((state) => state.userData.data);
  async function fetchData() {
    searchStaticInfos();
    if (valueUserData !== "") {
      const decryptDUser = await decryptData(valueUserData);
      setDataUser(decryptDUser);
    } else {
      setDataUser({});
    }
  }

  useEffect(() => {
    fetchData();
  }, [valueUserData]);

  const getFavoris = async () => {
    const favoris = await get(`/users/favs/${dataUser.id}`);
    let dataM = favoris.data;
    dataM = await dataM.filter((item) => item !== null);
    dispatch(setDataFavoris(dataM));
  };
  useEffect(() => {
    if (
      baseDataSet === undefined ||
      dataUser === undefined ||
      dataUser === null
    )
      return;
    if (dataUser.id) {
      getFavoris();
    }
  }, [baseDataSet, dataUser]);

  return (
    <>
      <CookieConsent style={{ display: "none" }}>
        Ce site web utilise des cookies pour ameliorer l'expérience utilisateur.
      </CookieConsent>

      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Acceuil />}></Route>
          <Route exact path="/connexion" element={<Connexion />}></Route>
          <Route
            exact
            path="/password-forget"
            element={<PasswordForget />}
          ></Route>
          <Route
            exact
            path="/new-password/:id"
            element={<NewPassword />}
          ></Route>
          <Route
            exact
            path="profil/confirmation_email/:token"
            element={<NewAccountConfirmComplete />}
          ></Route>
          <Route
            exact
            path="/account-confirm"
            element={<NewAccountConfirm />}
          ></Route>
          <Route exact path="/inscription" element={<Inscription />}></Route>
          <Route exact path="/faq" element={<Faq />}></Route>
          <Route exact path="/politique" element={<Politique />}></Route>
          <Route exact path="/condition" element={<Condition />}></Route>
          <Route exact path="/acheter" element={<Acheter />}></Route>
          <Route exact path="/vendre" element={<Vendre />}></Route>
          <Route exact path="/productpage/:id" element={<ProductBuy />}></Route>
          <Route
            exact
            path="/vehiclepurchase/:id"
            element={<VehiclePurchase />}
          ></Route>
          <Route
            exact
            path="/profilpage/:id"
            element={<ProfilPageComposant />}
          ></Route>
          <Route exact path="/*" element={<NotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
