import env from "react-dotenv";
import axios from "axios";
import { decryptData, encryptData } from "./encryption.service";
import { getVisitorPassport } from "./visitor.service";

var inProgress = 0;

axios.interceptors.request.use(
  function (config) {
    if (
      config.method == "post" ||
      config.method == "put" ||
      config.method == "patch"
    ) {
      let payload = config.data;
      let key = null;
      let visitor = getVisitorPassport();

      if (visitor) {
        key = visitor.key;
      }

      if (!(payload instanceof FormData)) {
        let encryptedPayload = encryptData(payload, key);
        config.data = {
          data: encryptedPayload,
        };
      }
    }
    if (!config.headers.has("x-api-token")) {
      let visitor = getVisitorPassport();
      config.headers["x-api-token"] = encryptData(visitor);
    }

    inProgress++;

    if (window.sessionStorage.getItem("firstLoad") === true) {
      document.getElementsByClassName("preloader-container")[0].style.display =
        "grid";
      document.getElementsByTagName("body")[0].style.overflowY = "hidden";
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (config) {
    let decryptedData = decryptData(config.data);

    config.data = decryptedData;

    --inProgress;

    if (inProgress == 0 && window.sessionStorage.getItem("firstLoad")) {
      document
        .getElementsByClassName("preloader-container")[0]
        .classList.add("preloader-out");

      setTimeout(function () {
        document.getElementsByClassName(
          "preloader-container"
        )[0].style.display = "none";
      }, 700);

      if (document.getElementsByClassName("CookieConsent").length > 0) {
        document.getElementsByClassName("CookieConsent")[0].style.display =
          "flex";
      }

      document.getElementsByTagName("body")[0].style.overflowY = "scroll";
      window.sessionStorage.setItem("firstLoad", false);
    }

    return config;
  },
  function (error) {
    console.log(error);
    let decryptedData = decryptData(error.response.data);
    error.response.data = decryptedData;
    return Promise.reject(error);
  }
);

function createGetRoute(baseRoute, params) {
  Object.keys(params).forEach((key, index) => {
    if (index > 0) {
      baseRoute += "&";
    } else {
      baseRoute += "?";
    }

    if (Array.isArray(params[key])) {
      let queryArray = params[key]

      queryArray.forEach((queryArrayValue, queryArrayIndex) => {
        baseRoute += `${key}[${queryArrayIndex}]=${queryArrayValue}`;
      })
    }
    else {
      baseRoute += key + "=" + params[key];
    }
  });

  return baseRoute;
}

export function post(endpoint, data, headers) {
  return axios.post(env.API_PRIVATE_URL + endpoint, data, {
    headers: headers,
  });
}

export function postFile(endpoint, formdata) {
  let visitor = getVisitorPassport();
  return axios.post(env.API_PRIVATE_URL + endpoint, formdata, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-api-token": encryptData(visitor),
    },
  });
}

export function get(endpoint, data = {}, headers = {}) {
  let route = createGetRoute(env.API_PRIVATE_URL + endpoint, data);

  return axios.get(route, {
    headers: headers,
  });
}

export function remove(endpoint, headers = {}) {
  return axios.delete(env.API_PRIVATE_URL + endpoint, {
    headers: headers,
  });
}

export function put(endpoint, data, headers = {}) {
  return axios.put(env.API_PRIVATE_URL + endpoint, data, {
    headers: headers,
  });
}

export function patch(endpoint, data, headers = {}) {
  return axios.patch(env.API_PRIVATE_URL + endpoint, data, {
    headers: headers,
  });
}
