import React, { useRef } from "react";
import "../Styles/section-contact.css";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import pngwing2 from "../Images/pngwing_2.png";
import sms from "../Images/sms.svg";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useState } from "react";
import axios from "axios";
import { get, post, patch } from "../Services/api.service";
import env from "react-dotenv";
import NotificationService from "../Services/notification.service";
import { useEffect } from "react";

export const SectionContact = () => {
  const [adresseMail, setAdresseMail] = useState("");
  const [message, setMessage] = useState("");
  const refAdrM = useRef(null);
  const refMess = useRef(null);

  const [canSendMessage, setCanSendMessage] = useState(false);
  const [loadMessageInLoading, setLoadMessageInLoading] = useState(false);

  useEffect(() => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (adresseMail.match(mailformat) && message.length >= 5) {
      setCanSendMessage(true);
    } else {
      setCanSendMessage(false);
    }
  }, [adresseMail, message]);

  const sendMessageContact = () => {
    let data = { email: adresseMail, message };
    setLoadMessageInLoading(true);
    post("/services/send_contact", data)
      .then((response) => {
        setLoadMessageInLoading(false);
        refMess.current.value = "";
        setMessage("");
        NotificationService.addToQueue("Votre message à bien été envoyé");
      })
      .catch((error) => {
        console.log(error);
        setLoadMessageInLoading(false);
        NotificationService.addToQueue(
          "Erreur lors de l'envoi de votre message"
        );
      });
  };
  return (
    <div className="section-contact center-margin max-width flex">
      <div className="contains-section-contact flex row">
        <div className="left flex colm">
          <div className="text-section flex colm">
            <p>Nous serons ravis de vous écouter.</p>
            <p>
              Contactez nous pour tous renseignements, demandes et suggestions.
              Nous sommes à votre écoute !
            </p>
          </div>
          <img src={pngwing2} />
        </div>
        <div className="right">
          <div className="contains-message flex colm">
            <div className="text-section flex colm">
              <p>Laissez nous un message</p>
              <p>Dites nous ce que vous pensez.</p>
            </div>
            <div className="input-section-contact flex row">
              <img src={sms} alt="icon" />
              <input
                onChange={(e) => {
                  setAdresseMail(e.target.value);
                }}
                ref={refAdrM}
                placeholder="Adresse mail"
              />
            </div>
            <div className="input-textarea-section-contact">
              <textarea
                ref={refMess}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                placeholder="Entrez votre message"
              ></textarea>
            </div>
            <button
              disabled={
                !(canSendMessage === true && loadMessageInLoading === false)
              }
              onClick={sendMessageContact}
              className="hover-btn"
            >
              Envoyer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
