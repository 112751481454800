export function getVisitorPassport(){
    if(window.sessionStorage.getItem('visitorIP') && window.sessionStorage.getItem('visitorKey')){
        return {
            ip: window.sessionStorage.getItem('visitorIP'),
            key: window.sessionStorage.getItem('visitorKey')
        }
    }
    else {
        return undefined;
    }
}