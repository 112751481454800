import React, { useState } from "react";
import "../Styles/returnTop.css";
import { useEffect } from "react";
import arrow from "../Images/arrow-down.svg";
export const ReturnTop = ({ top, detect = 50 }) => {
  useEffect(() => {}, []);
  const [scrollBeyond50, setScrollBeyond50] = useState(false);

  function goTo() {
    window.scrollTo({
      top: window.innerWidth <= 650 ? 0 : top,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrolledPixels = window.scrollY;
      let value = false;
      const bodyHeight = document.body.clientHeight;
      if (scrolledPixels > detect) value = true;
      if (scrolledPixels > bodyHeight - 900) value = false;
      setScrollBeyond50(value);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {scrollBeyond50 && (
        <button onClick={goTo} className="rtn-top hover-btn">
          <img src={arrow} alt="arrow" />
        </button>
      )}
    </>
  );
};
