import { createSlice } from "@reduxjs/toolkit";

export const changeDataFilter = createSlice({
  name: "autoplus-input-value",
  initialState: {
    searchTxt: "",
    categoModelSelect: [],
    carBrand: [],
    carFindBySave: false,
  },
  reducers: {
    setSearchTxt: (state, { payload }) => {
      state.searchTxt = payload;
    },
    setCategoModel: (state, { payload }) => {
      state.categoModelSelect = payload;
    },
    setCarBrand: (state, { payload }) => {
      state.carBrand = payload;
    },
    setCarFindBySave: (state, { payload }) => {
      state.carBrand = payload;
    },
  },
});

export const { setSearchTxt, setCategoModel, setCarBrand, setCarFindBySave } =
  changeDataFilter.actions;
export default changeDataFilter.reducer;
