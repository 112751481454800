import "./input.quick-complementary.css";
export const SelectComplementary = ({
  text,
  value,
  allValue,
  setValueTab,
  mineId,
}) => {
  function applyOnMyTab(e, value) {
    e.stopPropagation();
    let newTab = [];
    allValue.map((item) => {
      let objectI = item;
      if (objectI.id === mineId) newTab.push({ ...objectI, active: value });
      else newTab.push(objectI);
    });
    setValueTab(newTab);
  }
  return (
    <>
      <span>{text}</span>
      <div className="btn-selector | flex row liner">
        <div
          className={
            value === true ? "hoverless right-side" : "hoverless left-side"
          }
        ></div>
        <button
          type="button"
          style={{
            color:
              value === false ? "var(--white-500)" : "var(--secondary-500)",
          }}
          className="hover-btn"
          onClick={(e) => applyOnMyTab(e, false)}
        >
          Non
        </button>
        <div className="separator"></div>
        <button
          type="button"
          style={{
            color: value === true ? "var(--white-500)" : "var(--secondary-500)",
          }}
          className="hover-btn"
          onClick={(e) => applyOnMyTab(e, true)}
        >
          Oui
        </button>
      </div>
    </>
  );
};
