import { useEffect, useRef, useState } from "react";
import coinIcon from "../Images/imports/moneyIcon.svg";
import arrowsIconFilter from "../Images/arrow-down.svg";
import fillArrow from "../Images/imports/checkbox-fill-icon.svg";
import "../Styles/filterPrice.css";

export const PriceFilter = ({ setMin, setMax }) => {
  const [currentMarques, setCurrentMarques] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const refMin = useRef(null);
  const refMax = useRef(null);
  const handleOpen = () => {
    setOpen(!isOpen);
  };

  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;

    const priceInput = document.querySelectorAll(".price-input input");
    const rangeInput = document.querySelectorAll(".range-input input");
    const range = document.querySelector(".slider .progress");
    const priceGap = 100000;

    const handlePriceInputChange = (e) => {
      const minPrice = parseInt(priceInput[0].value);
      const maxPrice = parseInt(priceInput[1].value);
      if (minPrice > 0 && maxPrice > 0) {
        setMin(minPrice);
        setMax(maxPrice);
      }
      if (maxPrice - minPrice >= priceGap && maxPrice <= rangeInput[1].max) {
        if (e.target.className === "input-min") {
          rangeInput[0].value = minPrice;
          range.style.left = `${(minPrice / rangeInput[0].max) * 100}%`;
        } else {
          rangeInput[1].value = maxPrice;
          range.style.right = `${100 - (maxPrice / rangeInput[1].max) * 100}%`;
        }
      }
    };

    const handleRangeInputChange = (e) => {
      const minVal = parseInt(rangeInput[0].value);
      const maxVal = parseInt(rangeInput[1].value);
      setMin(minVal);
      setMax(maxVal);
      if (maxVal - minVal < priceGap) {
        if (e.target.className === "range-min") {
          rangeInput[0].value = maxVal - priceGap;
        } else {
          rangeInput[1].value = minVal + priceGap;
        }
      } else {
        priceInput[0].value = minVal;
        priceInput[1].value = maxVal;
        range.style.left = `${(minVal / rangeInput[0].max) * 100}%`;
        range.style.right = `${100 - (maxVal / rangeInput[1].max) * 100}%`;
      }
    };

    priceInput.forEach((input) => {
      input.addEventListener("input", handlePriceInputChange);
    });

    rangeInput.forEach((input) => {
      input.addEventListener("input", handleRangeInputChange);
    });

    return () => {
      priceInput.forEach((input) => {
        input.removeEventListener("input", handlePriceInputChange);
      });

      rangeInput.forEach((input) => {
        input.removeEventListener("input", handleRangeInputChange);
      });
    };
  }, []);

  useEffect(() => {
    const priceInput = document.querySelectorAll(".price-input input");
    const rangeInput = document.querySelectorAll(".range-input input");
    const range = document.querySelector(".slider .progress");
    refMin.current.value = 0;
    refMax.current.value = 100000000;
    const minVal = 0;
    const maxVal = 100000000;
    priceInput[0].value = minVal;
    priceInput[1].value = maxVal;
    range.style.left = `${(minVal / rangeInput[0].max) * 100}%`;
    range.style.right = `${100 - (maxVal / rangeInput[1].max) * 100}%`;
  }, []);

  useEffect(() => {
    setMin(0);
    setMax(100000000);
  }, []);

  return (
    <div
      className={
        isOpen === true
          ? "btn-filter year-filter flex colm price-filter price-filter-is-open"
          : "btn-filter year-filter price-filter flex colm"
      }
    >
      <button onClick={handleOpen} className="btn-select flex row btw">
        <div className="left flex row">
          <img src={coinIcon} />
          <span className="open-me-filter">Prix du véhicule</span>
        </div>
        <img
          className={
            isOpen === true
              ? "img-arrow-turn is-open-filter-cars"
              : "img-arrow-turn"
          }
          src={arrowsIconFilter}
        />
      </button>
      <div className="input-year">
        <div className="wrapper flex colm">
          <div className="price-input flex row btw">
            <div className="field flex colm">
              <span>Min :</span>
              <div className="input-price flex row ">
                <span>F</span>
                <input type="number" className="input-min" defaultValue="0" />
              </div>
            </div>
            <div className="field flex colm">
              <span>Max :</span>
              <div className="input-price flex row ">
                <span>F</span>
                <input
                  type="number"
                  className="input-max"
                  defaultValue="30000000"
                />
              </div>
            </div>
          </div>
          <div className="slider-value">
            <div className="slider">
              <div className="progress"></div>
            </div>
            <div className="range-input">
              <input
                ref={refMin}
                type="range"
                className="range-min"
                min="0"
                max="100000000"
                defaultValue="0"
                step="100000"
              />
              <input
                ref={refMax}
                type="range"
                className="range-max"
                min="0"
                max="100000000"
                defaultValue="30000000"
                step="100000"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
