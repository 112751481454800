import React, { useEffect, useRef } from "react";
import "../Shared/Styles/login.css";
import { Header } from "../Shared/Components/header.component";
import AutoPlusLogo from "../Shared/Images/imports/logo-primary-auto-plus-hide.svg";
import carImage from "../Shared/Images/pngwing11.png";
import vectorImage from "../Shared/Images/vectorConnexion.svg";
import smsIcon from "../Shared/Images/sms.svg";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FooterComposant } from "../Shared/Components/footer.composant";

export const NewAccountConfirm = () => {
  return (
    <div className="login-connect-page connexion-page">
      <Header NoBackground={false} />
      <div className="login-page flex center-margin max-width">
        <img className="back-car" src={carImage} alt="cars-image" />
        <img className="back-vector" src={vectorImage} alt="vector-image" />
        <div className="contains flex colm">
          <div className="top-img-label flex colm">
            <img src={AutoPlusLogo} alt="autoPlusLogo" />
            <h4>Confirmation</h4>
            <span>
              Connectez-vous à l’adresse e-mail saisie lors de votre
              inscription. Cliquez sur le lien afin de confirmer vôtre incription.
            </span>
          </div>
        </div>
      </div>
      <FooterComposant />
    </div>
  );
};
