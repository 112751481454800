import "../Styles/cardBuySection.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";
export const CardBuySection = () => {
  return (
    <div className="how-buy-section flex colm">
      <div className="label-title flex colm">
        <span>A VOS MARQUES, PRÊT, ACHETEZ !</span>
        <h2>
          <p>Achetez facilement votre </p>
          <p>véhicule en quelques minutes</p>
        </h2>
      </div>
      <div className="card-info desktop-steps-card flex row btw">
        <div className="step flex colm">
          <div className="label-step flex colm">
            <span>Etape 1</span>
            <h3>Parcourez notre inventaire</h3>
          </div>
          <span>
            Explorez notre vaste sélection de voitures neuves et d'occasion.
            affiner votre recherche en fonction de vos préférences spécifiques
            et Consultez la description de votre véhicule.
          </span>
        </div>
        <div className="step flex colm">
          <div className="label-step flex colm">
            <span>Etape 2</span>
            <h3>Prenez rendez - vous</h3>
          </div>
          <span>
            Une fois que vous avez trouvé la voiture qui correspond à vos
            besoins, contactez Notre équipe de professionnels pour programmer
            une visite.
          </span>
        </div>
        <div className="step flex colm">
          <div className="label-step flex colm">
            <span>Etape 3</span>
            <h3>Finalisez votre achat</h3>
          </div>
          <span>
            Après la visite du véhicule vous pouvez finaliser votre achat avec
            notre équipe. nous veillerons à ce que le processus soit simple et
            sans tracas.
          </span>
        </div>
      </div>
      <div className="card-info mobile-card-info flex row btw">
        <Swiper
          loop={false}
          modules={[Pagination]}
          spaceBetween={8}
          grabCursor={true}
          pagination={{ clickable: false }}
        >
          <SwiperSlide>
            <div className="step flex colm">
              <div className="label-step flex colm">
                <span>Etape 1</span>
                <h3>Parcourez notre inventaire</h3>
              </div>
              <span>
                Explorez notre vaste sélection de voitures neuves et d'occasion.
                affiner votre recherche en fonction de vos préférences
                spécifiques et Consultez la description de votre véhicule.
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="step flex colm">
              <div className="label-step flex colm">
                <span>Etape 2</span>
                <h3>Prenez rendez - vous</h3>
              </div>
              <span>
                Une fois que vous avez trouvé la voiture qui correspond à vos
                besoins, contactez Notre équipe de professionnels pour
                programmer une visite.
              </span>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="step flex colm">
              <div className="label-step flex colm">
                <span>Etape 3</span>
                <h3>Finalisez votre achat</h3>
              </div>
              <span>
                Après la visite du véhicule vous pouvez finaliser votre achat
                avec notre équipe. nous veillerons à ce que le processus soit
                simple et sans tracas.
              </span>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
