import { createSlice } from "@reduxjs/toolkit";
import { encryptData } from "../Shared/Services/encryption.service";

export const loadDataUser = createSlice({
  name: "autoplus-input-value",
  initialState: {
    data:"",
    dataFetched: false,
  },
  reducers: {
    setDataUser: (state, { payload }) => {
      state.data = payload.data
      state.dataFetched = payload.dataFetched;
    },
  },
});

export const { setDataUser } = loadDataUser.actions;
export default loadDataUser.reducer;
