import { useEffect, useRef, useState } from "react";
import carsIconFilter from "../Images/imports/carIcon.svg";
import arrowsIconFilter from "../Images/arrow-down.svg";
import fillArrow from "../Images/imports/checkbox-fill-icon.svg";
import "../Styles/filterMarque.css";
import { useDispatch, useSelector } from "react-redux";
import { setCarBrand } from "../../feature/filter.data";
export const CarTypeFilter = ({ carType, setCarType }) => {
  const [currentCarType, setCurrentCarType] = useState([]);
  const [marque, setMarque] = useState([]);
  const [isOpen, setOpen] = useState(false);

  const dataCategoryFilter = useSelector(
    (state) => state.filterDataConf.carBrand
  );

  const divRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (currentCarType.length > 0) return;
    setCurrentCarType(carType);
  }, [carType]);

  const handleOpen = () => {
    setOpen(!isOpen);
  };
  const handleSelectFilterMarque = (nameMarque) => {
    let newM = [];
    currentCarType.map((marq) => {
      if (marq.value === nameMarque) {
        marq.active = !marq.active;
      }
      newM.push(marq);
    });
    setCurrentCarType(newM);
  };
  const isFirstMount = useRef(true);
  useEffect(() => {
    if (isFirstMount.current && dataCategoryFilter.length > 0) {
      isFirstMount.current = false;
      return;
    }

    let newTab = [];
    currentCarType.map((marq) => {
      if (marq.active === true) {
        newTab.push(marq);
      }
    });
    setMarque(newTab);

    let dispatcher = [];

    newTab.map((item) => {
      dispatcher.push(item.value);
    });

    dispatch(setCarBrand(dispatcher));
    setCarType(newTab);
  }, [currentCarType]);
  useEffect(() => {
    let newTab = [];
    let compare = [];
    if (dataCategoryFilter) {
      dataCategoryFilter.map((carType) => {
        compare.push(carType);
        newTab.push({ name: carType, value: carType, active: true });
      });
    }
    let marques = [];
    marque.map((item) => {
      marques.push(item.value);
    });
    let compareBool =
      compare.every((element) => marques.includes(element)) &&
      marques.every((element) => compare.includes(element));
    if (compareBool === false) {
      let newM = [];
      carType.map((marq) => {
        if (compare.includes(marq.value)) {
          marq.active = true;
        } else {
          marq.active = false;
        }
        newM.push(marq);
      });
      setCurrentCarType(newM);
    }
  }, [dataCategoryFilter]);
  return (
    <div ref={divRef} className="btn-filter flex">
      <button
        style={{ width: "100%" }}
        onClick={handleOpen}
        className="btn-select flex row"
      >
        <img src={carsIconFilter} />
        <span style={{ width: "100%" }} className="flex row">
          <p className="flex row">
            {marque.length > 0
              ? marque.map((marq, count) => {
                  return count < marque.length ? marq.value + ", " : marq;
                })
              : "Trier par type"}
          </p>
          <img
            className={isOpen && "is-open-filter-cars"}
            src={arrowsIconFilter}
          />
        </span>
      </button>
      {isOpen === true ? (
        <div className="selection-marque">
          {currentCarType.map((marque) => {
            return (
              <button
                className="flex"
                onClick={() => handleSelectFilterMarque(marque.value)}
              >
                <div className={marque.active ? "flex active-select" : "flex"}>
                  <img src={fillArrow} alt="arrow-fill" />
                </div>
                <span>{marque.value}</span>
              </button>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
